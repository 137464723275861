<div class="container-fluid mt-2 mb-2">
  <div class="row mb-2" v-if="ffdSystem || ffdDescription">
    <div class="col-12">
      <div class="row ml-1">
        <div class="col-3">
          <label>{{ $t('message.header_vehicle_table_system_name') }}</label>
        </div>
        <div class="col-9">
          <label style="font-weight: bold;">{{ ffdSystem }}</label>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row ml-1">
        <div class="col-3">
          <label>{{ $t('message.header_vehicle_dtc_table_description') }}</label>
        </div>
        <div class="col-9">
          <label style="font-weight: bold;">{{ ffdDescription }}</label>
        </div>
      </div>
    </div>
  </div>
  <label style="font-weight: bold;">{{ $t('message.label_vehicle_ffd_select_measurement') }}</label>
  <b-form-select v-model="selectedFFDGroup" size="sm" @change="changeFFDMeasurement" id="ffd_model"
    class="custom-select custom-select-sm">
    <option v-for="opt in ffdGroupOptions" :value="opt.key">{{ opt.value.label }}</option>
  </b-form-select>
  <div class="mt-2 mb-2" style="display: flex;">
    <div>
      <b-button variant="primary" size="sm" @click="initializeFFDSort()">
        {{ $t('message.button_vehicle_ffd_initialize_sort') }}
      </b-button>
      <b-button class="ml-2" variant="primary" size="sm" @click="csvExport">
        {{ $t('message.file_export') }}
      </b-button>
    </div>
    <div class="ml-2">
      <slot name="didTestBtn"></slot>
    </div>
  </div>
  <cstm-table-modal ref="ffd_tbl" useSort initSortDir="asc" :items="ffdTableItems" :columns="ffdDetailTableColumns"
    type="simple" usePopup useStripeStyle hasTabs>
    <!-- ページングコンポーネントは呼び出し元から指定 -->
    <!-- <template v-slot:contentRightEnd>
      <cstm-paging-modal ref="ffd_pagination" :pageSize='maxItemsPerPage' v-bind:initPage=1
        v-bind:totalPageLength='totalFFDItemLength' @selecedPage="switchTablePage" inRprService />
    </template> -->
  </cstm-table-modal>
</div>