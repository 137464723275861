<!-- ECU設定情報の書込み ダイアログ -->
<b-modal v-model="modal_requirement_140" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <!-- ヘッダー -->
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 確認 -->
      <template v-if="phase === 'start' || phase === 'inputData'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 実行中 -->
      <template v-if="phase === 'writing'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- 成功 -->
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <!-- エラー -->
      <template v-if="phase === 'invalidData' || phase === 'writeError'|| phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <!-- ボディ -->
  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 待機画面 -->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_140_TXT_1`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- アプライドモデル・オプションコード入力表示画面 -->
        <template v-if="phase === 'inputData'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="w-100 mb-4">
                <div class="text-center work-support-message" style="padding-bottom: 15px;">{{
                  $t(`individual.header_input_applied_model_option_code_ws140`) }}
                </div>
                <table class="box-margin-right box-margin-top work-support-message" style="width:400px;">
                  <tbody>
                    <tr>
                      <td style="vertical-align: top">{{ $t(`${i18nWsKey}.REQ_WS_140_TXT_3`) }}</td>
                      <td>
                        <b-form-group>
                          <b-form-input v-model="inputAppliedModel"
                            :state="validateAppliedModel && !validateAppliedModel.valid" type="text" size="sm"
                            autocomplete="off">
                          </b-form-input>
                          <b-form-invalid-feedback v-if="validateAppliedModel">{{ validateAppliedModel.msg
                            }}</b-form-invalid-feedback>
                        </b-form-group>
                      </td>
                    </tr>
                    <tr>
                      <td style="vertical-align: top">{{ $t(`${i18nWsKey}.REQ_WS_140_TXT_5`) }}</td>
                      <td>
                        <b-form-group>
                          <b-form-input v-model="inputOptionCode"
                            :state="validateOptionCode && !validateOptionCode.valid" type="text" size="sm"
                            autocomplete="off">
                          </b-form-input>
                          <b-form-invalid-feedback v-if="validateOptionCode">{{ validateOptionCode.msg
                            }}</b-form-invalid-feedback>
                        </b-form-group>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </template>
        <!-- 実行画面 -->
        <template v-if="phase === 'writing'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_140_TXT_6`) }}</div>
          </div>
        </template>
        <!-- 停止画面 -->
        <template v-if="phase === 'success'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">
                  <div> {{ displayInfo }} </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- バリアントデータ不正表示画面 -->
        <template v-if="phase === 'invalidData'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_140_TXT_8`) }}</div>
          </div>
        </template>
        <!-- 書込み失敗表示画面 -->
        <template v-if="phase === 'writeError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_140_TXT_9`) }}</div>
          </div>
        </template>
        <!-- 共通エラー画面 -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <!-- フッター -->
  <template v-slot:footer>
    <div class="w-100 text-center ">
      <template v-if="phase === 'start'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="inputDataProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'inputData'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading || !chkValidateAppliedModel || !chkValidateOptionCode"
          variant="primary" @click="validateWithVariantData" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'invalidData' || phase === 'success' || phase === 'writeError'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exitProcess(1)" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>