import session from '../../../../share/session';
import individualWorkSupportUtil from '../../../../share/util/individualWorkSupportUtil';
import { REQUEST_STATUS_COMPLETED, REQUEST_STATUS_FAILED } from '../../../../share/util/api/polling';
import { PHASE } from './constants';

/**
 * DefaultSession遷移
 * @param {*} self this
 * @param {boolean} isMoveCloseWizard 機能終了遷移フラグ
 */
export async function terminate(self, isMoveCloseWizard = false) {
  try {
    self.isLoading = true;
    //バックエンドAPI POST,GET
    const token = session.getToken();
    const response = await individualWorkSupportUtil.pollingWspApi(
      self,
      self.netAppId,
      self.systemId,
      self.workSupportId,
      {
        id_token: token.id_token,
        type: 'terminate',
      },
      // エラー発生のコールバックを実装
      (errorType) => { errorType; self.showUnexpectedError(); },
      // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
      // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
    );

    if (response) {
      // 正常コールバック
      if (!isMoveCloseWizard) {
        // 機能終了遷移ではない場合
        switch (response.data.status) {
          case REQUEST_STATUS_COMPLETED:
            // 正常終了
            // プロジェクトデータ保存に遷移
            self.phase = PHASE.SAVE;
            break;
          case REQUEST_STATUS_FAILED:
          default:
            // 異常終了
            // DefaultSessionエラー画面に遷移
            self.phase = PHASE.DEFAULT_SESSION_ERROR;
            break;
        }
      } else {
        // 機能終了遷移の場合
        // 結果に関わらず、機能を終了する
        self.closeWizard();
      }
      
    }
  } finally {
    self.isLoading = false;
  }
}