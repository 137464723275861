import { fixNumberWithDecimal } from "@/common/nodejs/mathFunctions";

/**
 * DID data conversion from data to display value
 * @param conversionList
 * @param systemId
 */
function convertDataToDisplayValue(dataByteArray = [], conversionInfo = {}) {
    if (!dataByteArray || dataByteArray.length === 0) {
        return "";
    }
    let displayValue = "";
    if (!conversionInfo || !conversionInfo || !conversionInfo.data_type) {
        return displayValue;
    }
    const conversionFunctions = {
        "FLG": convertFlgData,
        "HEX": convertHexData,
        "Value": convertValueData,
        "ASCII": convertAsciiData,
        "Bin": convertBinData,
        "YYMMDD": convertYymmddData,
        "YYYYMMDD": convertYyyymmddData,
        "hhmmss": convertHhmmssData,
        "YYYYMMDDhhmm": convertYyyymmddhhmmData,
        "PRG_YYYYMMDDhhmm": convertPrgYyyymmddhhmmData,
        "YYYYMMDDhhmmss": convertYyyymmddhhmmssData,
        "min2hhmm": convertMin2hhmmData,
        "sec2hhmm": convertSec2hhmmData,
        "sec2hhmmss": convertSec2hhmmssData,
        "Lat_DEG": convertLatDegData,
        "Lng_DEG": convertLngDegData,
        "Lat_DMM": convertLatDmmData,
        "Lng_DMM": convertLngDmmData,
        "LatLng_DMM": convertLatLngDmmData,
        "ACL2INC": convertAcl2IncValue,
        //"dexExStr": convertDecExStrData, 呼び出し元で分岐するため不要
        "decWithDot": convertDecWithDotData,
        "MFD_BCD1": convertMfdBcd1Data,
        "MFD_BCD2": convertMfdBcd2Data
    };
    const conversionFunction = conversionFunctions[conversionInfo.data_type];
    if (conversionFunction) {
        displayValue = conversionFunction(dataByteArray, conversionInfo);
    }
    return displayValue;
}

function convertFlgData(dataByte, conversionInfo) {
    const FLG_TRUE = "1";
    const FLG_FALSE = "0";
    let specBitStr = "";
    if (conversionInfo.value_spec_bit) {
        switch (conversionInfo.value_spec_bit) {
            case FLG_TRUE:
                specBitStr = "_supp_FF";
                break;
            case FLG_FALSE:
                specBitStr = "_supp_00";
                break;
            default:
                break;
        }
    }

    if (conversionInfo.byte_or_bit === "byte") {
        return dataByte.toString(16).padStart(2, "0").toUpperCase() === "00" ? "00" + specBitStr : "FF";
    } else if (conversionInfo.byte_or_bit === "bit") {
        return dataByte.toString(10) === "0" ? "00" + specBitStr : "FF";
    }
    return "";
}

function convertHexData(dataBytes) {
    let displayValue = "";
    for (let i = 0; i < dataBytes.length; i++) {
        const dataByte = dataBytes[i].toString(16).padStart(2, "0").toUpperCase();
        displayValue += dataByte;
    }
    return displayValue;
}

function convertHexToInt(hexString, conversionInfo) {
    const { signature, byte_or_bit, bit_size } = conversionInfo;
    let valueTemp = parseInt(`0x${hexString}`, 16);
    if (signature === "signed") {
        // bit size(1桁=4bit)。bitのデータの場合は、変換情報の bit_size をそのまま使用する
        const bitSize = byte_or_bit === "byte" ? hexString.length * 4 : bit_size;
        // 符号なし整数の最大値
        const maxUnsignedValue = Math.pow(2, bitSize) - 1;
        // 符号付き整数の最大値
        const maxSignedValue = Math.pow(2, bitSize - 1) - 1;
        // 符号付き整数の最小値
        const minSignedValue = -Math.pow(2, bitSize - 1);
        // 符号付き整数の最大値を超える場合
        if (valueTemp > maxSignedValue) {
            valueTemp = valueTemp - (maxUnsignedValue + 1);
        }
        // 符号付き整数の最小値を下回る場合
        else if (valueTemp < minSignedValue) {
            valueTemp = valueTemp + (maxUnsignedValue + 1);
        }
    }
    return valueTemp;
}

function convertBinToInt(dataByteArray, conversionInfo) {
    let byteStr = '';
    for (let i = 0; i < dataByteArray.length; i++) {
        byteStr += dataByteArray[i].toString(16).padStart(2, "0");
    }
    return convertHexToInt(byteStr, conversionInfo);
}

/**
 * バイト配列に対する物理変換
 * @param {} dataByte バイト配列
 * @param {*} conversionInfo 変換情報
 * @returns 物理変換後の値(小数点以下丸めされた「文字列」)
 */
function convertValueData(dataByte, conversionInfo) {
    // Physical conversion
    const intValue = convertBinToInt(dataByte, conversionInfo);
    return convertValueDataForInt(intValue, conversionInfo);
}

/**
 * HEX文字列に対する物理変換
 * @param {} hexString HEX文字列
 * @param {*} conversionInfo 変換情報
 * @returns 物理変換後の値(小数点以下丸めされた「文字列」)
 */
function convertValueDataForHex(hexString, conversionInfo) {
    // Physical conversion
    const intValue = convertHexToInt(hexString, conversionInfo);
    return convertValueDataForInt(intValue, conversionInfo);
}

/**
 * 数値に対する物理変換
 * @param {} intValue 数値
 * @param {*} conversionInfo 変換情報
 * @returns 物理変換後の値(小数点以下丸めされた「文字列」)
 */
function convertValueDataForInt(intValue, conversionInfo) {
    const physicalValue = (intValue * conversionInfo.scaling_bit) + conversionInfo.offset;
    // Unit conversion
    const unitValue = convertUnitValueData(physicalValue, conversionInfo);
    // Format number using fixed-point notation.
    return fixNumberWithDecimal(unitValue, conversionInfo.digit_after_decimal);
}

/**
 * 単位変換(SI->US)
 * @param {} dataValue 数値
 * @param {*} conversionInfo 変換情報
 * @returns 単位変換後の数値
 */
function convertUnitValueData(dataValue, conversionInfo) {
    const unit_conversion = conversionInfo.unit_conversion || null;
    if (unit_conversion &&
        unit_conversion.scaling !== undefined && unit_conversion.offset !== undefined) {
        return ((dataValue * unit_conversion.scaling) + unit_conversion.offset);
    } else {
        return dataValue;
    }
}

function convertPhysicalValueData(dataValue, conversionInfo) {
    const floatValue = parseFloat(dataValue);
    // Unit conversion
    const unitValue = convertUnitValueData(floatValue, conversionInfo);
    // Format number using fixed-point notation.
    return fixNumberWithDecimal(unitValue, conversionInfo.digit_after_decimal);
}

function convertHhmmssData(dataByteArray) {
    if (dataByteArray.length < 3) {
        return "";
    }
    const hours = dataByteArray[0];
    const minutes = dataByteArray[1];
    const seconds = dataByteArray[2];
    if (hours >= 24 || minutes >= 60 || seconds >= 60) {
        // display "" (nothing)
        return "";
    } else {
        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }
}

function convertYyyymmddhhmmData(dataByteArray) {
    if (dataByteArray.length < 4) {
        return "";
    }
    const year = 2000 + (dataByteArray[0] >> 1);
    const month = ((dataByteArray[0] << 3) & 0x8) | (dataByteArray[1] >> 5);
    const date = dataByteArray[1] & 0x1F;
    const hours = dataByteArray[2] >> 3;
    const minutes = ((dataByteArray[2] << 3) & 0x38) | (dataByteArray[3] >> 5);
    if (year === 2127 || month === 0 || date === 0 || hours === 31 || minutes === 63) {
        return "-";
    } else {
        const datePart = `${year.toString().padStart(4, "0")}/${month.toString().padStart(2, "0")}/${date.toString().padStart(2, "0")}`;
        const timePart = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
        return `${datePart} ${timePart}`;
    }
}

function convertPrgYyyymmddhhmmData(dataByteArray) {
    return convertDateData(dataByteArray, false);
}

function convertYyyymmddhhmmssData(dataByteArray) {
    return convertDateData(dataByteArray, true);
}

function convertDateData(dataByteArray, hasSeconds) {
    const requiredDataLen = hasSeconds ? 6 : 5;
    if (dataByteArray.length < requiredDataLen) {
        return "";
    }
    // Byte to Hex
    let byteStr = '';
    for (let i = 0; i < dataByteArray.length; i++) {
        byteStr += dataByteArray[i].toString(16).toUpperCase().padStart(2, "0");
    }
    // BCD16進数とみなして変換し、一桁ごとにパースする
    const dateArr = byteStr.split('');
    const orgYear = '20' + dateArr[0] + dateArr[1];
    const orgMonth = dateArr[2] + dateArr[3];
    const orgDate = dateArr[4] + dateArr[5];
    const orgHours = dateArr[6] + dateArr[7];
    const orgMinutes = dateArr[8] + dateArr[9];
    const orgSeconds = dateArr[10] + dateArr[11];
    // GMTの日付に変換
    const gmtDateStr = `${orgYear}-${orgMonth.padStart(2, "0")}-${orgDate.padStart(2, "0")}T${orgHours.padStart(2, "0")}:${orgMinutes.padStart(2, "0")}:${hasSeconds ? orgSeconds.padStart(2, "0") : '00'}`;
    const dateGmt = new Date(gmtDateStr);
    // 不正な日付か否かの判定(不正な場合はtrue)
    const isInvalid = (dateGmt.toString() === "Invalid Date");
    // 日付が正しい場合はLocal Timeに変更する
    // 日付書式：(秒あり)YYYY/MM/DD HH:MM:SS (秒なし)YYYY/MM/DD HH:MM
    if (!isInvalid) {
        // GMT -> Local Time
        const offset = new Date().getTimezoneOffset() * -1;
        const dateLocal = new Date(dateGmt.valueOf() + (offset * 60 * 1000));
        const year = (dateLocal.getFullYear()).toString();
        const month = (dateLocal.getMonth() + 1).toString();
        const date = (dateLocal.getDate()).toString();
        const hours = (dateLocal.getHours()).toString();
        const minutes = (dateLocal.getMinutes()).toString();
        const seconds = (hasSeconds) ? (dateLocal.getSeconds()).toString() : null;
        // 日付部
        const datePart = `${year.padStart(4, "0")}/${month.padStart(2, "0")}/${date.padStart(2, "0")}`;
        // 時刻部
        let timePart = "";
        timePart = `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`;
        if (hasSeconds) {
            timePart += `:${seconds.padStart(2, "0")}`;
        }
        return `${datePart} ${timePart}`;
    }
    // 日付が不正な場合はHEX値そのままとする
    // 日付書式：(秒あり)YYYY-MM-DDTHH:MM:SSZ (秒なし)YYYY-MM-DDTHH:MMZ
    else {
        const year = orgYear;
        const month = orgMonth;
        const date = orgDate;
        const hours = orgHours;
        const minutes = orgMinutes;
        const seconds = (hasSeconds) ? orgSeconds : null;
        // 日付部
        const datePart = `${year.padStart(4, "0")}-${month.padStart(2, "0")}-${date.padStart(2, "0")}`;
        // 時刻部
        let timePart = "";
        timePart = `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`;
        if (hasSeconds) {
            timePart += `:${seconds.padStart(2, "0")}`;
        }
        return `${datePart}T${timePart}Z`;
    }
}

function convertLatDmmData(dataByteArray) {
    if (dataByteArray.length < 4) {
        return "-";
    }
    const direction = dataByteArray[0] >> 7;
    const degrees = convertValueData([dataByteArray[0] & 0x7F], { signature: "unsigned", scaling_bit: 1, offset: 0, digit_after_decimal: 0 });
    const minutes = convertValueData([dataByteArray[1] >> 2], { signature: "unsigned", scaling_bit: 1, offset: 0, digit_after_decimal: 0 });
    const seconds = convertValueData([((dataByteArray[1] << 8) & 0x300) | dataByteArray[2]], { signature: "unsigned", scaling_bit: 0.1, offset: 0, digit_after_decimal: 1 });
    if (Number(degrees) === 127 || Number(minutes) === 63 || Number(seconds) === 102.3) {
        return "-";
    } else {
        return `${degrees}° ${minutes}' ${seconds}'' ${direction === 0 ? "N" : "S"}`;
    }
}

function convertLngDmmData(dataByteArray) {
    if (dataByteArray.length < 4) {
        return "-";
    }
    const direction = dataByteArray[0] >> 7;
    const degrees = convertValueData([((dataByteArray[0] << 1) & 0xFE) | (dataByteArray[1] >> 7)], { signature: "unsigned", scaling_bit: 1, offset: 0, digit_after_decimal: 0 });
    const minutes = convertValueData([(dataByteArray[1] >> 1) & 0x3F], { signature: "unsigned", scaling_bit: 1, offset: 0, digit_after_decimal: 0 });
    const seconds = convertValueData([((dataByteArray[1] << 9) & 0x200) | (dataByteArray[2] << 1) | (dataByteArray[3] >> 7)], { signature: "unsigned", scaling_bit: 0.1, offset: 0, digit_after_decimal: 1 });
    if (Number(degrees) === 255 || Number(minutes) === 63 || Number(seconds) === 102.3) {
        return "-";
    } else {
        return `${degrees}° ${minutes}' ${seconds}'' ${direction === 0 ? "W" : "E"}`;
    }
}

function convertMfdBcd1Data(dataByteArray) {
    let thousandsDigitValue = 0, hundredsDigitValue = 0, tenthsDigitValue = 0, onesDigitValue = 0;
    if (dataByteArray.length === 1) {
        tenthsDigitValue = (dataByteArray[0] >> 4).toString(16).toUpperCase();
        onesDigitValue = (dataByteArray[0] & 0xF).toString(16).toUpperCase();
    } else if (dataByteArray.length === 2) {
        tenthsDigitValue = (dataByteArray[0] >> 4).toString(16).toUpperCase();
        onesDigitValue = (dataByteArray[0] & 0xF).toString(16).toUpperCase();
        thousandsDigitValue = (dataByteArray[1] >> 4).toString(16).toUpperCase();
        hundredsDigitValue = (dataByteArray[1] & 0xF).toString(16).toUpperCase();
    }
    const valueStr = `${thousandsDigitValue}${hundredsDigitValue}${tenthsDigitValue}${onesDigitValue}`;
    return convertMfdBcdValue(valueStr, 0);
}

function convertMfdBcd2Data(dataByteArray) {
    let tenThousandsDigitValue = 0, thousandsDigitValue = 0, hundredsDigitValue = 0;
    let tenthsDigitValue = 0, onesDigitValue = 0, decimalOneDigitValue = 0;
    let decimalPoints = 0;
    if (dataByteArray.length === 2) {
        decimalPoints = 1;
        onesDigitValue = (dataByteArray[0] >> 4).toString(16).toUpperCase();
        decimalOneDigitValue = (dataByteArray[0] & 0xF).toString(16).toUpperCase();
        tenthsDigitValue = (dataByteArray[0] & 0xF).toString(16).toUpperCase();
    } else if (dataByteArray.length === 3) {
        tenthsDigitValue = (dataByteArray[0] >> 4).toString(16).toUpperCase();
        onesDigitValue = (dataByteArray[0] & 0xF).toString(16).toUpperCase();
        thousandsDigitValue = (dataByteArray[1] >> 4).toString(16).toUpperCase();
        hundredsDigitValue = (dataByteArray[1] & 0xF).toString(16).toUpperCase();
        tenThousandsDigitValue = (dataByteArray[2] & 0xF).toString(16).toUpperCase();
    }
    const valueStr = `${tenThousandsDigitValue}${thousandsDigitValue}${hundredsDigitValue}${tenthsDigitValue}${onesDigitValue}.${decimalOneDigitValue}`;
    return convertMfdBcdValue(valueStr, decimalPoints);
}

function convertMfdBcdValue(valueStr, decimalPoints) {
    let updatedValueStr = "";
    let noNumbersUpperDigits = true;
    for (let index = 0; index < valueStr.length; index++) {
        const charCode = valueStr.charCodeAt(index);
        if (charCode >= 65 && charCode <= 69) {
            // A to E: Convert to 0
            updatedValueStr += "0";
        } else {
            if (valueStr[index] === "F") {
                if (noNumbersUpperDigits && index !== (valueStr.length - 1)) {
                    // index !== (valueStr.length - 1) -> Not the lowest digit
                    updatedValueStr += "-";
                } else {
                    updatedValueStr += "0";
                }
            } else {
                updatedValueStr += valueStr[index];
            }
            if (charCode >= 49 && charCode <= 57) {
                // From 1 to 9
                noNumbersUpperDigits = false;
            }
        }
    }

    const hyphenIndex = updatedValueStr.indexOf("-");
    if (hyphenIndex !== -1) {
        updatedValueStr = updatedValueStr.substring(hyphenIndex);
    }
    if (updatedValueStr === "-0") {
        return "0";
    } else if (updatedValueStr === "-0.0") {
        return "0.0";
    }
    if (decimalPoints === 0) {
        return parseInt(updatedValueStr, 10).toString(10);
    } else {
        return fixNumberWithDecimal(parseFloat(updatedValueStr), decimalPoints);
    }
}

function convertAcl2IncValue(dataByteArray, conversionInfo) {
    // Signedを考慮した物理変換
    const intValue = convertBinToInt(dataByteArray, conversionInfo);
    // 加速度を勾配[%]に変換
    let convertedValue = 100 * Math.tan(Math.asin(0.1 / 9.8 * intValue));
    // 変換結果の上限値を100[%]、下限値を-100[%]とする
    convertedValue = Math.max(Math.min(convertedValue, 100), -100);
    // Return Format number using fixed-point notation.
    return fixNumberWithDecimal(convertedValue, conversionInfo.digit_after_decimal).toString(10);
}

function convertAsciiData(dataByte) {
    return new TextDecoder("latin1").decode(new Uint8Array(dataByte));
}

function convertBinData(dataByte) {
    let displayValue = "";
    for (let index = 0; index < dataByte.length; index++) {
        displayValue += dataByte[index].toString(2).padStart(8, "0");
    }
    return displayValue;
}

function convertYymmddData(dataByte) {
    let displayValue;
    if (dataByte.length >= 3) {
        const year = dataByte[0].toString().padStart(2, "0");
        const month = dataByte[1].toString().padStart(2, "0");
        const date = dataByte[2].toString().padStart(2, "0");
        displayValue = `${year}.${month}.${date}`;
    }
    return displayValue;
}

function convertYyyymmddData(dataByte) {
    let displayValue;
    if (dataByte.length >= 4) {
        const year = (dataByte[0] << 8) | dataByte[1];
        const month = dataByte[2];
        const date = dataByte[3];
        if (year <= 0 || month <= 0 || month >= 13 || date <= 0 || date >= 32) {
            displayValue = "-";
        } else {
            displayValue = `${year.toString().padStart(4, "0")}/${month.toString().padStart(2, "0")}/${date.toString().padStart(2, "0")}`;
        }
    }
    return displayValue;
}

function convertMin2hhmmData(dataByte) {
    const dataView = new DataView(new Uint8Array(dataByte.slice(0, 2)).buffer);
    const totalSeconds = dataView.getUint16(0);
    const hours = Math.floor(totalSeconds / 60).toString().padStart(2, "0");
    const minutes = (totalSeconds % 60).toString().padStart(2, "0");
    return `${hours} hrs, ${minutes} min`;
}

function convertSec2hhmmData(dataByte) {
    const dataView = new DataView(new Uint8Array(dataByte.slice(0, 4)).buffer);
    const totalSeconds = dataView.getUint32(0);
    const hours = Math.floor(totalSeconds / 3600).toString().padStart(2, "0");
    const minutes = Math.floor((totalSeconds % 3600) / 60).toString().padStart(2, "0");
    return `${hours} hrs, ${minutes} min`;
}

function convertSec2hhmmssData(dataByte) {
    const dataView = new DataView(new Uint8Array(dataByte.slice(0, 4)).buffer);
    const totalSeconds = dataView.getUint32(0);
    const hours = Math.floor(totalSeconds / 3600).toString().padStart(2, "0");
    const minutes = Math.floor((totalSeconds % 3600) / 60).toString().padStart(2, "0");
    const seconds = ((totalSeconds % 3600) % 60).toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
}

function convertLatDegData(dataByte) {
    const latDeg = Number(convertValueData(dataByte, { signature: "signed", byte_or_bit: "byte", bit_size: 0, scaling_bit: 1, offset: 0, digit_after_decimal: 0 }));
    const latitude = (latDeg / 3600000);
    return `${latitude >= 0 ? "N" : "S"}${fixNumberWithDecimal(Math.abs(latitude), 4)}°`;
}

function convertLngDegData(dataByte) {
    const lngDeg = Number(convertValueData(dataByte, { signature: "signed", byte_or_bit: "byte", bit_size: 0, scaling_bit: 1, offset: 0, digit_after_decimal: 0 }));
    const longitude = (lngDeg / 3600000);
    return `${longitude >= 0 ? "E" : "W"}${fixNumberWithDecimal(Math.abs(longitude), 4)}°`;
}

function convertLatLngDmmData(dataByte) {
    let displayValue;
    const latDmm = convertLatDmmData(dataByte.slice(0, 4));
    const lngDmm = convertLngDmmData(dataByte.slice(4, 8));
    if (latDmm === "-" || lngDmm === "-") {
        displayValue = "-";
    } else {
        displayValue = `${latDmm},${lngDmm}`;
    }
    return displayValue;
}

function convertDecWithDotData(dataByte) {
    const decimalsValues = [];
    for (let index = 0; index < dataByte.length; index++) {
        decimalsValues.push(dataByte[index].toString(10));
    }
    return decimalsValues.join(".");
}

export default {
    convertDataToDisplayValue,
    convertHexData,
    convertValueData,
    convertValueDataForHex,
    convertUnitValueData,
    convertPhysicalValueData,
    convertBinToInt,
    convertMin2hhmmData,
    convertSec2hhmmData
};