<div class="container-fluid individual-active-test">
  <!-- メモボタン -->
  <div v-show="!showDmSelect && !noSignalItems" class="one-liner-btns">
    <div class="left-side-btn">
      <b-button
        class="mt-n5 ml-n4"
        variant="primary"
        size="sm"
        @click="openMemo"
        :disabled="btnDisabled.memo"
      >
        {{ $t('message.button_vehicle_dtc_note_modal') }}
      </b-button>
    </div>
    <div class="right-side-btn">
      <b-button
        class="mt-n5"
        variant="primary"
        size="sm"
        @click="handleConfigureGraph"
        :disabled="btnDisabled.graph"
      >
        {{ $t('individual.button_data_monitor_graph_config') }}
      </b-button>
    </div>
  </div>

  <!-- アクティブテスト項目選択 -->
  <div v-show="showAtSelect" class="at-select row">
    <div class="text-left col-6">
      <b-form autocomplete="off">
        <b-form-input
          v-model="filterText"
          type="text"
          class="w-100 mt-4"
          size="sm"
          autocomplete="off"
          :placeholder="$t('individual.label_active_test_filter')"
          @input="updateFilter"
        />
      </b-form>
      <cstm-table
        useStripeStyle
        rowSelect
        usePopup
        useSort
        hasTabs
        class="support-table-at mt-2"
        type="simple"
        ref="filtered_tbl"
        :currentSortkey="currentSortkey"
        :items="escapeFunctionList(filteredFunctionList)"
        :columns="columns"
        @rowClicked="clickRow"
      >
      </cstm-table>
    </div>
    <div class="text-left col-6">
      <b-form-group>
        <label style="font-weight: bold;" class="label-sm mt-4"
          >{{ $t('individual.common_label_description') }}</label
        >
        <b-form-textarea
          type="text"
          class="mt-3"
          style="background-color: white;"
          no-resize
          size="sm"
          rows="12"
          readonly
          tabindex="-1"
          :placeholder="$t('individual.common_label_description')"
          :value="selectedActiveTest ? selectedActiveTest.menu_description : ''"
        >
        </b-form-textarea>
        <div class="text-right w-100">
          <b-button
            size="sm"
            class="mt-2"
            variant="primary"
            @click="selectAtFunc()"
          >
            {{ $t('individual.button_active_test_select') }}
          </b-button>
        </div>
      </b-form-group>
    </div>
  </div>

  <div v-show="!showAtSelect" class="contents-area">
    <!-- コントロールパネル API実行順のためv-show -->
    <control-panel
      v-show="!showDmSelect"
      class="control-panel"
      ref="control_panel"
      :selectedAtFunc="selectedAtFunc"
      :i18nMenuTitleKey="i18nMenuTitleKey"
      :isDriving="isDriving"
      :inExecution="inExecution"
      @toggle-drive="toggleDrive"
      @toggle-execute="toggleExecute"
      @toggle-read-executed="toggleReadExecuted"
      @err-terminate="errTerminate"
      @at-failure="atFailure"
    />

    <!-- データモニタ -->
    <data-monitor
    v-if="!showAtSelect"
    ref="data_monitor"
    :parentName="parentName"
    :measureId="measureId"
    :showSelect="showDmSelect"
    :inExecution="inExecution"
    :inPlaying="inPlaying"
    :isUseCache="isUseCache"
    :highlightItems="highlightItems"
    :dataMonitorFunctionSettings="dataMonitorFunctionSettings"
    :selectedActiveTestId="activeTestId"
    @toggle-show-select="toggleShowDmSelect"
    @toggle-execute="toggleExecute"
    @toggle-playing="togglePlaying"
    @change-measure-id="setMeasuredId"
    @back-select="backMeasure"
    @exec-stop-monitor="stopMonitor"
    @change-check-value = "changeCheckValue"
    @change-no-signal="changeNoSignal"
  />
  </div>

  <!-- 選択の画面下部ボタン -->
  <div v-if="!showAtSelect && showDmSelect" class="bottom-btns">
    <b-button variant="outline-primary" size="sm" @click="backSelect">
      {{ $t("message.common_button_back") }}
    </b-button>
  </div>

  <!-- メモ画面 -->
  <b-modal
    v-model="modalMemo"
    size="lg"
    header-bg-variant="secondary"
    header-text-variant="light"
    no-stacking
    no-close-on-backdrop
    no-close-on-esc
    centered
    scrollable
  >
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.header_vehicle_dtc_note_modal') }}
      </div>
    </template>

    <memo-page
      v-if="modalMemo"
      ref="memo"
      :projectId="projectId"
      :measureId="measureId"
      :functionNameKey="navItemLabel"
      :destinationAPI="destinationAPI"
      :modalLoading="isMemoDialogLoading"
      @closeNoteModal="closeNoteModal"
      @update-loading="isMemoDialogLoading = $event"
    />

    <template v-slot:footer>
      <button
        type="button"
        :disabled="isMemoDialogLoading"
        v-on:click="memoClear()"
        class="btn clear-button ml-3 btn-outline-primary btn-sm rounded-pill"
      >
        {{ $t("message.common_button_clear") }}
      </button>
      <div class="text-center w-100">
        <b-button
          size="sm"
          :disabled="isMemoDialogLoading"
          variant="outline-primary"
          @click="modalMemo = false"
          class="ml-2 mr-2"
        >
          {{ $t("message.common_button_cancel") }}
        </b-button>
        <b-button
          size="sm"
          :disabled="isMemoDialogLoading"
          variant="primary"
          @click="memoSave()"
          class="ml-2 mr-2"
        >
          {{ $t('message.common_button_save') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <processing-view :processing="processing"></processing-view>
</div>
