import session from '../../../../share/session';
import individualWorkSupportUtil from '../../../../share/util/individualWorkSupportUtil';
import error from '../../../../common/nodejs/errors';
import { REQUEST_STATUS_COMPLETED, REQUEST_STATUS_FAILED } from '../../../../share/util/api/polling';
import { PHASE } from './constants';

/** DTC確認 */
export async function checkDTC(self) {
  try {
    self.phase = PHASE.WAITING;
    self.isLoading = true;
    const token = session.getToken();
    const response = await individualWorkSupportUtil.pollingWspApi(
      self,
      self.netAppId,
      self.systemId,
      self.workSupportId,
      // POST リクエストの body を指定する
      {
        id_token: token.id_token,
        type: 'check_dtc'
      },
      // エラー発生のコールバックを実装する。
      // ここでは利用していないが、引数の errorType にはあらかじめ用意された定数文字列が返ってくる。
      (errorType) => { errorType; self.showUnexpectedError(); },
      // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
      // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
    );

    if (response) {
      // エラーコールバックが呼ばれず、completed / failed が返ってきてポーリングが終了した時のみ、最終的な GET response が返ってくる。
      // エラーコールバックが呼ばれた場合は、null になる。
      switch (response.data.status) {
        case REQUEST_STATUS_COMPLETED:
          // 正常終了：check_mapを実行
          await self.checkMap();
          break;
        case REQUEST_STATUS_FAILED:
          // 異常終了
          switch (response.data.async_code) {
            case error.WSP_40001_TIMEOUT_ERROR:
              // タイムアウトエラー画面に遷移
              self.phase = PHASE.TIMEOUT_ERROR;
              break;
            case error.WSP_40001_ASSIST_MAP_UNRECORDED_ERROR:
              // 記録済み画面に遷移
              self.phase = PHASE.RECORDED_ERROR;
              break;
            case error.WSP_40001_ASSIST_MAP_IDENTIFICATION_ERROR:
              // 記録済み確認画面に遷移
              self.phase = PHASE.RECORDED_CONFIRMATION_ERROR;
              break;
            default:
              // 通信異常画面に遷移
              self.showUnexpectedError();
              break;
          }
          break;
        default:
          // 通信異常画面に遷移
          self.showUnexpectedError();
          break;
      }
    }
  } finally {
    self.isLoading = false;
  }
}