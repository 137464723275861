<!-- ステアリングセンサ補正 -->
<b-modal
  v-model="modal_requirement_40002"
  size="lg"
  :header-bg-variant="headerColor"
  header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking
  >
  <template v-slot:header>
    <div class="text-center w-100">
      {{ headerTitle }}
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <div class="w-100">
          <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
            workSupportMessage }}</div>
        </div>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer="{ close, ok }">
    <div class="w-100 text-center ">
      <template v-if="phase === 'start' || phase === 'torque_execute'">
        <!-- キャンセルボタン -->
        <b-button size="sm" variant="outline-primary" @click="onCancelClicked" class="mx-2 mt-2" :disabled="isLoading">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <!-- 次へボタン -->
        <b-button size="sm" variant="primary" @click="onNextClicked" class="mx-2 mt-2" :disabled="isLoading">
          {{ $t('message.button_agreement_confirm') }}
        </b-button>
      </template>
      <template v-if="phase === 'stop'">
        <!-- キャンセルボタン -->
        <b-button size="sm" variant="outline-primary" @click="onCancelClicked" class="mx-2 mt-2" :disabled="isLoading">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <!-- OKボタン -->
        <b-button size="sm" variant="primary" @click="onStopOkClicked" class="mx-2 mt-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'init_confirm' || phase === 'init_complete' || phase === 'torque_start'">
        <!-- 戻るボタン -->
        <b-button size="sm" variant="outline-primary" @click="onBackClicked" class="mx-2 mt-2" :disabled="isLoading">
            {{ $t('message.common_button_back') }}
        </b-button>
        <!-- キャンセルボタン -->
        <b-button size="sm" variant="outline-primary" @click="onCancelClicked" class="mx-2 mt-2" :disabled="isLoading">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <!-- 次へボタン -->
        <b-button size="sm" variant="primary" @click="onNextClicked" class="mx-2 mt-2" :disabled="isLoading">
          {{ $t('message.button_agreement_confirm') }}
        </b-button>
      </template>
      <!-- 終了ボタン -->
      <b-button v-if="showFinishButton" size="sm" variant="primary" @click="onFinishClicked" class="mx-2 mt-2" :disabled="isLoading">
        {{ $t('individual.label_button_finish') }}
      </b-button>
    </div>
  </template>
</b-modal>