import config from "../../../../../share/config";
import holdDownButton from "../../../../../share/components/vue/HoldDownButton";

export default {
  props: {
    items: { type: Array, default: null },
    columns: { type: Array, default: null },
    currentSortkey: { type: String, default: "" },
    usePopup: { type: Boolean, default: false },
    rowSelect: { type: Boolean, default: false },
    maxTableHeight: { type: Number, default: 0 },
    useSort: { type: Boolean, default: false },
    initSortDir: { type: String, default: config.DESC },
    excludeSortVal: { type: String, default: null },
    useStripeStyle: { type: Boolean, default: false },
    fixedHeader: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      currentSort: "",
      currentSortDir: this.initSortDir,
      propItems: null,
      opened: [],
      columnLength: 0,
      tableHeaderHeight: 0,
      displayMaxTableHeight: 0,
      // ポップアップ情報 Object
      popup: {
        id: "",
        name: "",
        variant: "danger",
      },
    };
  },

  components: {
    "hold-down-button": holdDownButton
  },

  watch: {
    items(value) {
      // 設定されたitem情報に変更があった場合には初期化処理を実施する。
      this.opened = [];
      this.currentSort = this.currentSortkey;
      if (value && value.length <= 0) {
        this.propItems = null;
        this.displayCount = "-";
      } else {
        this.propItems = value.concat();
      }
    },

    // 初期生成時も監視
    columns: {
      handler(val) {
        if (val && val.length <= 0) {
          this.columnLength = 0;
        } else {
          this.columnLength = val.length;
        }
      },
      immediate: true,
    },

    maxTableHeight(valu) {
      this.displayMaxTableHeight = valu - this.tableHeaderHeight;
    },
  },

  methods: {
    sort(s) {
      if (!this.useSort && !s.useSort) return;
      if (s.name === this.currentSort) {
        this.currentSortDir =
          this.currentSortDir === config.ASC ? config.DESC : config.ASC;
      }
      this.currentSort = s.name;
    },
    rowClicked(user) {
      this.$emit("rowClicked", user);
    },

    dataClicked(useDataSelect, rowIndex, columnIndex, columnName, value) {
      // useDataSelectがtrueの場合、行番号・列番号・カラム名・表示値を返す
      if (useDataSelect) {
        const selectedData = {
          rowIndex: rowIndex,
          columnIndex: columnIndex,
          columnName: columnName,
          value: value,
        };
        this.$emit("dataClicked", selectedData);
      }
    },

    buttonClicked(event, data) {
      this.$emit("rowButtonClicked", data);
      event.stopPropagation();
    },

    handleLeftBtnClick(event, data) {
      this.$emit("left-btn-click", data);
      event.stopPropagation();
    },

    handleRightBtnClick(event, data) {
      this.$emit("right-btn-click", data);
      event.stopPropagation();
    },

    /**
     * 左矢印ボタン長押し時のイベント処理
     * @param {Event} event イベントオブジェクト
     * @param {Object} data 値を変更した項目の情報
     */
    handleLeftBtnHold(event, data) {
      this.$emit("left-btn-hold", data);
      event.stopPropagation();
    },

    /**
     * 右矢印ボタン長押し時のイベント処理
     * @param {Event} event イベントオブジェクト
     * @param {Object} data 値を変更した項目の情報
     */
    handleRightBtnHold(event, data) {
      this.$emit("right-btn-hold", data);
      event.stopPropagation();
    },

    expanded(event, id, childrenData) {
      const index = this.opened.indexOf(id);
      let isOpen = false;
      if (index > -1) {
        this.opened.splice(index, 1);
        isOpen = false;
      } else {
        this.opened.push(id);
        isOpen = true;
      }

      const expandedData = {
        isOpen,
        data: childrenData,
      };

      this.$emit("rowExpanded", expandedData);
      event.stopPropagation();
    },

    onMouseHover(id, name, value) {
      if (this.usePopup) {
        this.popup.name = "";
        this.popup.id = id;

        const data = {
          rowData: {
            columnName: name,
            value,
          },
          popup: this.popup,
        };
        this.$emit("mouseHover", data);
      }
    },

    getColumnClasses(data, columnName) {
      if (!data.fieldSpec || !data.fieldSpec[columnName]) {
        return {};
      }

      const columnConfig = data.fieldSpec[columnName];
      return {
        "field-highlight": columnConfig === config.LIST_ITEM_HIGHLIGHT,
        "field-error": columnConfig === config.LIST_ITEM_ERROR,
        "field-bold":
          columnConfig === config.LIST_ITEM_BOLD ||
          columnConfig === config.LIST_ITEM_HIGHLIGHT,
        "field-disable": columnConfig === config.LIST_ITEM_DISABLE,
      };
    },

    scrollToTop() {
      this.$nextTick(() => {
        const chatLog = this.$refs.table_tbody;
        if (!chatLog) return;
        chatLog.scrollTop = 0;
      });
    },

    getTableStyle() {
      const type = this.useStripeStyle ? "table-dr-style" : "simple-table";
      const fixed = this.fixedHeader ? "fixed-header" : "";
      return { [type]: true, [fixed]: true };
    },

    // テーブル行に適用するスタイルを指定
    // @params style メインフローから指定されるスタイル
    // @params index テーブルの行インデックス
    getTdStyle(style, index) {
      if (!style) {
        return {
          "table-row-stripe": index % 2 === 0 && this.useStripeStyle,
        };
      }
      return {
        [style]: true,
        "table-row-stripe": index % 2 === 0 && this.useStripeStyle,
      };
    },
  },

  computed: {
    sortedActivity() {
      let resultItems = null;
      if (this.propItems) {
        // 独自ソート処理があるかチェック
        const hitIndex = this.columns.findIndex(
          (element) => element.name === this.currentSort
        );
        if (hitIndex !== -1) {
          if ("originalSortFunc" in this.columns[hitIndex]) {
            // 独自ソート処理を実施
            const param = {
              data: this.propItems,
              sortDir: this.currentSortDir,
              sortKey: this.currentSort,
            };
            resultItems = this.columns[hitIndex].originalSortFunc(param);
            return resultItems;
          }
        }

        /* eslint-disable-next-line complexity */
        resultItems = this.propItems.sort((a, b) => {
          if (
            a[this.currentSort] !== a[this.currentSort] &&
            b[this.currentSort] !== b[this.currentSort]
          )
            return 0;
          if (a[this.currentSort] !== a[this.currentSort]) return 1;
          if (b[this.currentSort] !== b[this.currentSort]) return -1;

          if (a[this.currentSort] === null && b[this.currentSort] === null)
            return 0;
          if (a[this.currentSort] === null) return 1;
          if (b[this.currentSort] === null) return -1;

          if (a[this.currentSort] === "" && b[this.currentSort] === "")
            return 0;
          if (a[this.currentSort] === "") return 1;
          if (b[this.currentSort] === "") return -1;

          if (this.excludeSortVal) {
            if (
              a[this.currentSort] === this.excludeSortVal &&
              b[this.currentSort] === this.excludeSortVal
            )
              return 0;
            if (a[this.currentSort] === this.excludeSortVal) return 1;
            if (b[this.currentSort] === this.excludeSortVal) return -1;
          }

          const sig = this.currentSortDir === config.DESC ? 1 : -1;
          return a[this.currentSort] < b[this.currentSort]
            ? sig
            : a[this.currentSort] > b[this.currentSort]
              ? -sig
              : 0;
        });
      }
      return resultItems;
    },

    getSortIcon() {
      return this.currentSortDir === config.ASC ? " ▲" : " ▼";
    },
  },

  mounted() {
    if (this.$refs.tableHeader && this.$refs.tableColumnHeader) {
      this.tableHeaderHeight =
        this.$refs.tableHeader.clientHeight +
        this.$refs.tableColumnHeader.clientHeight;
      this.displayMaxTableHeight = this.maxTableHeight - this.tableHeaderHeight;
    }
  },
};
