import { mapState, mapActions } from 'vuex';
import loading from '../../../share/components/vue/ProcessingIcon';
import cstmTable from '../../../share/components/vue/PaginationTable';
import util from '../../../share/util/utils';
import resource from '../../../share/util/resourceMap';
import diagnoResource from "../../../share/util/diagnose";
import ffdModal from '../../vue/FFDModal';
import apiService from '../../../share/util/api/service';
import handler from '../../../share/util/apiResultValidator';

/** 各フェーズ定義 */
const PHASE = {
  DTC_DISPLAY: 'dtcDisplay',
  DETAILED_FFD: 'detailedFfd',
  READINESS_CODE_DISPLAY: 'readinessCodeDisplay'
};

/** 対象DTC一覧 */
const TARGET_DTC = [
  'P0455', 'P0456', 'P04AF', 'P1451', 'P2402', 'P2404', 'P2420', 'P24BA', 'P24BB', 'P2401',
  'P2419', 'P0458', 'P2450', 'P04ED', 'P04EF', 'P04EC', 'P04EE', 'P2451', 'P2421', 'P0452',
  'P0453', 'P04AE', 'P0459', 'P04AC', 'P04AD', 'P1400',
  'P045500', 'P045600', 'P04AB73', 'P00FE00', 'P240012', 'P240392', 'P241812', 'P24B811', 'P24B815', 'P240014',
  'P241814', 'P044314', 'P241872', 'P04ED00', 'P04EF00', 'P04EC00', 'P04EE00', 'P241873', 'P044614', 'P045011',
  'P045015', 'P04AB07', 'P044312', 'P04AB14', 'P04AB12', 'P140011'
];

export default {
  name: 'detailDtcModal',
  data() {
    return {
      isLoading: false,
      phase: PHASE.DTC_DISPLAY,
      detailDtcTableColumns: [],
      detailDtcTableItems: [],
      currentFFDContents: {},
      readinessCodeTableItems: [],
      readinessCodeTableColumns: [],
      i18nWsDidKey: undefined,
      chainDtcType: 'normal',
      ffdTitle: '',
      ffdSystem: '',
      ffdDescription: '',
      ffdSystemTable: [],
      cachedFFDs: [],
      selectedSystem: {},
      dtcResult: [],
    };
  },

  props: {
    readinessCodeDataProp: { type: Object, default: [], required: false },
    dataPlayback: { type: Boolean, default: false, required: false },
    systemId: { type: String, default: "", required: false },
    projectId: { type: String, default: "", required: false },
    linkedIds: { type: Object, default: {}, required: false },
  },

  components: {
    'processing-view': loading,
    'cstm-table-modal': cstmTable,
    'ffd-modal': ffdModal
  },

  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('cache', ['cacheProjectDetails']),

    /**
     * 詳細DTC 一覧の生成
     */
    updateDetailDtcTable(readDtcList) {
      this.dtcResult = readDtcList;
      this.detailDtcTableColumns = [
        { name: "status", label: "message.header_vehicle_dtc_table_status", useResource: true },
        { name: 'dtc', label: "message.header_vehicle_dtc_table_code", originalSortFunc: diagnoResource.sortFuncCode },
        { name: 'description', label: "message.header_vehicle_dtc_table_description", useResource: true, originalSortFunc: diagnoResource.sortFuncDescription },
        { name: "ffd", label: "message.header_vehicle_dtc_table_ffd", useButton: true, originalSortFunc: diagnoResource.sortFuncFfd }
      ];
      // テーブル表示情報初期化
      this.detailDtcTableItems = [];
      let filteredDtcSystems = [];
      // 対象DTCでフィルタ
      filteredDtcSystems = readDtcList
        .filter(dtc => TARGET_DTC.includes(dtc.code))
        .filter(dtc => dtc.status_mask.pending || dtc.status_mask.confirmed);

      // テーブル作成
      const targetDtcList = resource.createDtcTable(filteredDtcSystems, this.systemId, this);
      this.$nextTick(() => {
        this.detailDtcTableItems = this.detailDtcTableItems.concat(targetDtcList);
      });
    },

    /**
     * DTC表示画面 FFDボタン押下時処理
     * @param {object} data DTC表示FFDボタン押下行のレコード情報
     */
    async rowButtonClickEvent(data) {
      try {
        if (!data) return;
        this.isLoading = true;
        // タイトルの設定
        const status = data.status ? ` (${data.status}) ` : '';
        this.ffdTitle = data.dtc + status;
        this.ffdSystem = this.$t(data.name);
        this.ffdDescription = this.$t(data.description);
        const cache = this.cachedFFDs.filter(e => e.system === data.systemID && e.dtc === data.dtc);
        if (cache.length > 0) {
          // キャッシュ有りならキャッシュ済みデータを表示
          this.ffdSystemTable = cache[0].data;
          this.selectedSystem = data;
          this.phase = PHASE.DETAILED_FFD;
          this.$emit('dtcModalPhaseChange', PHASE.DETAILED_FFD);
        } else {
          // キャッシュなしなら FFD 一覧情報を取得
          // 選択された任意のシステムのFFD一覧情報を取得
          const endPoint = `/diagDtc/${this.projectId}/${this.linkedIds.dtc}/ffd/${this.systemId}/${data.dtc}`;
          const res = await apiService.getCall(apiService.DIAG, endPoint, this.operationScreen);
          handler.validate(handler.validateTypes.all, res, this, null, () => {
            // 取得情報のキャッシュ
            this.cachedFFDs.push({
              system: data.systemID,
              dtc: data.dtc,
              data: res.data.data
            });
            // FFDダイアログの表示
            this.ffdSystemTable = res.data.data;
            this.selectedSystem = data;
            this.phase = PHASE.DETAILED_FFD;
            this.$emit('dtcModalPhaseChange', PHASE.DETAILED_FFD);
          }, (result) => {
            // 処理が失敗したときのコールバック処理
            if (result.behaviorType === handler.behaviorTypes.logout) this.logout(this);
          });
        }
      } finally {
        this.isLoading = false;
      }
    },

    /** DTC表示画面へ遷移 */
    async dtcModalProcess() {
      this.phase = PHASE.DTC_DISPLAY;
      this.$emit('dtcModalPhaseChange', PHASE.DTC_DISPLAY);
      this.updateDetailDtcTable(this.dtcResult);
    },

    /** DTC表示画面 レディネスコードボタン押下 */
    readinessCodeProcess() {
      // レディネスコード表示画面へ遷移
      this.phase = PHASE.READINESS_CODE_DISPLAY;
      this.$emit('dtcModalPhaseChange', PHASE.READINESS_CODE_DISPLAY);
      // レディネスコード結果一覧作成処理
      try {
        this.readinessCodeTableColumns = [
          { name: "status", label: "message.header_vehicle_dtc_table_status", useResource: true },
          { name: "dtc", label: "message.header_vehicle_dtc_table_code", originalSortFunc: diagnoResource.sortFuncCode },
          { name: "description", label: "message.header_vehicle_dtc_table_description", useResource: true, originalSortFunc: diagnoResource.sortFuncDescription }
        ];
        // テーブル表示情報初期化
        this.readinessCodeTableItems = [];
        let filteredDtcSystems = [];
        // 対象DTCでフィルタ
        filteredDtcSystems = this.dtcResult
          .filter(dtc => TARGET_DTC.includes(dtc.code))
          .filter(dtc => dtc.status_mask.not_completed);

        // テーブル作成
        const targetDtcList = resource.createDtcTable(filteredDtcSystems, this.systemId, this);
        for (let i = 0; i < targetDtcList.length; i++) {
          if (targetDtcList[i].status !== 'message.j_status_vehicle_dtc_status_no_dtc') {
            targetDtcList[i].status = `individual.label_readiness_code`;
          }
        }
        this.$nextTick(() => {
          this.readinessCodeTableItems = this.readinessCodeTableItems.concat(targetDtcList);
        });
      } catch {
        this.showUnexpectedError();
      }
    }
  },

  computed: {
    ...mapState({
      cachedDetails: state => state.cache.projectDetails,
      vehicleInfo: state => state.vehicle.vehicleInfo
    }),
    vinForCsv() {
      const vin = this.dataPlayback ? this.cachedDetails.information.vin : this.vehicleInfo.vehicle.vin.vin;
      return util.escapeForCsv(vin);
    },
    operationScreen() {
      return diagnoResource.operationFrom(this.$router.currentRoute.value.name);
    },
  },

  /**
   * createdイベント
   */
  created() {
    // noop
  },

  /**
   * mountedイベント
   */
  mounted() {
    // noop
  },
};