import apiService from '../../../../share/util/api/service';
import handler from '../../../../share/util/apiResultValidator';

/**
 * プロジェクト情報更新処理
 * @param {*} self this
 * @param {*} measuredId measured_id
 * @returns 実行結果
 */
export async function update(self, measuredId) {
  const endPoint = '/projects/' + self.vehicleInfo.vehicle.project.id + '/' + measuredId;
  const reqBody = {
    name: self.vehicleInfo.vehicle.project.id,
    comment: self.diagnoseComment,
    measured: {
      name: self.dataName,
      memo: self.memo
    }
  };
  const res = await apiService.postCall(apiService.WSP, endPoint, reqBody, self.operationScreen);
  let ret = false;
  // APIレスポンスの検証
  handler.validate(handler.validateTypes.all, res, self, null, () => {
    ret = true;
  }, (result) => {
    // レスポンス不正時のコールバック
    if (result.behaviorType === handler.behaviorTypes.logout) self.logout(self);
  });
  return ret;
}