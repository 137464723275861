import config from '../../../config';

export default {
  props: {
    type: { type: String, default: "page" },
    inActive: { type: Boolean, default: false },
    fixedScroll: { type: Boolean, default: false },
    items: { type: Array, default: null },
    columns: { type: Array, default: null },
    currentSortkey: { type: String, default: "" },
    usePopup: { type: Boolean, default: false },
    rowSelect: { type: Boolean, default: false },
    maxTableHeight: { type: Number, default: 0 },
    hasTabs: { type: Boolean, default: false },
    useSort: { type: Boolean, default: false },
    initSortDir: { type: String, default: config.DESC },
    excludeSortVal: { type: String, default: null },
    useStripeStyle: { type: Boolean, default: false },
    // TODO: v2マージ後のフルテストのタイミングでフラグを削除し、他のテーブルに影響がないかを確認する
    useFixSort: { type: Boolean, default: false },
    printSetting: { type: String, default: null },
  },
  data() {
    return {
      currentSort: '',
      currentSortDir: this.initSortDir,
      propItems: null,
      opened: [],
      columnLength: 0,
      tableHeaderHeight: 0,
      displayMaxTableHeight: 0,
      // ポップアップ情報 Object
      popup:
      {
        id: '',
        name: '',
        // defaultはBootstrapのDangerStlye
        style: 'background:#f8d7da; border-color: #f5c2c7;'
      }
    };
  },

  watch: {
    items: {
      // immediate 設定をしていないので items は初期処理時に起動しない。
      handler(value) {
        // TODO: v2マージ後のフルテストのタイミングで分岐フラグとソート初期化処理を削除し、他のテーブルに影響がないかを確認する
        if (!this.useFixSort) {
          // 初期ソートの設定
          this.currentSort = this.currentSortkey;
        }
        if (value && value.length <= 0) {
          this.currentSort = this.currentSortkey;
          this.propItems = null;
          this.displayCount = '-';
          this.opened = [];
        }
        else {
          this.propItems = value.concat();
        }
      },
      deep:true
    },

    // 初期生成時も監視
    columns: {
      handler(val) {
        if (val && val.length <= 0) {
          this.columnLength = 0;
        }
        else {
          this.columnLength = val.length;
        }
      },
      immediate: true,
    },

    maxTableHeight(valu) {
      this.displayMaxTableHeight = valu - this.tableHeaderHeight;
    }

  },

  methods: {
    sort(s) {
      if (s.name === this.currentSort) {
        this.currentSortDir = this.currentSortDir === config.ASC ? config.DESC : config.ASC;
      }
      this.currentSort = s.name;
    },
    getSortIcon() {
      if (this.currentSortDir === config.ASC) {
        return "<span> ▲</span>";
      } else {
        return "<span> ▼</span>";
      }
    },
    rowClicked(user) {
      this.$emit("rowClicked", user);
    },

    dataClicked(useDataSelect, rowIndex, columnIndex, columnName, value) {
      // useDataSelectがtrueの場合、行番号・列番号・カラム名・表示値を返す
      if (useDataSelect) {
        const selectedData = {
          rowIndex: rowIndex,
          columnIndex: columnIndex,
          columnName: columnName,
          value: value
        };
        this.$emit("dataClicked", selectedData);
      }
    },

    buttonClicked(event, data) {
      this.$emit("rowButtonClicked", data);
      event.stopPropagation();
    },

    expanded(event, id, childrenData) {
      const index = this.opened.indexOf(id);
      let isOpen = false;
      if (index > -1) {
        this.opened.splice(index, 1);
        isOpen = false;
      } else {
        this.opened.push(id);
        isOpen = true;
      }

      const expandedData = {
        isOpen,
        data: childrenData
      };

      this.$emit("rowExpanded", expandedData);
      event.stopPropagation();
    },

    onMouseHover(id, name, value) {
      if (this.usePopup) {
        this.popup.name = '';
        this.popup.id = id;

        const data = {
          rowData: {
            columnName: name,
            value
          },
          popup: this.popup
        };
        this.$emit("mouseHover", data);
      }
    },

    getColumnClasses(data, columnName) {
      if (!data.fieldSpec || !data.fieldSpec[columnName]) {
        return {};
      }

      const columnConfig = data.fieldSpec[columnName];
      return {
        'field-highlight': columnConfig === config.LIST_ITEM_HIGHLIGHT,
        'field-error': columnConfig === config.LIST_ITEM_ERROR,
        'field-bold': columnConfig === config.LIST_ITEM_BOLD || columnConfig === config.LIST_ITEM_HIGHLIGHT,
        'field-disable': columnConfig === config.LIST_ITEM_DISABLE
      };
    },

    scrollToTop() {
      this.$nextTick(() => {
        const chatLog = this.$refs.table_tbody;
        if (!chatLog) return;
        chatLog.scrollTop = 0;
      });
    },

    getTableStyle() {
      const type = this.useStripeStyle ? 'table-dr-style' : 'simple-table';
      return { 'table-active': this.inActive, [type]: true };
    },

    getScrollStyle() {
      const scroll = this.fixedScroll ? '' : 'table-responsive';
      return { 'scroll-active': false, [scroll]: true };
    },
    
    // テーブル行に適用するスタイルを指定
    // @params style メインフローから指定されるスタイル
    // @params index テーブルの行インデックス
    getTdStyle(style, index) {
      return { [style]: true, 'table-row-stripe': (index % 2 === 0) && this.useStripeStyle };
    },
  },

  computed: {
    /* eslint-disable complexity */
    sortedActivity() {
      let resultItems = null;
      if (this.propItems) {

        // 独自ソート処理があるかチェック
        const hitIndex = this.columns.findIndex(element => element.name === this.currentSort);
        if (hitIndex !== -1) {
          if ('originalSortFunc' in this.columns[hitIndex]) {
            // 独自ソート処理を実施
            const param = { data: this.propItems, sortDir: this.currentSortDir, sortKey: this.currentSort };
            resultItems = this.columns[hitIndex].originalSortFunc(param);
            return resultItems;
          }
        }

        resultItems = this.propItems
          .sort((a, b) => {
            if (a[this.currentSort] !== a[this.currentSort] && b[this.currentSort] !== b[this.currentSort]) return 0;
            if (a[this.currentSort] !== a[this.currentSort]) return 1;
            if (b[this.currentSort] !== b[this.currentSort]) return -1;

            if (a[this.currentSort] === null && b[this.currentSort] === null) return 0;
            if (a[this.currentSort] === null) return 1;
            if (b[this.currentSort] === null) return -1;

            if (a[this.currentSort] === '' && b[this.currentSort] === '') return 0;
            if (a[this.currentSort] === '') return 1;
            if (b[this.currentSort] === '') return -1;

            if (this.excludeSortVal) {
              if (a[this.currentSort] === this.excludeSortVal && b[this.currentSort] === this.excludeSortVal) return 0;
              if (a[this.currentSort] === this.excludeSortVal) return 1;
              if (b[this.currentSort] === this.excludeSortVal) return -1;
            }

            const sig = this.currentSortDir === config.DESC ? 1 : -1;
            return a[this.currentSort] < b[this.currentSort] ? sig : (a[this.currentSort] > b[this.currentSort] ? -sig : 0);
          });
      }
      return resultItems;
    }
  },

  mounted() {
    if (this.$refs.tableHeader && this.$refs.tableColumnHeader) {
      this.tableHeaderHeight = this.$refs.tableHeader.clientHeight + this.$refs.tableColumnHeader.clientHeight;
      this.displayMaxTableHeight = this.maxTableHeight - this.tableHeaderHeight;
    }

    // TODO: v2マージ後のフルテストのタイミングで分岐フラグを削除し、他のテーブルに影響がないかを確認する
    if (this.useFixSort) {
      // 初期ソートの設定
      this.currentSort = this.currentSortkey;
    }
  }
};