import session from '../../../../share/session';
import individualWorkSupportUtil from '../../../../share/util/individualWorkSupportUtil';

/** Dispose APIの実行 */
export async function doDispose(self) {
  try {
    self.isLoading = true;
    const token = session.getToken();
    await individualWorkSupportUtil.pollingWspApi(
      self,
      self.netAppId,
      self.systemId,
      self.workSupportId,
      // POST リクエストの body を指定する
      {
        id_token: token.id_token,
        type: 'dispose'
      },
      // エラー発生のコールバックを実装する。
      // ここでは利用していないが、引数の errorType にはあらかじめ用意された定数文字列が返ってくる。
      (errorType) => { errorType; self.showUnexpectedError(); },
      // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
      // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
    );
  } finally {
    self.isLoading = false;
  }
}