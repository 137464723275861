import session from '../../../../share/session';
import individualWorkSupportUtil from '../../../../share/util/individualWorkSupportUtil';
import { REQUEST_STATUS_COMPLETED, REQUEST_STATUS_FAILED } from '../../../../share/util/api/polling';
import { PHASE } from './constants';

/** DTC削除 */
export async function dtcClear(self) {
  try {
    self.isLoading = true;
    //バックエンドAPI POST,GET
    const token = session.getToken();
    const response = await individualWorkSupportUtil.pollingWspApi(
      self,
      self.netAppId,
      self.systemId,
      self.workSupportId,
      {
        id_token: token.id_token,
        type: 'clear_dtc',
      },
      // エラー発生のコールバックを実装
      (errorType) => { errorType; self.showUnexpectedError(); },
      // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
      // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
    );

    if (response) {
      // 正常コールバック
      switch (response.data.status) {
        case REQUEST_STATUS_COMPLETED:
          // 正常終了
          // センサ補正初期化完了へ遷移
          self.phase = PHASE.INIT_COMPLETE;
          break;
        case REQUEST_STATUS_FAILED:
        default:
          // 異常終了
          //  通信異常エラーへ遷移
          self.showUnexpectedError();
          break;
      }
    }
  } finally {
    self.isLoading = false;
  }
}