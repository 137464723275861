<!-- 設定保存ダイアログ -->
<b-modal v-model="modal_requirement_40004" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      {{ headerTitle }}
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <div class="row no-gutters">
          <div class="col-12">
            <div class="text-left align-middle">
              <div class="box-margin-right work-support-message">{{ workSupportMessage }}</div>
            </div>
          </div>
        </div>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer="{ close, ok }">
    <div class="w-100 text-center ">
      <template v-if="phase !== 'start'">
        <b-button size="sm" variant="primary" @click="onOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>