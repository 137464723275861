<div>
  <ol class="flow" v-if="navigationItems">
    <li v-for="(breadCrumb, i) in navigationItems"
      v-bind:class="{ 'disabled-style': disabled, 'current-style' : breadCrumb.isCurrent }">
      <div v-if="breadCrumb.isHome" @click.prevent="onClick(breadCrumb)" style="cursor: pointer; fill: white;">
        <svg viewBox="0 0 24 24" class="icon-size-normal">
          <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/>
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </div>
      <div v-else>
        <a v-if="breadCrumb.isCurrent" href="javascript:void(0)" style="text-decoration: none;" :class="{ 'label-ext-sm': isLangRu }">
          {{displayText(breadCrumb)}}
        </a>
        <a v-else href="javascript:void(0)" @click.prevent="onClick(breadCrumb)" :class="{ 'label-ext-sm': isLangRu }">
          {{displayText(breadCrumb)}}
        </a>
      </div>
    </li>
    <li style="visibility: hidden"></li>
  </ol>
</div>
