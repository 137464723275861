<div class="col-12">
  <div class="col-12">
    <!-- メモボタン -->
    <b-button class="ml-2" variant="primary" size="sm" @click="parentOpenMemoModal">
      {{ $t('message.button_vehicle_dtc_note_modal') }}
    </b-button>
    <!-- ファイルエクスポートボタン -->
    <b-button class="ml-2" variant="primary" size="sm" @click="csvExport">
      {{ $t('message.file_export') }}
    </b-button>
    <!-- モニタテスト確認ボタン(WS.45) -->
    <b-button class="ml-2" v-if="isWs45" variant="primary" size="sm" @click="openDetailMonitorTestModal">
      {{ $t('individual.button_monitor_test_confirm') }}
    </b-button>
    <!-- DTC確認ボタン(WS.45) -->
    <b-button class="ml-2" v-if="isWs45" variant="primary" size="sm" @click="openDetailDtcModal">
      {{ $t('individual.button_dtc_confirm') }}
    </b-button>
  </div>
  <!-- データ表示テーブル -->
  <div class="col-12">
    <custom-table class="mt-2 ml-2" type="simple" useSort useStripeStyle rowSelect hasTabs currentSortkey="status"
      initSortDir="asc" :items="measuredDataItems" :columns="measuredDataColumns" @rowButtonClicked="openDetailFDModal">
      <template v-slot:rowButton>{{ $t(`individual.button_fd`) }}</template>
    </custom-table>
  </div>
  <!-- シークバー,「<<」,「<」,「>」,「>>」ボタン -->
  <div v-show="isWs45" class="col-12 px-0 max-height">
    <div class="seek-bar-container">
      <div class="seek-bar">
        <time-line id="dm-ls-tmln"
          ref = "timeLineSeekBar"
          :start-time = "startTimeVal"
          :end-time = "endTimeVal"
          no-range = true />
      </div>
      <span class="time-line-indicator">{{ currentTimeStr }}</span>
    </div>
    <div class="seek-btns">
      <b-button
        id="dm-backLong"
        squared
        variant="primary btn-sm" >
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF">
          <g>
            <rect fill="none" height="24" width="24" />
          </g>
          <g>
            <g>
              <polygon points="17.59,18 19,16.59 14.42,12 19,7.41 17.59,6 11.59,12" />
              <polygon points="11,18 12.41,16.59 7.83,12 12.41,7.41 11,6 5,12" />
            </g>
          </g>
        </svg>
      </b-button>
      <b-button
        id="dm-backShort"
        squared
        variant="primary btn-sm" >
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF">
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" />
        </svg>
      </b-button>
      <b-button
        id="dm-forwardShort"
        squared
        variant="primary btn-sm">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF">
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
        </svg>
      </b-button>
      <b-button
        id="dm-forwardLong"
        squared
        variant="primary btn-sm">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF">
          <g>
            <rect fill="none" height="24" width="24" />
          </g>
          <g>
            <g>
              <polygon points="6.41,6 5,7.41 9.58,12 5,16.59 6.41,18 12.41,12" />
              <polygon points="13,6 11.59,7.41 16.17,12 11.59,16.59 13,18 19,12" />
            </g>
          </g>
        </svg>
      </b-button>
    </div>
  </div>

  <!-- モニタテスト結果表示画面 -->
  <b-modal v-model="isMonitorTest" size="lg" header-bg-variant="secondary" header-text-variant="light" no-stacking
    no-close-on-backdrop no-close-on-esc centered scrollable>
    <!-- header -->
    <template v-slot:header>
      <div class="text-center w-100">{{ $t('message.common_title_infomation') }}</div>
    </template>
    <!-- body -->
    <template v-slot:default>
      <detail-monitor-test-modal ref="detail_monitor_test_modal" />
    </template>
    <!-- footer -->
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" @click="closeMonitorTestModal" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- DTC確認画面 -->
  <b-modal v-model="isDetailDtc" size="lg" header-bg-variant="secondary" header-text-variant="light" no-stacking
    no-close-on-backdrop no-close-on-esc centered scrollable>
    <!-- header -->
    <template v-slot:header>
      <div class="text-center w-100">{{ $t('message.common_title_infomation') }}</div>
    </template>
    <!-- body -->
    <template v-slot:default>
      <detail-dtc-modal :systemId="system"
        :dataPlayback="dtcModalDataPlayback" :projectId="projectId" :linkedIds="linkedIds"
        :operationScreen="operationScreen" ref="detail_dtc_modal" @dtcModalPhaseChange="dtcModalPhaseChange" />
    </template>
    <!-- footer -->
    <template v-slot:footer>
      <div class="text-center w-100">
        <template v-if="dtcModalPhase === 'dtcDisplay'">
          <b-button size="sm" variant="primary" @click="closeDtcModal" class="mx-2">
            {{ $t("message.common_button_ok") }}
          </b-button>
        </template>
        <template v-if="dtcModalPhase !== 'dtcDisplay'">
          <b-button size="sm" variant="primary" @click="dtcModalProcess" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
      </div>
    </template>
  </b-modal>

  <processing-view :processing="processing"></processing-view>
</div>