<div class="max-height data-monitor-container">
  <!-- 項目選択 -->
  <div v-show="showSelect">
    <data-monitor-select
      ref="data_monitor_select"
      :isUseCache="isUseCache"
      :isDataMonitor="isDataMonitor"
      :isSpecial="isSpecial"
      :dataMonitorFunctionSettings="dataMonitorFunctionSettings"
      @start-execute="startExecute"
      @display-processing="displayProcessing"
    />
  </div>
  <!-- 計測 -->
  <div v-if="!showSelect">
    <div :class="getAreaStyle()">
      <data-monitor-execute
        class="data-monitor"
        ref="data_monitor_execute"
        :inExecution="inExecution"
        :inPlaying="inPlaying"
        :conversionList="conversionList"
        :dataMonitorList="dataMonitorList"
        :selectedFunctionList="selectedFuncs"
        :selectedFunctionHash="selectedFunctionHash"
        :graphConfigInfo="graphConfigInfo"
        :completeResolve="completeResolve"
        :baseOrgTime="baseTime"
        :measurementUuid="measurementUuid"
        :apiToken="apiToken"
        :endpoint="endpoint"
        :highlightItems="highlightItems"
        :funcValues="funcValues"
        :specifiedSystemId="specifiedSystemId"
        :dataMonitorFunctionSettings="dataMonitorFunctionSettings"
        :savedMarkerInfo="markerInfo"
        :isCompleted="isCompleted"
        @data-total-count="notifyDataTotalCount"
        @toggle-playing="togglePlaying"
        @change-selected-funcs="changeSelectedFuncs"
        @show-disconnected-server-error="showCommonError"
        @back-select="backSelect"
        @stop-monitor="execStopMonitor"
        @start-monitor="startMonitor"
        @set-graph-config="setGraphConfig"
        @change-check-value="changeCheckValue"
        @change-no-signal="changeNoSignal"
      />
    </div>
  </div>
  <processing-view :processing="isDisplayProcessing" />
</div>
