<b-modal v-model="modal_requirement_045" :size="modalSize" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      {{ headerTitle }}
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- モニタテスト結果 ボタン -->
        <template v-if="phase === 'monitorTestResult'" class="w-100">
          <div class="align-right">
            <b-button size="sm" :disabled="isLoading" variant="primary" @click="getDiagMeasuredData" class="mx-2">
              {{ $t('individual.button_dtc_confirm') }}
            </b-button>
            <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="completionProcess" class="mx-2">
              {{ $t('individual.label_button_finish') }}
            </b-button>
          </div>
        </template>
        <!-- DTC表示 ボタン -->
        <template v-if="phase === 'dtcDisplay'" class="w-100">
          <div class="align-right">
            <b-button size="sm" :disabled="isLoading" variant="primary" @click="getReadinessMeasuredData" class="mx-2">
              {{ $t('individual.label_readiness_code') }}
            </b-button>
            <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="getMonitorTestData"
              class="mx-2">
              {{ $t(`individual.button_diagnosis`) }}
            </b-button>
          </div>
        </template>
        <!-- レディネスコード表示 ボタン -->
        <template v-if="phase === 'readinessCodeDisplay'" class="w-100">
          <div class="align-right">
            <b-button size="sm" :disabled="isLoading" variant="primary" @click="getDiagMeasuredData" class="mx-2">
              {{ $t('individual.label_button_return') }}
            </b-button>
            <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="getMonitorTestData"
              class="mx-2">
              {{ $t(`individual.button_diagnosis`) }}
            </b-button>
          </div>
        </template>
        <!-- 全画面共通 -->
        <template v-if="phase !== ''">
          <div class="w-100">
            <div class="row no-gutters">
              <div class="box-margin-right work-support-message" v-html="displayInfo"></div>
            </div>
          </div>
        </template>
        <!-- プロジェクトデータ保存 -->
        <template v-if="phase === 'projectData'">
          <project-data ref="projectData" :vin="vin" :vehicleName="vehicleName" :displayProjectID="displayProjectID"
            :registrationNumber="registrationNumber" :functionName="functionName" :dataName="dataName" :memo="memo"
            :hideRegistrationNumber="hideRegistrationNumber">
          </project-data>
        </template>
        <!-- FFD表示 -->
        <template v-if="phase === 'detailedFfd'">
          <ffd-modal :ffdTableProp="ffdSystemTable" :systemProp="selectedSystem" :ffdSystem="ffdSystem"
            :ffdDescription="ffdDescription">
          </ffd-modal>
        </template>
        <!-- テーブル -->
        <template v-if="
          phase === 'dataMonitor' ||
          phase === 'dataMonitorStarted' ||
          phase === 'monitorTestResult' ||
          phase === 'dtcDisplay'  ||
          phase === 'readinessCodeDisplay'" style="max-height: 265px; overflow-y: auto;">
          <cstm-table useStripeStyle rowSelect useSort fixedHeader hasTabs initSortDir="asc" class="support-table mt-2"
            type="simple" :items="displayTableInfo" :columns="displayTableColumns" :currentSortkey="currentSortkey"
            @rowButtonClicked="rowButtonClickEvent" ref="customTable">
            <template v-slot:rowButton>{{ $t('message.header_vehicle_dtc_table_ffd') }}</template>
          </cstm-table>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <template v-if="showCancelButton">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="onCancelClicked" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
      </template>
      <template v-if="showOkButton">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="onOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="showNoButton">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="interruptionProcess" class="mx-2">
          {{ $t('individual.label_confirm_no') }}
        </b-button>
      </template>
      <template v-if="showYesButton">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="obdMemoryClear" class="mx-2">
          {{ $t('individual.label_confirm_yes') }}
        </b-button>
      </template>
      <template v-if="showStartButton">
        <b-button size="sm" :disabled="isLoading || isStartToggle" variant="primary" @click="startDataMonitor"
          class="mx-2">
          {{ $t(`individual.label_button_start`) }}
        </b-button>
      </template>
      <template v-if="showFinishButton">
        <b-button size="sm" :disabled="isEndToggle" variant="outline-primary" @click="stopDataMonitor" class="mx-2">
          {{ $t(`individual.label_button_finish`) }}
        </b-button>
      </template>
      <template v-if="showSaveButton">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="callSaveEvent" class="mx-2">
          {{ $t('message.common_button_save') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>