import { fixNumberWithDecimal } from "../../common/nodejs/mathFunctions";
import util from "../../share/util/utils";

/**
 * OBD FFD 一覧情報の値を設定
 * @param {Array} ffds ffd配列 
 * @param {string} system システムID
 * @param {object} i18nMap システムIDとi18n用のキー名を紐づける一覧
 * @returns {object} 変換結果
 */
export const getObdSaveOrderTable = (ffds, root) => {
  const param = { items: [], headers: [] };

  if (!ffds || ffds.length < 1) {
    return param;
  }

  // FFD一覧column情報
  const ffdColumList = [];
  // FFD一覧テーブル情報
  const ffdSystemTable = [];
  ffds.forEach(e => {
    let sortNum = 0;
    // 項目・単位以外の表示を設定
    for (const ffdData of e.ffd_data) {
      const ffdSystemObj = {};
      let ffdValue = null;
      let ffdResourceID = "";
      const originalValue = ffdData.value;
      // 取得したFFDが NULLの場合は「-」を表示する
      if (originalValue === null) {
        ffdResourceID = 'message.common_hyphen';
        ffdValue = root.$t(ffdResourceID);
      } else {
        // data_type = HEX なら翻訳可否をチェックし、不可ならデフォルト表示
        if (e.data_type === 'HEX') {
          const { hexResourceID, hexValue } = convertHexTypeData(e, originalValue, root);
          ffdResourceID = hexResourceID;
          ffdValue = hexValue;
        }
        // data_type = Value なら digits_after_decimal から少数点以下の桁数を調整
        if (e.data_type === 'Value') {
          // time_formatがtrueの場合変換し表示する
          if (e.time_format.exist) {
            const convertSec = util.convertToSec(ffdData.value, e.time_format.type);
            ffdValue = timeToFormat(convertSec, e.time_format.format);
          }
          else {
            const decimal = e.digits_after_decimal;
            ffdValue = decimal !== null ? fixNumberWithDecimal(ffdData.value, decimal) : String(ffdData.value);
          }
        }
        // data_type = FLG なら 真偽値を設定
        if (e.data_type === 'FLG') {
          const i18nKeyName = 'digital_display';
          ffdResourceID = i18nKeyName + '.' + ffdData.digital_expression;
          ffdValue = root.$t(ffdResourceID);
        }
        // 以下の data_type はそのまま表示
        if (e.data_type === 'min2hhmm' || e.data_type === 'sec2hhmm') ffdValue = ffdData.value;
        // 未対応のdata_type(ffdValueが初期値(null)のままの場合)は、そのまま表示させる
        if (ffdValue === null) ffdValue = ffdData.value;
      }
      // 任意のKey名と値を設定
      const order = ffdData.detected_order;
      ffdSystemObj[order] = ffdValue;
      const resouceIdKey = "resouceId_" + order;
      ffdSystemObj[resouceIdKey] = ffdResourceID;
      const valueKey = "value_" + order;
      ffdSystemObj[valueKey] = originalValue;
      ffdColumList.push(order);

      // 項目の設定
      ffdSystemObj.item = ffdData.term_expression ? 'pid.' + ffdData.term_expression : 'pid.' + e.id;
      // 単位の設定
      ffdSystemObj.unit = 'obd_unit.' + e.unit;
      // データモニターのソート順をnumberで設定
      ffdSystemObj.monitorSort = sortNum++;
      ffdSystemTable.push(ffdSystemObj);
    }
  });
  param.items = ffdSystemTable;
  param.headers = ffdColumList;
  return param;
};

const timeToFormat = (arg, type) => {
  let result;
  let hour = 0;
  let min = 0;

  switch (type) {
    case "hrs, min":
    case "hrs,min":
      if (!arg || arg === 0) {
        result = "{0}hrs,{1}min".format(hour, min);
        return result;
      }
      hour = Math.floor(arg / 3600);
      min = Math.floor(arg / 60) % 60;
      result = "{0}hrs,{1}min".format(hour, min);
  }
  return result;
};

/**
 * HEXタイプのデータ変換
 */
const convertHexTypeData = (ffd, originalValue, root) => {
  let hexResourceID;
  let hexValue;
  const hexDisplay = 'free_display' + '.' + ffd.id + '_';
  const binaryValue = ffd.bit_size !== null ? parseInt(originalValue, 16).toString(2).padStart(ffd.bit_size, '0') : parseInt(originalValue, 16).toString(2);
  const originalResource = hexDisplay + originalValue;
  const binaryResource = hexDisplay + binaryValue;
  const defaultResource = hexDisplay + 'default';

  // 値からリソースIDを作成してリソースIDの存在チェックする。
  // 存在しない場合はリソースIDを空で設定する。
  if (!root.$te(originalResource) && !root.$te(binaryResource) && !root.$te(defaultResource)) {
    hexResourceID = "";
  } else {
    hexResourceID = root.$te(originalResource) ? originalResource : (root.$te(binaryResource) ? binaryResource : defaultResource);
  }

  if (hexResourceID) {
    hexValue = root.$t(hexResourceID);
  } else {
    // リソースIDが設定されていない場合は先頭に「0x」を追加した値を設定
    hexValue = `0x${originalValue}`;
  }

  // J1979-1 かつ PID0x02の場合はそのままの値を設定
  if (ffd.protocol === 'SAEJ1979' && ffd.id === '02_1_all') {
    hexValue = originalValue;
  }

  return { hexResourceID, hexValue };
};
