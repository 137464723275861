<div class="container">
  <div v-if="isTestMode" class="row text-center">
    <div class="col-12">
      <label class="sub-title-style alert-msg-color">{{ $t('message.label_title_training_mode') }}</label>
    </div>
  </div>

  <div :class="{ 'repro-base-container': containerMode }">
    <div class="row text-center">

      <!-- リプロ実行中コンテナ -->
      <div class="col-12">
        <!-- Downloading progress status -->
        <div v-if="execPhase === 'downloading'">
          <div class="repro-header">{{ $t('message.header_repro_execute') }}</div>
          <div class="col-12 text-left">
            <cstm-dialog :name="getTargetUnitDisplayName(progressObjects.system_name)"
              :progress="progressObjects.progress" :status="progressObjects.repro_exec_status"
              :innerProgressIcon="innerProgressIcon">
            </cstm-dialog>
          </div>
        </div>
        <!-- Pre Programming progress status -->
        <div v-else-if="execPhase === 'pre_programming'">
          <div class="repro-header">{{ $t('message.header_repro_execute') }}</div>
          <div class="col-12 text-left">
            <cstm-dialog :name="getTargetUnitDisplayName(progressObjects.system_name)"
              :progress="progressObjects.progress" :status="progressObjects.repro_exec_status"
              :remainingTime="progressObjects.remaining_repro_time" :innerProgressIcon="innerProgressIcon">
            </cstm-dialog>
          </div>
        </div>
        <!-- Post Programming progress status -->
        <div v-else-if="execPhase === 'post_programming'">
          <div class="repro-header">{{ $t('message.header_repro_execute') }}</div>
          <div class="col-12 text-left">
            <cstm-dialog :name="getTargetUnitDisplayName(progressObjects.system_name)"
              :progress="progressObjects.progress" :status="progressObjects.repro_exec_status"
              :innerProgressIcon="innerProgressIcon">
            </cstm-dialog>
          </div>
        </div>
        <!-- Programming progress status -->
        <div v-else-if="execPhase === 'programming'">
          <div class="repro-header">{{ $t('message.header_repro_execute') }}</div>
          <div class="col-12 text-left">
            <div v-for="system in systems">
              <cstm-dialog onProgramming :name="getTargetUnitDisplayName(system.system_name)"
                :progress="system.repro_progress" :remainingTime="getRemainingTimeText(system.remaining_repro_time)"
                :innerProgressIcon="innerProgressIcon">
              </cstm-dialog>
            </div>
          </div>
        </div>
      </div>

      <!-- IG-OFF/IG-ON 確認コンテナ -->
      <div class="col-12">
        <div v-if="containerMode === 'ig-off' || containerMode === 'ig-on'">
          <div v-if="containerMode === 'ig-on'" class="repro-header">
            {{ $t('message.common_title_confirmation') }}
          </div>
          <div v-if="containerMode === 'ig-off'" class="repro-header">
            {{ $t('message.common_title_confirmation') }}
          </div>
        </div>
        <div class="col-12">
          <div v-if="containerMode === 'ig-on'" class="mt-5 checkbox-size-lg">
            <b-form-checkbox v-model="executingIgOn">
              {{ $t('message.message_repro_confirm_ig_on') }}
            </b-form-checkbox>
          </div>
          <div v-if="containerMode === 'ig-off'" class="mt-5 checkbox-size-lg">
            <b-form-checkbox v-model="executingIgOff">
              {{ $t('message.message_repro_confirm_ig_off') }}
            </b-form-checkbox>
          </div>
        </div>
        <div v-if="containerMode === 'ig-on'" class="col-12 mt-3">
          {{ $t('message.message_repro_ig_on_attention') }}
        </div>
        <div class="col-12">
          <b-button v-if="containerMode === 'ig-on'" variant="primary" v-on:click="confirmIgOn()"
            :disabled="!executingIgOn" class="ml-2 mr-2 mb-3 mt-3">{{ $t('message.common_button_ok') }}
          </b-button>
          <b-button v-if="containerMode === 'ig-off'" variant="primary" v-on:click="confirmIgOff()"
            :disabled="!executingIgOff" class="ml-2 mr-2 mb-3 mt-5">{{ $t('message.common_button_ok') }}
          </b-button>
        </div>
      </div>

      <!-- 80 秒カウントダウンコンテナ -->
      <div class="col-12">
        <div v-if="containerMode === 'ig-count-down'">
          <div class="repro-header">{{ $t('message.header_repro_execute') }}</div>
          <div class="col-12 spiner-container-header">
            <label class="mt-3" style="font-size: 130%;" v-html="getCountDownHeader()"></label>
          </div>
          <div clss="col-12 text-left">
            <div class="repro-wait-status-frame mt-3 mb-5">
              <div class="repro-wait-progress-data">
                <label class="repro-wait-progress-timer">{{ countDownTimer }}</label>
                <div class="repro-wait-progress-image"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- CCUリプログラミング実行中 コンテナ -->
      <div class="col-12">
        <div v-if="containerMode === 'ccu-reprogramming'">
          <div class="repro-header">
            {{ $t('message.header_repro_execute') }}
          </div>
          <div class="spiner-container-header" style="font-weight: bold">
            <div class="col-12 mt-3 text-left">
              <div v-for="system in systems">
                {{ system.system_name }}
              </div>
            </div>
            <div class="col-12 mt-2 ml-1">
              <label class="text-left">
                {{ $t("message.common_label_status") }}
              </label>
              <label class="text-left">
                {{ $t("message.state_repro_execute_ccu_reprogram_programming") }}
              </label>
            </div>
            <div class="col-12">
              <label class="text-left">
                {{ $t("message.message_repro_execute_ccu_reprogram_programing") }}
              </label>
            </div>
          </div>
          <div class="col-12">
            <b-button variant="primary" v-on:click="confirmCompleteCcuReprogramming()" class="ml-2 mr-2 mb-3 mt-3">
              {{ $t('message.common_button_ok') }}
            </b-button>
          </div>
        </div>
      </div>

      <!-- CCUリプログラミング完了確認 コンテナ -->
      <div class="col-12">
        <div v-if="containerMode === 'confirm-complete-ccu-reprogramming'">
          <div class="repro-header">
            {{ $t('message.header_repro_confirm_completion') }}
          </div>
          <div class="col-12">
            <label class="text-center alert-msg-color mt-3">
              <b>{{ $t('message.label_repro_caution')}}</b>
            </label>
            <div class="text-left alert-msg-color mt-1">
              <b>{{ $t('message.message_repro_caution_during_software_update')}}</b>
            </div>
            <div class="text-left">
              {{ $t('message.message_repro_caution_check_complete_ccu_reprogramming')}}
            </div>
            <div class="mt-5 checkbox-size-lg">
              <b-form-checkbox v-model="completionCcuReprogramming">
                {{ $t('message.message_repro_confirm_complete_ccu_reprogramming') }}
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-12 mt-2 mb-2">
            <b-button variant="primary" v-on:click="completeCcuReprogramming()" :disabled="!completionCcuReprogramming"
              class="ml-2 mr-2 mb-3 mt-3">
              {{ $t('message.common_button_ok') }}
            </b-button>
          </div>
        </div>
      </div>

    </div>

    <processing-view :processing="processing"></processing-view>

    <!-- Queue 表示方式ダイアログ SU2対応 -->
    <b-modal v-model="showModalQueueWarning" id="modal-queue-warning" size="lg" header-bg-variant="warning"
      header-text-variant="light" no-stacking no-close-on-backdrop centered no-close-on-esc @ok="closeModalQueue()"
      @hidden="callModalHiddenEvent">
      <template v-slot:header>
        <div class="text-center w-100">
          {{ $t('message.common_title_warning') }}
        </div>
      </template>

      <template v-slot:default>
        <div>
          <div v-html="modalQueueMsg.lead" class="text-left w-100 mt-3"></div>
        </div>
        <div v-if="modalQueueMsg.tail" class="rxswin-warn-style mt-3">
          <p v-html="modalQueueMsg.tail" class="p-3"></p>
        </div>
      </template>

      <template v-slot:footer>
        <div class="text-center w-100">
          <b-button size="sm" variant="primary" @click="closeModalQueue()" class="ml-2 mr-2">
            {{ $t("message.button_polling_alert_terminate") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</div>