/** 利用回数カウント：リプロ */
export const REPRO = "repro";
/** 利用回数カウント：全 DTC */
export const DIAG = "diag";
/** 利用回数カウント：個別診断 DTC */
export const DTC = "individual_dtc";
/** 利用回数カウント：個別診断 制御作動履歴 */
export const VCH = "individual_vch";
/** 利用回数カウント：個別診断 カスタマイズ */
export const CST = "individual_cst";
/** 利用回数カウント：個別診断 作業サポート */
export const WSP = "individual_wsp";
/** 利用回数カウント：個別診断 キャンセルコード */
export const CNC = "individual_cnc";
/** 利用回数カウント：個別診断 データモニタ */
export const DM = "individual_dm";
/** 利用回数カウント：個別診断 アクティブテスト */
export const AT = "individual_at";
/** 利用回数カウント：特殊機能 データモニタ */
export const SP_DM = "special_dm";
/** 利用回数カウント：特殊機能 DTC */
export const SP_DTC = "special_dtc";
/** 利用回数カウント：特殊機能 キャンセルコード */
export const SP_CNC = "special_cnc";
/** 利用回数カウント：特殊機能 作業サポート */
export const SP_WSP = "special_wsp";
/** 利用回数カウント：データ再生 */
export const DATA_PLAYBACK = "data_playback";
/** 利用回数カウント：イモビ */
export const IMMOBILIZER = "immobilizer";
/** 利用回数カウント：MAC 鍵 */
export const MACKEY = "mackey";
/** 利用回数カウント：個社情報取得 */
export const REAL_WORLD_DATA = "real_world_data";
/** 利用回数カウント：OBD データモニタ */
export const OBD_DM = "obd_dm";
/** 利用回数カウント：OBD 個別診断 DTC */
export const OBD_DTC = "obd_individual_diag";
/** 利用回数カウント：OBD 個別診断 オンボードモニタテスト */
export const OBD_OMT = "obd_on_board_monitor_test";
/** 利用回数カウント：OBD 個別診断 オンボードシステムテスト */
export const OBD_OST = "obd_on_board_system_test";
/** 利用回数カウント：OBD 車両情報取得 */
export const OBD_VEHICLE = "obd_get_vehicle_info";
/** 利用回数カウント：OBD パーマネント DTC */
export const OBD_PERMANENT_DTC = "obd_permanent_diag";
/** 利用回数カウント：OBD ALL DTC */
export const OBD_ALL_DTC = "obd_all_dtc";
