<div>
  <!-- 設定保存ダイアログ -->
  <b-modal v-model="modal_hiddenFunction" size="md" :header-bg-variant="headerColor" header-text-variant="light"
    no-close-on-backdrop no-close-on-esc centered no-stacking>
    <template v-slot:header>
      <div class="text-center w-100">
        {{ headerTitle }}
      </div>
    </template>

    <template v-slot:default>
      <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
        <div class="container-fluid" style="min-height: 140px;">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">
              <div v-html="displayMessage()"></div>
            </div>
            <b-form-group v-if="showInputFormAndCancelButton">
              <b-form-input type="password" v-model="inputPass" :state="validateParam && !validateParam.valid" size="sm" />
              <b-form-invalid-feedback v-if="validateParam"> {{ validateParam.msg }} </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
      </b-overlay>
    </template>

    <template v-slot:footer>
      <div class="w-100 text-center ">
        <b-button v-if="showInputFormAndCancelButton" size="sm" variant="outline-primary" @click="closeButton" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="onOkClicked()" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>
  <processing-view :processing="processing"></processing-view>
</div>