// Frontend / Backend どちらからも利用する共通の数学関連の関数を定義する。

/**
 * 小数点付き数値を四捨五入する。\
 * Number.prototype.toFixed() を使うと正しく変換できないケースがある。\
 * 正確な四捨五入を行いたい場合は、こちらを利用すること。\
 * \
 * ■具体例\
 * 65.535.toFixed(2)); // "65.53"\
 * ※浮動小数点数として解釈した場合、「65.534999...」となるため
 * @param {number} input 入力する数値
 * @param {number} digits 小数点以下の桁数
 * @returns {string} 変換後の数値
 */
module.exports.fixNumberWithDecimal = (input, digits = 0) => {
  const convertFunc = (input, digits) => (Math.round(input * (10 ** digits)))/(10 ** digits);
  if (input === 0) {
    return input.toFixed(digits);
  } else if (input > 0) {
    return convertFunc(input, digits).toFixed(digits);
  } else {
    // input が負の場合は一度正の数に変換して四捨五入する
    return (-convertFunc(-input, digits)).toFixed(digits);
  }
};
