import session from '../../../../share/session';
import individualWorkSupportUtil from '../../../../share/util/individualWorkSupportUtil';
import error from '../../../../common/nodejs/errors';
import { REQUEST_STATUS_COMPLETED, REQUEST_STATUS_FAILED } from '../../../../share/util/api/polling';
import { PHASE } from './constants';

/** 現在DTC確認 */
export async function checkDtc(self) {
  try {
    self.isLoading = true;
    //バックエンドAPI POST,GET
    const token = session.getToken();
    const response = await individualWorkSupportUtil.pollingWspApi(
      self,
      self.netAppId,
      self.systemId,
      self.workSupportId,
      {
        id_token: token.id_token,
        type: 'check_dtc',
      },
      // エラー発生のコールバックを実装
      (errorType) => { errorType; self.showUnexpectedError(); },
      // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
      // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
    );

    if (response) {
      // 正常コールバック
      switch (response.data.status) {
        case REQUEST_STATUS_COMPLETED:
          // 正常終了
          // MS2系初期表示へ遷移
          self.phase = PHASE.START;
          break;
        case REQUEST_STATUS_FAILED:
          // 異常終了
          switch (response.data.async_code) {
            case error.WSP_40002_DTC_ERROR:
              // DTC判定エラーへ遷移
              self.phase = PHASE.ERROR_DTC_OTHER;
              break;
            case error.WSP_40002_COMMUNICATION_ERROR:
            default:
              //  通信異常エラーへ遷移
              self.showUnexpectedError();
              break;
          }
          break;
        default:
          // 異常終了：通信異常エラー
          self.showUnexpectedError();
          return;
      }
    }
  } finally {
    self.isLoading = false;
  }
}