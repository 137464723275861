<!-- 設定保存ダイアログ -->
<b-modal
  v-model="modal_requirement_082"
  size="md"
  :header-bg-variant="headerColor"
  header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking
  >
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start' || phase === 'check'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'end'">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'execute' || phase === 'register' || phase === 'finish'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'error' || phase === 'timeout' || phase === 'again'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 待機画面 -->
        <template v-if="phase === 'start'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_82_TXT_1`) }}</div>
          </div>
        </template>
        <!-- キー状態判定画面 -->
        <template v-if="phase === 'check'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_82_TXT_2`) }}</div>
          </div>
        </template>
        <!-- 登録モード遷移画面 -->
        <template v-if="phase === 'execute'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_82_TXT_3_0001`) }}</div>
          </div>
        </template>
        <!-- 登録モード中画面 -->
        <template v-if="phase === 'register'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ displayInfo }}</div>
          </div>
        </template>
        <!-- 停止画面 -->
        <template v-if="phase === 'finish'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_82_TXT_5`) }}</div>
          </div>
        </template>
        <!-- 異常終了(タイムアウト)画面 -->
        <template v-if="phase === 'timeout'">
          <div class="w-100">
            <div class="w-100">
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_82_TXT_6`) }}</div>
            </div>
          </div>
        </template>
        <!-- 異常終了(やり直し)画面 -->
        <template v-if="phase === 'again'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ uniqueErrorMsg }}</div>
          </div>
        </template>
        <!-- 共通エラー画面) -->
        <template v-if="phase === 'error'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
        <!-- 終了画面 -->
        <template v-if="phase === 'end'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_82_TXT_7`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 待機画面 -->
      <template v-if="phase === 'start'">
        <b-button :disabled="isLoading" size="sm" variant="outline-primary" @click="onStartCancelClicked" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onStartOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 登録モード中画面 -->
      <template v-if="phase === 'register'">
        <b-button size="sm" variant="primary" @click="onRegisgerOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 異常終了(やり直し)画面 -->
      <template v-if="phase === 'again'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onRetryOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 共通エラー画面 -->
      <template v-if="phase === 'error'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onErrorOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 終了画面 -->
      <template v-if="phase === 'end'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onSuccessOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>