import {mapState} from 'vuex';

export default {
  props: {
    disabled: false,
    navigationItems: {
      type: Array,
      required: false,
      default: null,
      isLangRu: false,
    }
  },

  watch: {
    language: {
      immediate: true,
      handler(val) {
        this.isLangRu = val === "ru"? true : false;
      }
    },
  },

  computed: {
    ...mapState({
      language: state => state.systems.language,
    }),
  },

  methods: {
    isInternalLink(path) {
      return !/^https?:\/\//.test(path);
    },
    /**
     * パンくずの表示文字列を取得する
     * @param {object} breadCrumb 対象のオブジェクト
     * @returns {string} 表示文字列
     */
    displayText(breadCrumb) {
      // displayText > resourcesId の優先度で表示する
      if (this.$te(breadCrumb?.resourcesId))
        return this.$t(breadCrumb?.resourcesId);
      return breadCrumb?.displayText || "";
    },
    onClick(breadCrumb) {
      // navigationが無効かどうかを確認 
      if (!this.disabled) {
        if (this.isInternalLink(breadCrumb.path)) {
          const routerData = {
            name: breadCrumb.name
          };
          // params データが存在するかチェック
          if (breadCrumb.param) {
            routerData['params'] = breadCrumb.param;
          }
          // 個別診断・特殊機能の初期化通信有無のための同じシステム内遷移判定
          if (breadCrumb.name === "individualSelect" || breadCrumb.name === "specialFeatureSelect") {
            routerData['query'] = { sameSystemFlg: true };
          }
          this.$router.push(routerData);
        } else {
          location.href = breadCrumb.path;
        }
      }
    }
  }
};