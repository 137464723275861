<b-modal v-model="modal_special_requirement_ES5" :size="modalSize" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'listView' || phase === 'nodata'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'detailedFd'">
        {{ $t('message.common_title_infomation') }}
      </template>
      <template v-if="phase === 'projectData'">
        {{ $t(`individual.label_input`) }}
      </template>
      <template v-if="phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 初期 -->
        <template v-if="phase === 'start'"></template>
        <!-- 一覧表示 -->
        <template v-if="phase === 'listView'">
          <custom-table useStripeStyle hasTabs :items="displayInfo" :columns="tableColumns" useSort
            currentSortkey="status" initSortDir="asc" class="mt-2 ml-2" type="simple" usePopup rowSelect
            @rowButtonClicked="rowButtonClickEvent">
            <template v-slot:rowButton>{{ $t(`individual.button_fd`) }}</template>
          </custom-table>
        </template>
        <!-- 履歴データ無し表示 -->
        <template v-else-if="phase === 'nodata'">
          {{ $t(`individual.label_history`) }}
        </template>
        <!-- 詳細FD表示 -->
        <template v-if="phase === 'detailedFd'">
          <div class="row ml-1">
            <div class="ml-2">
              <label>{{ statusLabel }}</label>
            </div>
            <div class="ml-2">
              <label style="font-weight: bold;">{{ statusValue }}</label>
            </div>
          </div>
          <b-button size="sm" variant="primary" :disabled="isLoading" @click="csvExport" class="mx-2">
            {{ $t('message.file_export') }}
          </b-button>
          <custom-table id="detailedFd" useStripeStyle hasTabs :items="displayFdInfo" :columns="tableFdColumns" useSort
            currentSortkey="did" initSortDir="asc" class="mt-2 ml-2" type="simple" :height="1000" usePopup>
          </custom-table>
        </template>
        <!-- プロジェクトデータ保存 -->
        <template v-if="phase === 'projectData'">
          <project-data ref="projectData" :vin="vin" :vehicleName="vehicleName" :displayProjectID="displayProjectID"
          :registrationNumber="registrationNumber" :functionName="functionName" :dataName="dataName"
          :memo="memo" :hideRegistrationNumber="hideRegistrationNumber"></project-data>
        </template>
        <!-- 異常終了(エラー) -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <template v-if="phase === 'listView'">
        <b-button size="sm" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="saveButtonClickEvent" class="mx-2">
          {{ $t('message.common_button_save') }}
        </b-button>
      </template>
      <template v-if="phase === 'nodata'">
        <b-button size="sm" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="Secondary" @click="saveButtonClickEvent" class="btn btn-secondary" disabled>
          {{ $t('message.common_button_save') }}
        </b-button>
      </template>
      <template v-if="phase === 'detailedFd'">
        <b-button size="sm" variant="outline-primary" :disabled="isLoading" @click="backEvent" class="mx-2">
          {{ $t('message.common_button_back') }}
        </b-button>
      </template>
      <template v-if="phase === 'projectData'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="callListView" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="callSaveEvent" class="mx-2">
          {{ $t('message.common_button_save') }}
        </b-button>
      </template>
      <template v-if="phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exit" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>