module.exports = {
    SPWS_UNLOCK_INFOS: [
        {
            special_key: 1,
            info: [{
                immutable_id: 601,
                special_requirement_id: "ES6",
                system_name: "ES"
            }]
        },
        {
            special_key: 2,
            info: [{
                immutable_id: 101,
                special_requirement_id: "MCAM1",
                system_name: "MC"
            }]
        }
    ]
};