/** 画面フェーズ */
export const PHASE = {
  WAITING: 'waiting',
  START: 'start',
  INIT_CONFIRM: 'init_confirm',
  INIT_WAIT: 'init_wait',
  INIT_COMPLETE: 'init_complete',
  TORQUE_START: 'torque_start',
  TORQUE_EXECUTE: 'torque_execute',
  TORQUE_WAIT: 'torque_wait',
  COMPLETE: 'complete',
  STOP: 'stop',
  ERROR_DTC_RECORDED_IN: 'error_dtc_recorded_in',
  ERROR_DTC_RECORDED_OUT: 'error_dtc_recorded_out',
  ERROR_COMMUNICATION: 'error_communication',
  ERROR_DTC_OTHER: 'error_dtc_other',
  END: 'end',
};

/** 秒カウントダウン */
export const TIMER = {
  MAX_TIMER: 45000, // カウント45秒
  INTERVAL: 1000, // 秒毎のカウント
};

/** 通常モード移行種別 */
export const NORMAL_MODE_MOVE_TYPE = {
  END: 'end',                             // センサ補正完了
  STOP: 'stop',                           // センサ補正中止
  ERROR: 'error',                         // 異常時
  DTC_WITHIN_RANGE: 'dtc_within_range',   // DTC範囲内エラー
  DTC_OUT_RANGE: 'dtc_out_range',         // DTC範囲外エラー
};

/** トルクセンサ0点補正リトライ情報 ※トルクセンサ0点補正 のみポーリング間隔を1000ms(リトライ回数:3600回)とする */
export const SENSOR_CORRECTION_RETRY_INFO = {
  RETRY_INTERVAL: 1000, // ポーリング間隔（ms）
  RETRY_COUNT: 3600, // ポーリングリトライ回数
};
