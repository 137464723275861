import dmUtil from "../../../dataMonitor/dataMonitorUtil";

// 項目表示名に含まれるPIDを取り出す正規表現
const ITEM_NAME_PID_REGEX = /^(.+)_over_/;

/**
 * 物理変換された値から表示する値を取得する
 * @param {object} instance 画面コンポーネントのインスタンス（通常は this）を指定する
 * @param {string} argDid 選択項目のDID情報
 * @param {string} value 変換された計測値
 * @param {array} conversionInfo 変換情報リスト
 * @returns 翻訳された計測値
 */
export function getTranslateValue(instance, argDid, value, conversionInfo) {
  // 項目名には項目表示名の上書き条件を含むことがあるため、PIDのみを抜き出す
  const matched = argDid.match(ITEM_NAME_PID_REGEX);
  const did = matched ? matched[1] : argDid;
  const cache = new dmUtil.Cache();
  const cachedValue = cache.get(did, value);
  // 0や空文字の場合はキャッシュを利用するため、nullやundefinedの時のみ翻訳情報を取得する
  if (cachedValue !== undefined && cachedValue !== null) {
    return cachedValue;
  }

  let didValDisp = "";
  const pid = convertKeyForDidToPid(did);
  if (conversionInfo.data_type === "FLG") {
    const convertedData = value;
    if (instance.$te(`digital_display.${pid}_${convertedData}`)) {
      didValDisp = instance.$t(`digital_display.${pid}_${convertedData}`);
    } else {
      didValDisp = value;
    }
  } else if (conversionInfo.data_type === "HEX") {
    if (instance.$te(`free_display.${pid}_${value}`)) {
      didValDisp = instance.$t(`free_display.${pid}_${value}`);
    } else if (instance.$te(`free_display.${pid}_default`)) {
      didValDisp = instance.$t(`free_display.${pid}_default`);
    } else {
      // HEXで翻訳結果がない場合、先頭に'0x'をつけて返す
      didValDisp = `${dmUtil.HEX_PREFIX}${value}`;
    }
  }

  // 翻訳結果を返却
  cache.set(did, value, didValDisp);
  return didValDisp;
}

/**
 * DID(翻訳キーを含む)をDID形式からPID形式に変換する
 * @param {string} id DID/PID
 * @returns PID
 */
export function convertKeyForDidToPid(id) {
  if (!id) {
    return id;
  }
  return id.replace(/^F4([A-F0-9]{2})/, '$1').replace(/_F4([A-F0-9]{2})/, '_$1');
}

export default {
  convertKeyForDidToPid
};