import { toRefs } from 'vue';

export default {
  name: "holdDownButton",
  props: {
    // マウスの長押し処理の開始・繰り返し時間。ミリ秒で指定。
    mouseDownSpeed: { default: 150, type: Number, },
    // マウスクリック時の処理。クリックと長押しで処理を分けるため、@clickは使用しないこと。
    onClick: { type: Function, required: true },
    // マウス長押し時の処理
    onHoldDown: { type: Function, required: true },
  },

  setup(props) {
    // 親コンポーネントからのデータや関数
    const { mouseDownSpeed, onClick, onHoldDown } = toRefs(props);

    // 描画に使用せずコンポーネント内で保持するのみの値(非リアクティブな変数)
    let timer = null;
    let mouseDownTime = 0;

    /**
     * 長押し処理を解除する
     */
    function clearHoldEvent() {
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    }

    /**
     * マウスボタンが押下されたイベント。
     * @param {Event} event イベントオブジェクト
     */
    function onMousedown(event) {
      mouseDownTime = new Date().getTime();
      if (timer === null) {
        timer = setInterval(() => {
          // 指定時間以上マウスが押下され続けたら、長押し処理を開始
          onHoldDown.value(event);
        }, mouseDownSpeed.value);
      }
    }

    /**
     * マウスボタンが離されたイベント
     * @param {Event} event イベントオブジェクト
     */
    function onMouseup(event) {
      const mouseUpTime = new Date().getTime();
      clearHoldEvent();
      if (mouseUpTime - mouseDownTime < mouseDownSpeed.value) {
        // マウス押下が指定時間より短い場合は、クリック処理を開始
        onClick.value(event);
      }
    }

    /**
     * マウスカーソルがボタンから離れたイベント
     */
    function onMouseLeave() {
      clearHoldEvent();
    }

    return {
      onMousedown,
      onMouseup,
      onMouseLeave
    };
  }
};