/**
 * 診断システムのユーティリティ関数群をまとめたクラス
 */
class DiagSystemUtils {

  /**
   * システム名称がエンジンに該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isEngine(systemName) {
    return ['EGI_OLD', 'EGI', 'PU_EGI', 'EGI_25ePF'].includes(systemName);
  }

  /**
   * システム名称がトランスミッションに該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isTransmission(systemName) {
    return ['TM', 'TM_OLD', 'TM_25ePF'].includes(systemName);
  }

  /**
   * システム名称がアイサイトに該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isEyesight(systemName) {
    return ['ES', 'ES_25ePF'].includes(systemName);
  }

  /**
   * システム名称がブレーキコントロールに該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isBrakeControl(systemName) {
    return ['BC', 'BC_25ePF'].includes(systemName);
  }

  /**
   * システム名称がボディコントロールに該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isBodyControl(systemName) {
    return ['BIU', 'BIU_25ePF'].includes(systemName);
  }

  /**
   * システム名称がエアバッグに該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isAirbag(systemName) {
    return ['ABG', 'ABG_25ePF'].includes(systemName);
  }

  /**
   * システム名称がセントラルECUに該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isCentralEcu(systemName) {
    return ['C-ECU'].includes(systemName);
  }

  /**
   * システム名称が電制ダンパーに該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isDamper(systemName) {
    return ['DAM', 'DAM_25ePF'].includes(systemName);
  }

  /**
   * システム名称がキーレスエントリーに該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
    static isKeylessEntry(systemName) {
      return ['KLW'].includes(systemName);
    }

  /**
   * システム名称がパワーウィンドウ(スイッチ)に該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isPowerWindowSwitch(systemName) {
    return ['PWS', 'PWS_25ePF'].includes(systemName);
  }

  /**
   * システム名称がドライバーモニタに該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isDriverMonitor(systemName) {
    return ['DMS', 'DMS_25ePF'].includes(systemName);
  }

  /**
   * システム名称がコンビネーションメータに該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isCombinationMeter(systemName) {
    return ['MET', 'MET_25ePF'].includes(systemName);
  }

  /**
   * システム名称がパワーシートメモリに該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isPowerSeatMemory(systemName) {
    return ['PSM', 'PSM_25ePF'].includes(systemName);
  }

  /**
   * システム名称がドアミラー(運転席)に該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isDoorMirrorDriver(systemName) {
    return ['MIRD', 'MIRD_25ePF'].includes(systemName);
  }

  /**
   * システム名称がドアミラー(助手席)に該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isDoorMirrorPassenger(systemName) {
    return ['MIRP', 'MIRP_25ePF'].includes(systemName);
  }

  /**
   * システム名称がエアコンに該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isAirConditioner(systemName) {
    return ['AC', 'AC_25ePF'].includes(systemName);
  }

}

module.exports = DiagSystemUtils;
