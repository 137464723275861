import activeTestSliderPanel from "../../../vue/individualActiveTest/activeTestControlPanel/ActiveTestSliderPanel.vue";
import activeTestButtonPanel from "../../../vue/individualActiveTest/activeTestControlPanel/ActiveTestButtonPanel.vue";
import activeTestBlankPanel from "../../../vue/individualActiveTest/activeTestControlPanel/ActiveTestBlankPanel.vue";
import requirementSP7 from "../../../vue/individualActiveTest/activeTestControlPanel/RequirementSP7.vue";
import requirementSP4 from "../../../vue/individualActiveTest/activeTestControlPanel/RequirementSP4.vue";
import requirementSP30 from "../../../vue/individualActiveTest/activeTestControlPanel/RequirementSP30.vue";

/**
 * components指定
 * vueをimportし、components名を設定する
 */
export const wizardComponents = {
  activeTestSliderPanel: activeTestSliderPanel,
  activeTestButtonPanel: activeTestButtonPanel,
  activeTestBlankPanel: activeTestBlankPanel,
  requirementSP7: requirementSP7,
  requirementSP4: requirementSP4,
  requirementSP30: requirementSP30,
};

// 不変ID:001049 要求ID:REQ.ACT.SP5...
/** アクティブテスト機能要求ID/コンポーネント対応表 */
export const reqIdCompNameTable = {
  activeTestSliderPanel: [
    // スライダー
    "REQ.ACT.2F.IN.EXM.STP.STM.RD.NLP",
    "REQ.ACT.2F.IN.EXM.STP.NSTM.RD.NLP",
    "REQ.ACT.2F.IN.EXM.STP.NSTM.RD.LP",
    "REQ.ACT.2F.IN.EXM.STP.NSTM.NRD.LP",
    "REQ.ACT.SP5", // 実行後の遷移に固定時間のウェイトを挟む。規定回数の処理中ステータス応答で強制的に終了する。
    "REQ.ACT.SP8", // エラー遷移時に停止なし。
    "REQ.ACT.SP9", // 継続条件がなく、一回の応答で結果を判定する。
    "REQ.ACT.SP11", // NRC($22)を見る。
    "REQ.ACT.SP12", // 「ユーザ入力値」ごとに別DIDが割当たる。
    "REQ.ACT.SP13", // NRC($22)を見る。
    "REQ.ACT.SP14", // NRC($22)を見る。
    "REQ.ACT.SP17", // エラー遷移時に停止なし。可変入力あり。
  ],
  activeTestButtonPanel: [
    // ボタン
    "REQ.ACT.SP10", // NRC($22)を見る。
    "REQ.ACT.SP15", // NRC($22)を見る。可変入力なし。
    "REQ.ACT.SP20", // 1機能で複数RIDが割り当たる。
    "REQ.ACT.SP22", // NRC($22)を見る。1機能で複数RIDが割り当たる。
    "REQ.ACT.SP24", // NRC($11, 12, 31)を見る。 1機能で複数RIDが割り当たる。
    "REQ.ACT.SP27", // 1機能で複数RIDが割り当たる。
  ],
  activeTestBlankPanel: [
    // 入力なし
    "REQ.ACT.2F.NIN.EXM.STP.STM.RD.NLP",
    "REQ.ACT.2F.NIN.EXM.STP.NSTM.NRD.LP",
    "REQ.ACT.31.NIN.EXM.STP.STM.NRD.NLP",
    "REQ.ACT.31.NIN.EXM.STP.NSTM.NRD.NLP",
    "REQ.ACT.31.NIN.EXM.NSTP.NSTM.NRD.NLP",
    "REQ.ACT.31.NIN.NEXM.NSTP.NSTM.NRD.NLP",
    "REQ.ACT.SP16", // エラー遷移時に停止なし。
    "REQ.ACT.SP18", // エラー遷移時に停止なし。
    "REQ.ACT.SP19", // 継続条件一致時の表示文言が固定。
    "REQ.ACT.SP21", // NRC($22)を見る。
    "REQ.ACT.SP23", // NRC($22)を見る。終了条件一致時に停止なし。
    "REQ.ACT.SP25", // NRC($11, 12, 31)を見る。
    "REQ.ACT.SP26", // 終了条件一致時に停止なし。
    "REQ.ACT.SP28", // NRC($11, 12, 31)を見る。
    "REQ.ACT.SP29", // NRC($22)を見る。実行監視なし。
  ],
  requirementSP7: [
    // SP7
    "REQ.ACT.SP7", // 複数アクティブテストを同時実行できる。終了条件が4バイトかつ複数の意味を持つ。
  ],
  requirementSP4: [
    // SP4
    "REQ.ACT.SP4", // 特定のDIDによって実行条件を判定し、条件合致する時のみ処理実行できる。
  ],
  requirementSP30: [
    // SP30
    "REQ.ACT.SP30", // 複数アクティブテストを同時実行できる。
  ],
};
