<div class="container-fluid individual-data-monitor">
  <div v-if="!showSelect && !isObd" class="one-liner-btns">
    <div class="left-side-btn">
      <b-button
        class="mt-n5 ml-n4"
        variant="primary"
        size="sm"
        @click="openMemo"
        :disabled="btnDisabled.memo"
      >
        {{ $t('message.button_vehicle_dtc_note_modal') }}
      </b-button>
    </div>
    <div class="right-side-btn">
      <b-button
        class="mt-n5"
        variant="primary"
        size="sm"
        @click="handleConfigureGraph"
        :disabled="btnDisabled.graph"
      >
        {{ $t('individual.button_data_monitor_graph_config') }}
      </b-button>
    </div>
  </div>

  <!-- OBD機能実行時用 -->
  <div v-if="!showSelect && isObd" class="one-liner-btns" style="margin-top: 50px;">
    <div class="left-side-btn">
      <b-button
        class="mt-n5 ml-n4"
        variant="primary"
        size="sm"
        @click="openMemo"
        :disabled="btnDisabled.memo"
      >
        {{ $t('message.button_vehicle_dtc_note_modal') }}
      </b-button>
    </div>
    <div class="right-side-btn">
      <b-button
        class="mt-n5"
        variant="primary"
        size="sm"
        @click="handleConfigureGraph"
        :disabled="btnDisabled.graph"
      >
        {{ $t('individual.button_data_monitor_graph_config') }}
      </b-button>
    </div>
  </div>

  <data-monitor
    class="{'dm-area': showSelect}"
    ref="data_monitor"
    :showSelect="showSelect"
    :inExecution="inExecution"
    :inPlaying="inPlaying"
    :measureId="measureId"
    :isUseCache="isUseCache"
    :isSpecial="isSpecial"
    :dataMonitorFunctionSettings="dataMonitorFunctionSettings"
    @toggle-show-select="toggleShowSelect"
    @toggle-execute="toggleExecute"
    @toggle-playing="togglePlaying"
    @change-measure-id="changeMeasureId"
    @back-select="backSelect"
    @exec-stop-monitor="stopMonitor"
    @change-check-value = "changeCheckValue"
  />

  <!-- メモ画面 -->
  <b-modal
    v-model="modalMemo"
    size="lg"
    header-bg-variant="secondary"
    header-text-variant="light"
    no-stacking
    no-close-on-backdrop
    no-close-on-esc
    centered
    scrollable
  >
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.header_vehicle_dtc_note_modal') }}
      </div>
    </template>

    <memo-page
      v-if="modalMemo"
      ref="memo"
      :projectId="projectId"
      :measureId="measureId"
      :functionNameKey="navItemLabel"
      :destinationAPI="destinationAPI"
      :modalLoading="isMemoDialogLoading"
      @closeNoteModal="closeNoteModal"
      @update-loading="isMemoDialogLoading = $event"
    />

    <template v-slot:footer>
      <button
        type="button"
        :disabled="isMemoDialogLoading"
        v-on:click="memoClear()"
        class="btn clear-button ml-3 btn-outline-primary btn-sm rounded-pill"
      >
        {{ $t("message.common_button_clear") }}
      </button>
      <div class="text-center w-100">
        <b-button
          size="sm"
          :disabled="isMemoDialogLoading"
          variant="outline-primary"
          @click="modalMemo = false"
          class="ml-2 mr-2"
        >
          {{ $t("message.common_button_cancel") }}
        </b-button>
        <b-button
          size="sm"
          :disabled="isMemoDialogLoading"
          variant="primary"
          @click="memoSave()"
          class="ml-2 mr-2"
        >
          {{ $t('message.common_button_save') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <processing-view :processing="processing"></processing-view>
</div>
