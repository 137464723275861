<!-- WS.146 変速比変化量異常判定 -->
<b-modal
  v-model="modal_requirement_146"
  size="lg"
  :header-bg-variant="headerColor"
  header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking
  >
  <template v-slot:header>
    <div class="text-center w-100">
      {{ headerTitle }}
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="opacityValue" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 共通 -->
        <template v-if="phase !== 'save'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              workSupportMessage }}</div>
          </div>
        </template>
        <!-- プロジェクトデータ保存 -->
        <template v-if="phase === 'save'">
          <div class="row">
            <div class="col-12">
              <label class="sub-line-title-style"
                v-html="$t('message.label_vehicle_dtc_note_modal_information')"></label>
            </div>
            <div class="col-12">
              <div class="row ml-1">
                <div class="col-3">
                  <label v-html="$t('message.label_vehicle_dtc_note_modal_vin')"></label>
                </div>
                <div class="col-9">
                  <label style="font-weight: bold;">{{ vin }}</label>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row ml-1">
                <div class="col-3">
                  <label v-html="$t('message.label_vehicle_dtc_note_modal_vehicle')"></label>
                </div>
                <div class="col-9">
                  <label style="font-weight: bold;">{{ vehicleName }}</label>
                </div>
              </div>
            </div>
            <div v-if="hideRegistrationNumber" class="col-12">
              <div class="row ml-1">
                <div class="col-3">
                  <label v-html="$t('message.label_vehicle_dtc_note_modal_number')"></label>
                </div>
                <div class="col-9">
                  <label style="font-weight: bold;">{{ registrationNumber }}</label>
                </div>
              </div>
            </div>
            <div class="col-12">
              <hr class="hr-line-100">
              <div>
                <label class="sub-line-title-style" v-html="$t('message.label_vehicle_dtc_note_modal_project')"></label>
              </div>
            </div>
            <div class="col-12">
              <div class="row ml-1">
                <div class="col-3">
                  <label v-html="$t('message.label_vehicle_dtc_note_modal_project_id')"></label>
                </div>
                <div class="col-9">
                  <label style="font-weight: bold;">{{ displayProjectID }}</label>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row ml-1 mt-2">
                <div class="col-3">
                  <label v-html="$t('message.label_vehicle_dtc_note_modal_comment')"></label>
                </div>
                <div class="col-9">
                  <b-form-textarea type="text" v-model="diagnoseComment" size="sm" rows="3"
                    :state="isAlert.diagnoseComment.valid">
                    {{ diagnoseComment }}
                  </b-form-textarea>
                  <b-form-invalid-feedback>{{ $t(isAlert.diagnoseComment.msg, [isAlert.diagnoseComment.length])
                    }}</b-form-invalid-feedback>
                </div>
              </div>
            </div>
            <div class="col-12">
              <hr class="hr-line-100">
              <div>
                <label class="sub-line-title-style"
                  v-html="$t('message.label_vehicle_dtc_note_modal_measured_data')"></label>
              </div>
            </div>
            <div class="col-12">
              <div class="row ml-1">
                <div class="col-3">
                  <label v-html="$t('message.label_vehicle_dtc_note_modal_function_name')"></label>
                </div>
                <div class="col-9">
                  <label style="font-weight: bold;">{{ $t(functionName) }}</label>
                </div>
              </div>
            </div>
            <div class="col-12">
              <b-form-group>
                <div class="row ml-1">
                  <div class="col-3">
                    <label v-html="$t('message.label_vehicle_dtc_note_modal_data_name')"></label>
                    <span class="input-attention-required ml-1" v-html="$t('message.alert_vinz_input_required')"></span>
                  </div>
                  <div class="col-9">
                    <b-form-input type="text" v-model="dataName" size="sm" :state="isAlert.dataName.valid">
                      {{ dataName }}
                    </b-form-input>
                    <b-form-invalid-feedback>{{ $t(isAlert.dataName.msg, [isAlert.dataName.length])
                      }}</b-form-invalid-feedback>
                  </div>
                </div>
              </b-form-group>
            </div>
            <div class="col-12">
              <div class="row ml-1 mt-2">
                <div class="col-3">
                  <label v-html="$t('message.label_vehicle_dtc_note_modal_note')"></label>
                </div>
                <div class="col-9">
                  <b-form-textarea type="text" v-model="memo" size="sm" rows="3" :state="isAlert.memo.valid">
                    {{ memo }}
                  </b-form-textarea>
                  <b-form-invalid-feedback>{{ $t(isAlert.memo.msg, [isAlert.memo.length]) }}</b-form-invalid-feedback>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer="{ close, ok }">
    <div class="w-100 text-center ">
      <template v-if="phase === 'start'">
        <!-- キャンセルボタン -->
        <b-button size="sm" variant="outline-primary" @click="onCancelClicked" class="mx-2 mt-2" :disabled="isLoading">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <!-- OKボタン -->
        <b-button size="sm" variant="primary" @click="onOkClicked" class="mx-2 mt-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'cancel_confirm'">
        <!-- キャンセルボタン -->
        <b-button size="sm" variant="outline-primary" @click="onCancelClicked" class="mx-2 mt-2" :disabled="isDisabledExecute">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <!-- OKボタン -->
        <b-button size="sm" variant="primary" @click="onOkClicked" class="mx-2 mt-2" :disabled="isDisabledExecute">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'complete' || phase === 'error' || phase === 'defaultSessionError'">
        <!-- OKボタン -->
        <b-button size="sm" variant="primary" @click="onOkClicked" class="mx-2 mt-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'save'">
        <!-- 保存ボタン -->
        <b-button size="sm" variant="primary" @click="onSaveClicked" class="mx-2 mt-2" :disabled="isLoading">
          {{ $t('message.common_button_save') }}
        </b-button>
      </template>
      <template v-if="phase === 'execute'">
        <!-- 終了ボタン -->
        <b-button size="sm" variant="primary" @click="onFinishClicked" class="mx-2 mt-2" :disabled="isDisabledExecute">
          {{ $t('individual.label_button_finish') }}
        </b-button>
      </template>

    </div>
  </template>
</b-modal>