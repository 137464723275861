<div class="col-12">
  <div class="col-12">
    <!-- メモボタン -->
    <b-button class="ml-2 mt-2" variant="primary" size="sm" @click="parentOpenMemoModal">
      {{ $t('message.button_vehicle_dtc_note_modal') }}
    </b-button>
    <b-button v-if="testModeVisible" class="ml-2 mt-2" variant="primary" size="sm" @click="debugExport">
      Export DTC Data
    </b-button>
    <b-button class="ml-2 mt-2" variant="primary" size="sm" @click="csvExport">
      {{ $t('message.file_export') }}
    </b-button>
  </div>
  <div class="col-12">
    <custom-table class="mt-2 ml-2" type="simple" useSort useStripeStyle rowSelect hasTabs currentSortkey="status"
      initSortDir="asc" :items="measuredDataItems" :columns="measuredDataColumns" @rowButtonClicked="openFFDModal">
      <template v-slot:rowButton>
        <svg viewBox="0 0 482 493" class="ffd-icon-size">
          <g transform="translate(0.000000,493.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
            <path d="M4382 4857 c-63 -127 -181 -251 -304 -320 -32 -18 -58 -34 -58 -37 0 -3 17 -13 38 -23 119 -57 251 -190 319 -323 l37 -72 39 71 c76 140 197 263
                  315 320 23 12 42 23 42 26 0 4 -30 23 -65 44 -121 70 -234 192 -299 320 l-31 62 -33 -68z"
              class="icon-color" />
            <path d="M2010 4456 c-98 -35 -774 -353 -805 -378 -19 -16 -47 -51 -63 -76 l-27 -47 -3 -528 -3 -529 24 -52 c41 -88 79 -113 477 -297 385 -179 450 -203
                  515 -193 22 3 138 50 257 105 119 54 220 99 223 99 3 0 5 -78 5 -173 0 -212 10 -248 85 -322 45 -45 89 -68 440 -230 387 -178 390 -179 460 -180 70 0 71 1
                  458 180 411 190 456 217 500 299 22 41 22 43 22 576 l0 535 -31 55 c-19 34 -47 66 -75 85 -24 17 -215 109 -424 205 -485 223 -428 217 -740 73 l-220 -101
                  -5 186 c-4 163 -8 193 -26 233 -28 59 -78 109 -140 139 -189 89 -706 325 -738 336 -47 17 -119 17 -166 0z m485 -324 c245 -112 369 -173 388 -193 l27 -29 0
                  -213 0 -213 -104 -52 c-104 -52 -130 -75 -172 -152 -17 -31 -20 -64 -24 -285 l-5 -249 -240 -112 c-132 -62 -253 -113 -270 -113 -19 -1 -173 65 -405 173
                  -318 148 -378 179 -397 206 l-23 33 0 476 c0 433 2 479 18 511 16 32 42 46 381 202 416 191 408 188 441 180 14 -3 187 -79 385 -170z m1509 -705 c280
                  -131 374 -179 390 -200 21 -28 21 -38 21 -517 0 -476 -1 -489 -20 -516 -26 -34 -752 -374 -800 -374 -48 0 -774 340 -800 375 -19 26 -20 41 -20 515 0 479
                  0 489 21 517 30 41 741 371 799 371 31 0 125 -39 409 -171z" class="icon-color" />
            <path d="M1415 3885 c-28 -27 -31 -43 -11 -70 7 -10 150 -82 317 -160 l304 -142 5 -417 c5 -399 6 -418 24 -432 27 -19 60 -18 79 4 15 17 17 61 17 429 0
                  319 3 412 13 416 146 60 636 282 649 294 32 29 18 86 -25 97 -18 4 -113 -33 -353 -139 -181 -80 -334 -145 -340 -145 -6 0 -151 65 -322 145 -172 80 -317
                  145 -322 145 -6 0 -22 -11 -35 -25z" class="icon-color" />
            <path d="M2912 3188 c-16 -16 -15 -73 1 -86 6 -6 147 -73 312 -150 l300 -139 5 -417 c5 -399 6 -418 24 -432 25 -18 57 -18 82 0 18 14 19 33 24 431 l5 416
                  320 141 c176 77 326 145 333 150 17 15 15 63 -4 82 -8 9 -27 16 -41 16 -14 0 -170 -64 -347 -142 -176 -77 -325 -141 -331 -141 -5 0 -146 64 -312 142 -167
                  77 -315 141 -331 141 -15 0 -33 -5 -40 -12z" class="icon-color" />
            <path d="M441 2707 c-42 -88 -158 -208 -242 -252 l-56 -29 55 -33 c117 -71 205 -166 257 -276 l23 -49 38 74 c47 89 155 202 237 248 l58 33 -73 46 c-90
                  58 -184 159 -228 246 -18 36 -33 65 -34 64 -1 0 -16 -32 -35 -72z" class="icon-color" />
            <path d="M2040 2113 c-30 -6 -747 -334 -807 -369 -48 -28 -95 -91 -112 -149 -9 -29 -11 -185 -9 -555 3 -489 4 -517 23 -555 41 -83 68 -100 485 -294 l395
                  -184 75 -1 75 -1 390 180 c354 164 394 185 441 231 85 83 84 77 84 644 0 567 1 561 -83 644 -47 46 -85 66 -397 208 -190 87 -365 166 -390 177 -48 21 -129
                  32 -170 24z m452 -333 c232 -106 382 -180 396 -196 l22 -25 0 -499 0 -499 -22 -25 c-32 -35 -750 -366 -795 -366 -44 0 -761 331 -795 367 l-23 25 0 499 0
                  499 25 25 c34 33 745 363 787 364 22 1 151 -53 405 -169z" class="icon-color" />
            <path d="M1404 1525 c-17 -27 -13 -55 14 -77 9 -8 151 -77 315 -152 l297 -139 0 -406 c0 -448 0 -451 60 -451 61 0 60 -9 60 443 l0 412 331 145 c186 81 335
                  152 340 162 22 41 -4 88 -49 88 -12 0 -167 -64 -345 -142 -177 -78 -329 -141 -337 -140 -8 0 -150 64 -314 141 -165 78 -312 141 -328 141 -18 0 -33 -8 -44
                  -25z" class="icon-color" />
            <path d="M4076 1372 c-21 -42 -57 -104 -81 -137 -68 -96 -209 -223 -309 -279 l-87 -49 58 -31 c182 -98 325 -245 418 -431 l43 -85 17 43 c9 23 37 75 62 116
                  93 150 207 259 359 346 l75 43 -68 37 c-169 93 -307 236 -402 415 l-47 89 -38 -77z"
              class="icon-color" />
          </g>
        </svg>
      </template>
    </custom-table>
  </div>

  <!-- FFD 一覧画面 -->
  <b-modal v-model="modalFfd" size="huge" header-bg-variant="secondary" header-text-variant="light" no-stacking
    no-close-on-backdrop no-close-on-esc centered scrollable>
    <!-- FFDタイトルの設定 -->
    <template v-slot:header>
      <div class="text-center w-100">
        {{ ffdTitle }}
      </div>
    </template>

    <ffd-modal v-if="modalFfd" :ffdTableProp="ffdSystemTable" :systemProp="selectedSystem" :ffdSystem="ffdSystem"
      :ffdDescription="ffdDescription" :isObd="isObd" ref="ffd_modal">
      <template v-slot:didTestBtn>
        <b-button v-if="testModeVisible" class="clear-button" variant="primary" size="sm" @click="didTestDataExport">
          Export DID Data
        </b-button>
      </template>
    </ffd-modal>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" @click="modalFfd=false" class="ml-2 mr-2">
          {{ $t("message.common_button_ok") }}
        </b-button>
      </div>
    </template>
  </b-modal>
  <processing-view :processing="processing"></processing-view>
</div>