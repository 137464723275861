import session from '../../../../share/session';
import individualWorkSupportUtil from '../../../../share/util/individualWorkSupportUtil';
import { REQUEST_STATUS_COMPLETED } from '../../../../share/util/api/polling';
import { update } from './update';

/** プロジェクトデータ保存 */
export async function save(self) {
  try {
    self.isLoading = true;
    //バックエンドAPI POST,GET
    const token = session.getToken();
    const response = await individualWorkSupportUtil.pollingWspApi(
      self,
      self.netAppId,
      self.systemId,
      self.workSupportId,
      {
        id_token: token.id_token,
        type: 'save',
        project_id: self.vehicleInfo.vehicle.project.id,
        file_path: self.filePath,
      },
      // エラー発生のコールバックを実装
      (errorType) => { errorType; self.showUnexpectedError(); },
      // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
      // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
    );

    if (response) {
      // 正常コールバック
      switch (response.data.status) {
        case REQUEST_STATUS_COMPLETED:
          // 正常終了
          await update(self, response.data.user_data.measured_id);
          break;
        default:
          break;
      }
    }
  } finally {
    self.isLoading = false;
    // 処理が終わったら必ず機能停止
    self.closeWizard();
  }
}