import session from '../../../../share/session';
import individualWorkSupportUtil from '../../../../share/util/individualWorkSupportUtil';
import { PHASE } from './constants';

/**
 * 周期通信停止（正常時・異常時）処理
 * @param {*} self this
 * @param {boolean} isNormal 正常時・異常時判定
 */
export async function communicationStop(self, isNormal = false) {
  try {
    self.isLoading = true;
    //バックエンドAPI POST,GET
    const token = session.getToken();
    const response = await individualWorkSupportUtil.pollingWspApi(
      self,
      self.netAppId,
      self.systemId,
      self.workSupportId,
      {
        id_token: token.id_token,
        type: 'communicationStop',
      },
      // エラー発生のコールバックを実装
      (errorType) => { errorType; self.showUnexpectedError(); },
      // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
      // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
    );

    if (response) {
      // 正常コールバック
      if (isNormal) {
        // 周期通信停止（正常時）の場合
        // 結果に関わらず、slipHoldの空判定
        if (self.slipHold !== "") {
          // 空以外の場合、変速比変化異常判定結果に遷移
          const displaySlipHold = self.slipHold === "0" ? self.$t('individual.label_judge_normal') : self.$t('individual.label_judge_abnormal'); // "0":正常、"1":異常
          self.completeMessage = self.$t(`${self.i18nWsKey}.REQ_WS_146_TXT_5`).replace("$RESULT$", displaySlipHold);
          self.phase = PHASE.COMPLETE;
        } else {
          // 上記以外の場合、エラーに遷移
          self.showUnexpectedError();
        }
      } else {
        // 周期通信停止（異常時）の場合
        // 結果に関わらず、エラーに遷移
        self.showUnexpectedError();
      }
    }
  } finally {
    self.isLoading = false;
  }
}