import configureGraph, { DEFAULT_RANGE_DISPLAY } from "../../../dataMonitor/ConfigureGraph";
export default {
  name: "obdConfigureGraph",

  extends: configureGraph,

  methods: {

    /**
     * 変換情報の形式を調整する。
     * @param {Object} propsConversions Propsにて渡されるオリジナルの変換情報
     * @returns ConfigureGraphコンポーネント用に形式を変更した変換情報
     */
    formatConversionList(propsConversions) {
      const result = {};
      Object.keys(propsConversions).forEach(did => {
        if (propsConversions[did].conversion) {
          // conversionがある場合、1階層上に情報を上げ、OBD用の単位の翻訳を取得して設定
          const unit = this.$t(`obd_unit.` + propsConversions[did].conversion.unit);
          result[did] = Object.assign(
            propsConversions[did], propsConversions[did].conversion, { unit: unit }
          );
        } else {
          // conversionがない場合、グラフの最大・最小は0固定表示で、単位は'-'固定。
          result[did] = Object.assign(
            propsConversions[did], { graph_range_display: DEFAULT_RANGE_DISPLAY }, { unit: "-" }
          );
        }
      });
      return result;
    }
  }
};