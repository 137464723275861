import session from '../../../../share/session';
import individualWorkSupportUtil from '../../../../share/util/individualWorkSupportUtil';
import { REQUEST_STATUS_COMPLETED, REQUEST_STATUS_FAILED } from '../../../../share/util/api/polling';
import { NORMAL_MODE_MOVE_TYPE, SENSOR_CORRECTION_RETRY_INFO } from './constants';
import { checkDtcAfter } from './checkDtcAfter';
import { normalMode } from './normalMode';

/** トルクセンサ0点補正 */
export async function sensorCorrection(self) {
  try {
    self.isLoading = true;
    //バックエンドAPI POST,GET
    const token = session.getToken();
    const response = await individualWorkSupportUtil.pollingWspApi(
      self,
      self.netAppId,
      self.systemId,
      self.workSupportId,
      {
        id_token: token.id_token,
        type: 'sensor_correction',
      },
      // エラー発生のコールバックを実装
      (errorType) => { errorType; self.showUnexpectedError(); },
      // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
      // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
      // ポーリングリトライ回数
      SENSOR_CORRECTION_RETRY_INFO.RETRY_COUNT,
      // ポーリング間隔（ms）
      SENSOR_CORRECTION_RETRY_INFO.RETRY_INTERVAL,
    );

    if (response) {
      // 正常コールバック
      // Response取得後、タイマーが止まっていない場合停止する
      if (self.timeoutTimer) {
        clearTimeout(self.timeoutTimer);
        self.timeoutTimer = null;
        clearInterval(self.elapsedTime);
        self.timer = 0;
      }
      switch (response.data.status) {
        case REQUEST_STATUS_COMPLETED:
          // 正常終了
          // 現在DTC確認（センサ補正後）APIを実行
          await checkDtcAfter(self);
          break;
        case REQUEST_STATUS_FAILED:
          // 異常終了
          // 通常モード移行（異常時）APIを実行
          await normalMode(self, NORMAL_MODE_MOVE_TYPE.ERROR);
          break;
        default:
          // 通信異常エラー
          self.showUnexpectedError();
          break;
      }
    }
  } finally {
    self.isLoading = false;
  }
}