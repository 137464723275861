<b-modal v-model="modal_requirement_132" :size="modalSize" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template
        v-if="phase === 'mode' || phase === 'wait' || phase === 'executeCheck' || phase === 'executeShow' || phase === 'stopCheck' || phase === 'cancel' || phase === 'executeInput' || phase === 'executeSelect'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <template
        v-if="phase === 'failed' || phase === 'registered' || phase === 'commonError' || phase === 'terminate' || phase === 'error'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 書込み方法選択 -->
        <template v-if="phase === 'mode'">
          <div class="row no-gutters">
            <div class="col-12">
              <b-form-select class="form-control" v-model="selectedMode" :options="modeList" @change="handleSelected"
                :select-size="6"></b-form-select>
            </div>
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`individual.label_select_writing_method_messag_ws132`) }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- 確認表示 -->
        <template v-if="phase === 'wait'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message"
                  v-html="$t('individual.label_ask_writing_with_note_message_ws132')"></div>
              </div>
            </div>
          </div>
        </template>
        <!-- データ選択 -->
        <template v-if="phase === 'executeSelect'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="col-12 text-left">
                <div class="row">
                  <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                    $t('individual.label_title_filter') }}
                  </div>
                  <div class="col-12 d-flex">
                    <div class="d-flex w-100" style="align-items: self-end;">
                      <!--登録日（年）-->
                      <div class="w-24 mr-2">
                        <b-form-group>
                          <label class="filter_label">{{ $t('message.label_measured_data_search_registered_date')
                            }}</label>
                          <b-form-select v-model="selectedYear" :options="searchYearOptions" @change="searchFilter()"
                            class="custom-select" />
                        </b-form-group>
                      </div>
                      <!--登録日（月）-->
                      <div class="w-24 mr-2">
                        <b-form-group>
                          <label class="filter_label">{{ $t('individual.label_custom_read_regdate_month') }}</label>
                          <b-form-select v-model="selectedMonth" :options="searchMonthOptions" @change="searchFilter()"
                            class="custom-select" />
                        </b-form-group>
                      </div>
                      <!-- 登録者 -->
                      <div class="w-18 mr-2">
                        <b-form-group>
                          <label class="filter_label">{{ $t('individual.header_table_registrants') }}</label>
                          <b-form-input v-model="filterRegistrant" type="text" id="filter-text" @input="searchFilter" />
                        </b-form-group>
                      </div>
                      <!-- DataID -->
                      <div class="w-18 mr-2">
                        <b-form-group>
                          <label class="filter_label">{{ $t('individual.header_table_data_id') }}</label>
                          <b-form-input v-model="filterDataId" type="text" id="filter-text" @input="searchFilter" />
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                </div>
                <cstm-table useStripeStyle useSort currentSortkey="registered_at" initSortDir="desc" rowSelect hasTabs
                  :items="filteredDataList" :columns="systemTableColumns" class="support-table mt-2" type="simple"
                  @rowClicked="clickRow" @rowButtonClicked="deleteRow" usePopup>
                  <template v-slot:rowButton>
                    {{ $t('individual.label_readiness_code_delete') }}
                  </template>
                </cstm-table>
              </div>
            </div>
          </div>
        </template>
        <!-- TVV書込み確認表示 -->
        <template v-if="phase === 'executeCheck'">
          <div class="row no-gutters">
            <div class="col-12">
              <cstm-table rowSelect hasTabs :items="tvvTableItems" :columns="savedTvvTableColumns"
                class="support-table mt-2" type="simple" usePopup>
              </cstm-table>
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t('individual.label_ask_writing_message_ws132') }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- TVV入力値表示 -->
        <template v-if="phase === 'executeShow'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message"
                  v-html="$t('individual.label_confirm_input_message_ws132')"></div>
              </div>
            </div>
            <div class="col-12 mt-2">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message">
                  {{ $t('individual.label_input_type') }} : {{ type }}
                </div>
                <div class="box-margin-right work-support-message">
                  {{ $t('individual.label_input_variant') }} : {{ variant }}
                </div>
                <div class="box-margin-right work-support-message">
                  {{ $t('individual.label_input_version') }} : {{ version }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- TVV入力 -->
        <template v-if="phase === 'executeInput'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="w-100 mb-4">
                <table class="box-margin-right box-margin-top work-support-message" style="width:400px;">
                  <tbody>
                    <tr>
                      <td style="vertical-align: top">{{ $t('individual.label_input_type') }}</td>
                      <td>
                        <b-form-group>
                          <b-form-input v-model="inputType" :state="validateType && !validateType.invalid" type="text"
                            size="sm" autocomplete="off">
                          </b-form-input>
                          <b-form-invalid-feedback v-if="validateType">{{ validateType.msg
                            }}</b-form-invalid-feedback>
                        </b-form-group>
                      </td>
                    </tr>
                    <tr>
                      <td style="vertical-align: top">{{ $t('individual.label_input_variant') }}</td>
                      <td>
                        <b-form-group>
                          <b-form-input v-model="inputVariant" :state="validateVariant && !validateVariant.invalid"
                            type="text" size="sm" autocomplete="off">
                          </b-form-input>
                          <b-form-invalid-feedback v-if="validateVariant">{{ validateVariant.msg
                            }}</b-form-invalid-feedback>
                        </b-form-group>
                      </td>
                    </tr>
                    <tr>
                      <td style="vertical-align: top">{{ $t('individual.label_input_version') }}</td>
                      <td>
                        <b-form-group>
                          <b-form-input v-model="inputVersion" :state="validateVersion && !validateVersion.invalid"
                            type="text" size="sm" autocomplete="off">
                          </b-form-input>
                          <b-form-invalid-feedback v-if="validateVersion">{{ validateVersion.msg
                            }}</b-form-invalid-feedback>
                        </b-form-group>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </template>
        <!-- TVV書込み値確認表示 -->
        <template v-if="phase === 'stopCheck'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message"
                  v-html="$t('individual.label_confirm_tvv_message_ws132')"></div>
              </div>
              <cstm-table rowSelect hasTabs :items="tvvTableItems" :columns="writeTvvTableColumns"
                class="support-table mt-2" type="simple" usePopup>
              </cstm-table>
            </div>
          </div>
        </template>
        <!-- 登録完了表示 -->
        <template v-if="phase === 'success'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t('individual.label_success_tvv_writing_message_ws132') }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- 中止表示 -->
        <template v-if="phase === 'cancel'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t('individual.label_cancel_writing_message_ws132') }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- TVV登録済み表示 -->
        <template v-if="phase === 'registered'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message"
                  v-html="$t('individual.label_registered_tvv_message_ws132')"></div>
              </div>
            </div>
          </div>
        </template>
        <!-- TVVロックフラグ書込みエラー表示 -->
        <template v-if="phase === 'error'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t('individual.label_writing_error_message_ws132') }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- データ選択エラー表示 -->
        <template v-if="phase === 'failed'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t('individual.label_select_failed_message_ws132') }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- 共通エラー -->
        <template v-if="phase === 'commonError' || phase === 'terminate'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`message.label_function_menu_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 書込み方法選択 -->
      <template v-if="phase === 'mode'">
        <b-button size="sm" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button :disabled="isNotSelected" size="sm" variant="primary" @click="clickConfirm" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 確認表示 -->
      <template v-if="phase === 'wait'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="readLockFlag" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- データ選択 -->
      <template v-if="phase === 'executeSelect'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="clickCancel" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="checkSelectedData" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- TVV書込み確認表示 -->
      <template v-if="phase === 'executeCheck'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="executeWrite" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- TVV入力値表示 -->
      <template v-if="phase === 'executeShow'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="clickInput" class="mx-2">
          {{ $t('individual.label_input') }}
        </b-button>
        <b-button :disabled="isDisabled || isLoading" size="sm" variant="primary" @click="executeManualWrite"
          class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- TVV入力 -->
      <template v-if="phase === 'executeInput'">
        <b-button size="sm" variant="outline-primary" @click="deleteInput" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="confirmInput" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- TVV書込み値確認表示 -->
      <template v-if="phase === 'stopCheck'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="exit(0)" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="writeLockFlag" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 登録完了表示 -->
      <template v-if="phase === 'success'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exit(0)">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 中止表示 or 共通エラー画面(終了処理無し) -->
      <template v-if="phase === 'cancel' || phase === 'failed' || phase === 'registered' || phase === 'commonError'">
        <b-button size="sm" variant="primary" @click="closeWizard">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- TVVロックフラグ書込みエラー表示 -->
      <template v-if="phase === 'error'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exit(0)">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 共通エラー画面(終了処理有り) -->
      <template v-if="phase === 'terminate'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exit(1)">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>