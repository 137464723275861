import config from '../config';
import utils from './utils';

// コード独自ソート
export const sortFuncCode = (param) => {
  if (!param.data) {
    return null;
  }
  // 結果リスト
  let resultItems = [];
  let pCodeList = [];
  let cCodeList = [];
  let bCodeList = [];
  let uCodeList = [];
  const codeHyphenList = [];
  param.data.forEach(element => {
    if (element.dtc.indexOf('P') === 0) {
      pCodeList.push(element);
    }
    if (element.dtc.indexOf('C') === 0) {
      cCodeList.push(element);
    }
    if (element.dtc.indexOf('B') === 0) {
      bCodeList.push(element);
    }
    if (element.dtc.indexOf('U') === 0) {
      uCodeList.push(element);
    }
    if (element.description.indexOf('message.common_hyphen') > -1) {
      codeHyphenList.push(element);
    }
  });
  pCodeList = utils.sortItems(pCodeList, 'dtc', param.sortDir);
  cCodeList = utils.sortItems(cCodeList, 'dtc', param.sortDir);
  bCodeList = utils.sortItems(bCodeList, 'dtc', param.sortDir);
  uCodeList = utils.sortItems(uCodeList, 'dtc', param.sortDir);

  // 昇順の場合
  if (param.sortDir === config.ASC) {
    resultItems = resultItems.concat(pCodeList);
    resultItems = resultItems.concat(cCodeList);
    resultItems = resultItems.concat(bCodeList);
    resultItems = resultItems.concat(uCodeList);
    resultItems = resultItems.concat(codeHyphenList);
    return resultItems;
  }
  // 降順の場合
  else {
    resultItems = resultItems.concat(codeHyphenList);
    resultItems = resultItems.concat(uCodeList);
    resultItems = resultItems.concat(bCodeList);
    resultItems = resultItems.concat(cCodeList);
    resultItems = resultItems.concat(pCodeList);
    return resultItems;
  }
};

// FFD 独自ソート
export const sortFuncFfd = (param) => {
  if (!param.data) {
    return null;
  }
  const resultItems = utils.sortItems(param.data, 'buttonVisible', param.sortDir);
  return resultItems;
};

// トレーニングモード時にFFDシステムがユーザーが設定した単位と同一化判定する関数
export const checkUnit = (response, ffdSystemTable, self) => {
  // 距離
  if (response.data.unit_settings.distance !== self.$t('didUnit.unitId_32')) {
    ffdSystemTable.forEach(item => {
      if (item.unit === 'unitId_32') {
        item.unit = 'unitId_61';
      }
      return item.unit;
    });
  } else {
    ffdSystemTable.forEach(item => {
      if (item.unit === 'unitId_61') {
        item.unit = 'unitId_32';
      }
      return item.unit;
    });
  }
  // 速度
  if (response.data.unit_settings.speed !== self.$t('didUnit.unitId_33')) {
    ffdSystemTable.forEach(item => {
      if (item.unit === 'unitId_33') {
        item.unit = 'unitId_72';
      }
      return item.unit;
    });
  } else {
    ffdSystemTable.forEach(item => {
      if (item.unit === 'unitId_72') {
        item.unit = 'unitId_33';
      }
      return item.unit;
    });
  }
  // 温度
  const unitId3ToSet = 20;
  const unitId4ToSet = 68;

  if (response.data.unit_settings.temperature !== self.$t('didUnit.unitId_3')) {
    ffdSystemTable.forEach(item => {
      if (item.unit === 'unitId_3') {
        item.unit = 'unitId_4';
        // ffd_data配列内のvalueを変更
        item.ffd_data.forEach(data => {
          if (data.value === unitId3ToSet) {
            data.value = unitId4ToSet;
          }
        });
      }
      return item.unit;
    });
  } else {
    ffdSystemTable.forEach(item => {
      if (item.unit === 'unitId_4') {
        item.unit = 'unitId_3';
        // ffd_data配列内のvalueを変更
        item.ffd_data.forEach(data => {
          if (data.value === unitId4ToSet) {
            data.value = unitId3ToSet;
          }
        });
      }
      return item.unit;
    });
  }
  // 圧力
  const pressureSetting = response.data.unit_settings.pressure;
  const unitIdToSet = [self.$t('didUnit.unitId_67'), self.$t('didUnit.unitId_28'), self.$t('didUnit.unitId_83'), self.$t('didUnit.unitId_29')];
  let matchingUnitId = null;
  const unitId67ToSet = 748.901;
  const unitId37ToSet = 99.845;
  const unitId28ToSet = 29.484;
  const unitId83ToSet = 14.481;
  const unitId29ToSet = 400.839;

  for (const unitId of unitIdToSet) {
    if (pressureSetting === unitId) {
      if (pressureSetting === self.$t('didUnit.unitId_67'))
        matchingUnitId = 'unitId_67';

      ffdSystemTable.forEach(item => {
        if (['unitId_37', 'unitId_28', 'unitId_83', 'unitId_29'].includes(item.unit)) {
          item.ffd_data.forEach(data => {
            if ([unitId37ToSet, unitId28ToSet, unitId83ToSet, unitId29ToSet].includes(data.value)) {
              data.value = unitId67ToSet;
              return;
            }
          });
        }
      });
      break;
    } else if (pressureSetting === self.$t('didUnit.unitId_28')) {
      matchingUnitId = 'unitId_28';
      ffdSystemTable.forEach(item => {
        if (['unitId_37', 'unitId_67', 'unitId_83', 'unitId_29'].includes(item.unit)) {
          item.ffd_data.forEach(data => {
            if ([unitId37ToSet, unitId67ToSet, unitId83ToSet, unitId29ToSet].includes(data.value)) {
              data.value = unitId28ToSet;
              return;
            }
          });
        }
      });
      break;
    } else if (pressureSetting === self.$t('didUnit.unitId_83')) {
      matchingUnitId = 'unitId_83';
      ffdSystemTable.forEach(item => {
        if (['unitId_37', 'unitId_67', 'unitId_28', 'unitId_29'].includes(item.unit)) {
          item.ffd_data.forEach(data => {
            if ([unitId37ToSet, unitId67ToSet, unitId28ToSet, unitId29ToSet].includes(data.value)) {
              data.value = unitId83ToSet;
              return;
            }
          });
        }
      });
      break;
    } else if (pressureSetting === self.$t('didUnit.unitId_29')) {
      matchingUnitId = 'unitId_29';
      ffdSystemTable.forEach(item => {
        if (['unitId_37', 'unitId_67', 'unitId_28', 'unitId_83'].includes(item.unit)) {
          item.ffd_data.forEach(data => {
            if ([unitId37ToSet, unitId67ToSet, unitId28ToSet, unitId83ToSet].includes(data.value)) {
              data.value = unitId29ToSet;
              return;
            }
          });
        }
      });
      break;
    } else if (pressureSetting === self.$t('didUnit.unitId_37')) {
      matchingUnitId = 'unitId_37';
      ffdSystemTable.forEach(item => {
        if (['unitId_67', 'unitId_28', 'unitId_83', 'unitId_29'].includes(item.unit)) {
          item.ffd_data.forEach(data => {
            if ([unitId67ToSet, unitId28ToSet, unitId83ToSet, unitId29ToSet].includes(data.value)) {
              data.value = unitId37ToSet;
              return;
            }
          });
        }
      });
      break;
    }
  }
  ffdSystemTable.forEach(item => {
    if (['unitId_37', 'unitId_67', 'unitId_28', 'unitId_83', 'unitId_29'].includes(item.unit)) {
      item.unit = matchingUnitId;
    }
    return item.unit;
  });
  // 流量
  if (response.data.unit_settings.flow_rate !== self.$t('didUnit.unitId_24')) {
    ffdSystemTable.forEach(item => {
      if (item.unit === 'unitId_24') {
        item.unit = 'unitId_47';
      }
      return item.unit;
    });
  } else {
    ffdSystemTable.forEach(item => {
      if (item.unit === 'unitId_47') {
        item.unit = 'unitId_24';
      }
      return item.unit;
    });
  }
};

// 故障・診断独自ソート
export const sortFuncDescription = (param) => {
  if (!param.data) {
    return null;
  }
  // 結果リスト
  let resultItems = [];
  // 指定外コードリスト
  const unspecifiedList = [];
  // hyphenリスト
  const hyphenList = [];
  // DTC割り当て有りリスト
  let dtcDescriptionLists = [];
  param.data.forEach(element => {
    if (element.description.indexOf('message.status_vehicle_dtc_description_unspecified') > -1) {
      unspecifiedList.push(element);
    }
    if (element.description.indexOf('message.common_hyphen') > -1) {
      hyphenList.push(element);
    }
    // dtc、またはbmcの説明かどうかを判定
    if (element.description.indexOf('dtc.') > -1 || element.description.indexOf('bmc') > -1) {
      dtcDescriptionLists.push(element);
    }
  });
  dtcDescriptionLists = utils.sortItems(dtcDescriptionLists, 'description', param.sortDir);

  // 昇順の場合
  if (param.sortDir === config.ASC) {
    resultItems = dtcDescriptionLists.concat(unspecifiedList);
    resultItems = resultItems.concat(hyphenList);
    return resultItems;
  }
  // 降順の場合
  else {
    resultItems = hyphenList.concat(unspecifiedList);
    resultItems = resultItems.concat(dtcDescriptionLists);
    return resultItems;
  }
};

// IGON回数 独自ソート
export const sortIgon = (param) => {
  if (!param.data) {
    return null;
  }
  // 結果リスト
  let resultItems = [];
  let igonList = [];
  const hyphenList = [];
  param.data.forEach(element => {
    if (element[param.sortKey] !== '-') {
      igonList.push(element);
    }
    else {
      hyphenList.push(element);
    }
  });
  igonList = utils.sortItems(igonList, param.sortKey, param.sortDir);

  // 昇順の場合
  if (param.sortDir === config.ASC) {
    resultItems = resultItems.concat(igonList);
    resultItems = resultItems.concat(hyphenList);
    return resultItems;
  }
  // 降順の場合
  else {
    resultItems = resultItems.concat(hyphenList);
    resultItems = resultItems.concat(igonList);
    return resultItems;
  }
};

// 汎用独自ソート
const sortFuncItems = (param) => {
  if (!param.data) {
    return null;
  }
  // 結果リスト
  let resultItems = [];
  let itemList = [];
  const hyphenList = [];

  param.data.forEach(element => {
    if (element[param.sortKey] !== '-') {
      itemList.push(element);
    }
    else {
      hyphenList.push(element);
    }
  });
  itemList = utils.sortItems(itemList, param.sortKey, param.sortDir);

  // 昇順の場合
  if (param.sortDir === config.ASC) {
    resultItems = resultItems.concat(itemList);
    resultItems = resultItems.concat(hyphenList);
    return resultItems;
  }
  // 降順の場合
  else {
    resultItems = resultItems.concat(hyphenList);
    resultItems = resultItems.concat(itemList);
    return resultItems;
  }
};

// 日付独自ソート
export const sortDate = (param) => {
  if (!param.data) {
    return null;
  }
  // 結果リスト
  let resultItems = [];
  let dateList = [];
  const hyphenList = [];
  const invalidList = [];
  param.data.forEach(element => {
    if (element[param.sortKey] === '-') {
      hyphenList.push(element);
    } else if (!Date.parse(element[param.sortKey])) {
      invalidList.push(element);
    } else {
      dateList.push(element);
    }
  });
  dateList = utils.sortItems(dateList, param.sortKey, param.sortDir);

  // 昇順の場合
  if (param.sortDir === config.ASC) {
    resultItems = resultItems.concat(dateList);
    resultItems = resultItems.concat(invalidList);
    resultItems = resultItems.concat(hyphenList);
    return resultItems;
  }
  // 降順の場合
  else {
    resultItems = resultItems.concat(hyphenList);
    resultItems = resultItems.concat(invalidList);
    resultItems = resultItems.concat(dateList);
    return resultItems;
  }
};

// 診断系のAPIは複数画面から使用されているため
// 現在の画面で使用されるAPIが診断/データ再生/個別診断/OBDシステムのうちどの機能なのか判別する
/* eslint-disable complexity*/
export const operationFrom = (currentRoute) => {
  let screen = '';
  switch (currentRoute) {
    case 'dataRecollection':
      screen = config.EXECUE_FUNCTION_TYPE_DATAPLAYBACK;
      break;
    case 'diagProjectDetails':
      screen = config.EXECUE_FUNCTION_TYPE_DATAPLAYBACK;
      break;
    case 'home':
      screen = config.EXECUE_FUNCTION_TYPE_DIAG;
      break;
    case 'dtcTable':
      screen = config.EXECUE_FUNCTION_TYPE_DIAG;
      break;
    // 特殊機能ダイアグコードは機能プランの特殊機能対応時に特殊機能側に移動する
    case 'individualDiagCode':
    case 'individualWorkSupport':
    case 'individualCancelCode':
    case 'individualCustomize':
    case 'individualVehicleControlHistory':
    case 'spDiagCode':
    case 'spWorkSupport':
    case 'spCancelCode':
      screen = config.EXECUE_FUNCTION_TYPE_INDIVIDUAL_DIAG;
      break;
    // OBDシステム（車両情報取得, オンボードモニタテスト, 個別診断DTC, パーマネントDTC, 全DTC）
    case 'obdSystemTop':
    case 'generalObdSystemTop':
    case 'obdIndividualMenu':
    case 'generalObdIndividualMenu':
    case 'obdOnboardSystemTest':
    case 'generalObdOnboardSystemTest':
    case 'obdGetVehicleInfo':
    case 'generalObdGetVehicleInfo':
    case 'obdOnboardMonitorTest':
    case 'generalObdOnboardMonitorTest':
    case 'obdDtc':
    case 'generalObdDtc':
    case 'obdPermanentDtc':
    case 'generalObdPermanentDtc':
    case 'obdDataMonitor':
    case 'generalObdDataMonitor':
    case 'obdAllDtc':
    case 'generalObdAllDtc':
      screen = config.FUNCTION_TYPE_OBD;
      break;
  }
  return screen;
};
/* eslint-enable complexity*/

export default {
  sortFuncCode,
  sortFuncFfd,
  sortFuncDescription,
  sortIgon,
  sortFuncItems,
  sortDate,
  operationFrom,
  checkUnit
};