import session from '../../../../share/session';
import individualWorkSupportUtil from '../../../../share/util/individualWorkSupportUtil';

/** ポーリング停止処理 */
export async function pollingStop(self) {
  // 結果を取得しないため、本APIではスピナー制御は行わない。
  try {
    //バックエンドAPI POST,GET
    const token = session.getToken();
    await individualWorkSupportUtil.pollingWspApi(
      self,
      self.netAppId,
      self.systemId,
      self.workSupportId,
      {
        id_token: token.id_token,
        type: 'pollingStop',
      },
      // エラー発生のコールバックを実装
      (errorType) => { errorType; self.showUnexpectedError(); },
      // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
      // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
    );

    // 結果に関わらず、何もしない
  } finally {
    // noop
  }
}