import session from '../../../../share/session';
import individualWorkSupportUtil from '../../../../share/util/individualWorkSupportUtil';
import { REQUEST_STATUS_COMPLETED, REQUEST_STATUS_FAILED } from '../../../../share/util/api/polling';
import { PHASE, NORMAL_MODE_MOVE_TYPE } from './constants';

/** 通常モード移行 */
export async function normalMode(self, moveType) {
  try {
    self.isLoading = true;
    //バックエンドAPI POST,GET
    const token = session.getToken();
    const response = await individualWorkSupportUtil.pollingWspApi(
      self,
      self.netAppId,
      self.systemId,
      self.workSupportId,
      {
        id_token: token.id_token,
        type: 'normal_mode',
      },
      // エラー発生のコールバックを実装
      (errorType) => { errorType; self.showUnexpectedError(); },
      // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
      // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
    );

    if (response) {
      switch (moveType) {
        case NORMAL_MODE_MOVE_TYPE.END:  // 「センサ補正完了」の場合
          // 正常コールバック
          switch (response.data.status) {
            case REQUEST_STATUS_COMPLETED:
              // 正常終了
              // 補正正常終了へ遷移
              self.phase = PHASE.COMPLETE;
              break;
            case REQUEST_STATUS_FAILED:
            default:
              // 異常終了
              // 通信異常エラーへ遷移
              self.showUnexpectedError();
              break;
          }
          break;
        case NORMAL_MODE_MOVE_TYPE.STOP:  // 「センサ補正中止」の場合
          // 結果に関わらず、補正モード終了へ遷移
          self.phase = PHASE.END;
          break;
        case NORMAL_MODE_MOVE_TYPE.ERROR:  // 「異常時」の場合
          // 結果に関わらず、通信異常エラーへ遷移
          self.showUnexpectedError();
          break;
        case NORMAL_MODE_MOVE_TYPE.DTC_WITHIN_RANGE:  // 「DTC範囲内エラー」の場合
          // 結果に関わらず、DTC記録済み（範囲内）エラーへ遷移
          self.phase = PHASE.ERROR_DTC_RECORDED_IN;
          break;
        case NORMAL_MODE_MOVE_TYPE.DTC_OUT_RANGE:  // 「DTC範囲外エラー」の場合
          // 結果に関わらず、DTC記録済み（範囲外）エラーへ遷移
          self.phase = PHASE.ERROR_DTC_RECORDED_OUT;
          break;
        default:
          break;
      }
    }
  } finally {
    self.isLoading = false;
  }
}