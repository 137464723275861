import * as obd from "./obd";
import {
  headerType_none,
  headerType_start,
  headerType_vehicle,
  headerType_result,
} from "../constants/uiConfigs";

import util from "@/share/util/utils";
import config from "@/share/config";

import blank from "@/components/vue/Blank";
import termsAgreement from "@/components/vue/TermsAgreement";
import changePassword from "@/components/vue/ChangePassword";
import changeUnit from "@/components/vue/ChangeUnit";
import informationMain from "@/components/vue/InformationMain";
import systemTable from "@/components/vue/SystemTable";
import systemInformation from "@/components/vue/SystemInformation";
import attention from "@/components/vue/AttentionRepro";
import execution from "@/components/vue/ExecuteRepro";
import result from "@/components/vue/ReproResult";
import completeNewPasswordChallenge from "@/components/vue/CompleteNewPasswordChallenge";
import home from "@/components/vue/Home";
import initPassword from "@/components/vue/InitPassword";
import suSystemTable from "@/components/vue/SuSystemTable";
import usbVerificationFailed from "@/components/vue/UsbVerificationFailed";
import diagProjectList from "@/components/vue/DiagProjectList";
import dtcTable from "@/components/vue/DtcTable";
import individualFunctions from "@/components/vue/IndividualFunctions";
import individualDiagCode from "@/components/vue/IndividualDiagCode";
import individualDataMonitor from "@/components/vue/IndividualDataMonitor";
import individualActiveTest from "@/components/vue/individualActiveTest/IndividualActiveTest";
import individualWorkSupport from "@/components/vue/individualWorkSupport/IndividualWorkSupport";
import individualVehicleControlHistory from "@/components/vue/IndividualVehicleControlHistory";
import individualCustomize from "@/components/vue/individualCustomize";
import individualCancelCode from "@/components/vue/IndividualCancelCode";
import menu from "@/components/vue/Menu";
import informationTabs from "@/components/vue/InformationTabs";
import diagProjectDetails from "@/components/vue/DiagProjectDetails";
import dataRecollection from "@/components/vue/DataRecollection";
import oldBrowserError from "@/share/components/vue/OldBrowserError";
import macKey from "@/components/vue/MACKey";
import immobilizer from "@/components/vue/Immobilizer";
import specialFeatureEcuTable from "@/components/vue/specialFeature/SpecialFeatureEcuTable";
import specialFeatureSelect from "@/components/vue/specialFeature/SpecialFeatureSelect";
import spWorkSupport from "@/components/vue/specialFeature/spWorkSupport/SpWorkSupport";
import spDiagCode from "@/components/vue/specialFeature/spDiagCode/SpDiagCode";
import spCancelCode from "@/components/vue/specialFeature/spCancelCode/SpCancelCode";
import spDataMonitor from "@/components/vue/specialFeature/spDataMonitor/SpDataMonitor";
import individualEcuTable from "@/components/vue/IndividualEcuTable";
import individualSelect from "@/components/vue/IndividualSelect";
import realWorldDataTable from "@/components/vue/realWorldData/RealWorldDataTable";
import componentBase from "@/components/vue/obd/ObdBase";

// ログイン画面は日本と中国で分ける
const login = util.isChinaResion()
  ? require("@/components/vue/LoginChina").default
  : require("@/components/vue/Login").default;

/* eslint-disable max-lines */
export const routes = [
  {
    path: "/",
    name: "login",
    component: login,
    meta: {
      requiresAuth: false,
      headerType: headerType_none,
      flowNavigationType: config.NAVIGATION_NONE,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "common-main-no-nav",
    },
    props: true,
  },
  {
    path: "/old-browser",
    name: "oldBrowserError",
    component: oldBrowserError,
    meta: {
      requiresAuth: false,
      headerType: headerType_none,
      flowNavigationType: config.NAVIGATION_NONE,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "common-main-no-nav",
    },
  },
  {
    path: "/blank",
    name: "blank",
    component: blank,
    meta: {
      requiresAuth: false,
      headerType: headerType_none,
      flowNavigationType: config.NAVIGATION_NONE,
      breadCrumbLevel: 1,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "common-main-no-nav",
    },
    props: true,
  },
  {
    path: "/terms-agreement",
    name: "termsAgreement",
    component: termsAgreement,
    meta: {
      requiresAuth: true,
      headerType: headerType_none,
      flowNavigationType: config.NAVIGATION_NONE,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "common-main-no-nav",
    },
  },
  {
    path: "/change-password",
    name: "changePassword",
    component: changePassword,
    meta: {
      requiresAuth: true,
      headerType: headerType_start,
      flowNavigationType: config.NAVIGATION_NONE,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "common-main-no-nav",
    },
  },
  {
    path: "/change-unit",
    name: "changeUnit",
    component: changeUnit,
    meta: {
      requiresAuth: true,
      headerType: headerType_start,
      flowNavigationType: config.NAVIGATION_NONE,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "common-main",
    },
  },
  {
    path: "/complete-new-password-challenge",
    name: "completeNewPasswordChallenge",
    component: completeNewPasswordChallenge,
    meta: {
      requiresAuth: false,
      headerType: headerType_none,
      flowNavigationType: config.NAVIGATION_NONE,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "common-main-no-nav",
    },
    props: true,
  },
  {
    path: "/init-password",
    name: "initPassword",
    component: initPassword,
    meta: {
      requiresAuth: false,
      headerType: headerType_none,
      flowNavigationType: config.NAVIGATION_NONE,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "common-main-no-nav",
    },
  },
  {
    path: "/home",
    name: "home",
    component: home,
    meta: {
      requiresAuth: true,
      breadCrumbLevel: 2,
      headerType: headerType_start,
      flowNavigationType: config.NAVIGATION_DISPLAY,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "",
    },
    props: true,
  },
  {
    path: "/menu",
    name: "menu",
    component: menu,
    meta: {
      requiresAuth: true,
      breadCrumbLevel: 3,
      headerType: headerType_start,
      flowNavigationType: config.NAVIGATION_DISPLAY,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "",
    },
  },
  {
    path: "/diag-project-list",
    name: "diagProjectList",
    component: diagProjectList,
    meta: {
      requiresAuth: true,
      breadCrumbLevel: 3,
      headerType: headerType_start,
      flowNavigationType: config.NAVIGATION_DISPLAY,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "",
    },
  },
  {
    path: "/diag-project-details",
    name: "diagProjectDetails",
    component: diagProjectDetails,
    meta: {
      requiresAuth: true,
      breadCrumbLevel: 3,
      headerType: headerType_start,
      flowNavigationType: config.NAVIGATION_DISPLAY,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "",
    },
    props: true,
  },
  {
    path: "/data-recollection",
    name: "dataRecollection",
    component: dataRecollection,
    meta: {
      requiresAuth: true,
      breadCrumbLevel: 4,
      headerType: headerType_start,
      flowNavigationType: config.NAVIGATION_DISPLAY,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "container-execute",
    },
    props: true,
  },
  {
    path: "/information-Main",
    name: "informationMain",
    component: informationMain,
    props: true,
    children: [
      {
        path: "/information-tabs",
        name: "informationTabs",
        component: informationTabs,
        props: true,
        children: [
          {
            path: "/system-table",
            name: "systemTable",
            component: systemTable,
            meta: {
              requiresAuth: true,
              breadCrumbLevel: 4,
              headerType: headerType_vehicle,
              flowNavigationType: config.NAVIGATION_DISPLAY,
              viewProcessMode: config.PROCESS_IDLING,
              containerStyle: "container-execute",
            },
          },
          {
            path: "/su-system-table",
            name: "suSystemTable",
            component: suSystemTable,
            meta: {
              requiresAuth: true,
              breadCrumbLevel: 4,
              headerType: headerType_vehicle,
              flowNavigationType: config.NAVIGATION_DISPLAY,
              viewProcessMode: config.PROCESS_IDLING,
              containerStyle: "container-execute",
            },
          },
          {
            path: "/dtc-table",
            name: "dtcTable",
            component: dtcTable,
            meta: {
              requiresAuth: true,
              breadCrumbLevel: 4,
              headerType: headerType_vehicle,
              flowNavigationType: config.NAVIGATION_DISPLAY,
              viewProcessMode: config.PROCESS_IDLING,
              containerStyle: "container-execute",
            },
          },
          {
            path: "/individualEcuTable",
            name: "individualEcuTable",
            component: individualEcuTable,
            meta: {
              requiresAuth: true,
              breadCrumbLevel: 4,
              headerType: headerType_vehicle,
              flowNavigationType: config.NAVIGATION_DISPLAY,
              viewProcessMode: config.PROCESS_IDLING,
              containerStyle: "container-execute",
            },
          },
          {
            path: "/:systemId/individualSelect",
            name: "individualSelect",
            component: individualSelect,
            meta: {
              requiresAuth: true,
              breadCrumbLevel: 5,
              breadCrumbData: (to, from) => {
                from;
                return util.createBreadCrumbBaseData(to, 'diagSytem.' + to?.params.systemId);
              },
              headerType: headerType_vehicle,
              flowNavigationType: config.NAVIGATION_DISPLAY,
              viewProcessMode: config.PROCESS_IDLING,
              containerStyle: "container-execute",
            },
            props: true,
          },
          {
            path: "/individual",
            name: "individual",
            component: individualFunctions,
            children: [
              {
                path: "/:systemId/individual-diag-code",
                name: "individualDiagCode",
                component: individualDiagCode,
                meta: {
                  requiresAuth: true,
                  breadCrumbLevel: 6,
                  headerType: headerType_vehicle,
                  flowNavigationType: config.NAVIGATION_DISPLAY,
                  viewProcessMode: config.PROCESS_IDLING,
                  containerStyle: "container-execute",
                },
              },
              {
                path: "/:systemId/individual-work-support",
                name: "individualWorkSupport",
                component: individualWorkSupport,
                meta: {
                  requiresAuth: true,
                  breadCrumbLevel: 6,
                  headerType: headerType_vehicle,
                  flowNavigationType: config.NAVIGATION_DISPLAY,
                  viewProcessMode: config.PROCESS_IDLING,
                  containerStyle: "container-execute",
                },
              },
              {
                path: "/:systemId/individual-vehicle-control-history",
                name: "individualVehicleControlHistory",
                component: individualVehicleControlHistory,
                meta: {
                  requiresAuth: true,
                  breadCrumbLevel: 6,
                  headerType: headerType_vehicle,
                  flowNavigationType: config.NAVIGATION_DISPLAY,
                  viewProcessMode: config.PROCESS_IDLING,
                  containerStyle: "container-execute",
                },
              },
              {
                path: "/:systemId/individual-vehicle-customize",
                name: "individualCustomize",
                component: individualCustomize,
                meta: {
                  requiresAuth: true,
                  breadCrumbLevel: 6,
                  headerType: headerType_vehicle,
                  flowNavigationType: config.NAVIGATION_DISPLAY,
                  viewProcessMode: config.PROCESS_IDLING,
                  containerStyle: "container-execute",
                },
              },
              {
                path: "/:systemId/individual-cancel-code",
                name: "individualCancelCode",
                component: individualCancelCode,
                meta: {
                  requiresAuth: true,
                  breadCrumbLevel: 6,
                  headerType: headerType_vehicle,
                  flowNavigationType: config.NAVIGATION_DISPLAY,
                  viewProcessMode: config.PROCESS_IDLING,
                  containerStyle: "container-execute",
                },
              },
              {
                path: "/:systemId/individual-data-monitor",
                name: "individualDataMonitor",
                component: individualDataMonitor,
                meta: {
                  requiresAuth: true,
                  breadCrumbLevel: 6,
                  headerType: headerType_vehicle,
                  flowNavigationType: config.NAVIGATION_DISPLAY,
                  viewProcessMode: config.PROCESS_IDLING,
                  containerStyle: "container-execute",
                },
              },
              {
                path: "/:systemId/individual-active-test",
                name: "individualActiveTest",
                component: individualActiveTest,
                meta: {
                  requiresAuth: true,
                  breadCrumbLevel: 6,
                  headerType: headerType_vehicle,
                  flowNavigationType: config.NAVIGATION_DISPLAY,
                  viewProcessMode: config.PROCESS_IDLING,
                  containerStyle: "container-execute",
                },
              },
            ],
          },
          {
            path: "/system-information",
            name: "systemInformation",
            component: systemInformation,
            meta: {
              requiresAuth: true,
              breadCrumbLevel: 5,
              headerType: headerType_vehicle,
              flowNavigationType: config.NAVIGATION_DISPLAY,
              viewProcessMode: config.PROCESS_IDLING,
              containerStyle: "container-execute",
            },
          },
          {
            path: "/mac-key",
            name: "macKey",
            component: macKey,
            meta: {
              requiresAuth: true,
              breadCrumbLevel: 4,
              headerType: headerType_vehicle,
              flowNavigationType: config.NAVIGATION_DISPLAY,
              viewProcessMode: config.PROCESS_IDLING,
              containerStyle: "container-execute",
            },
            props: true,
          },
          {
            path: "/immobilizer",
            name: "immobilizer",
            component: immobilizer,
            meta: {
              requiresAuth: true,
              breadCrumbLevel: 4,
              headerType: headerType_vehicle,
              flowNavigationType: config.NAVIGATION_DISPLAY,
              viewProcessMode: config.PROCESS_IDLING,
              containerStyle: "container-execute",
            },
            props: true,
          },
          {
            path: "/specialFeatureEcuTable",
            name: "specialFeatureEcuTable",
            component: specialFeatureEcuTable,
            meta: {
              requiresAuth: true,
              breadCrumbLevel: 4,
              headerType: headerType_vehicle,
              flowNavigationType: config.NAVIGATION_DISPLAY,
              viewProcessMode: config.PROCESS_IDLING,
              containerStyle: "container-execute",
            },
          },
          {
            path: "/:systemId/specialFeatureSelect",
            name: "specialFeatureSelect",
            component: specialFeatureSelect,
            meta: {
              requiresAuth: true,
              breadCrumbLevel: 5,
              breadCrumbData: (to, from) => {
                from;
                return util.createBreadCrumbBaseData(to, 'diagSytem.' + to?.params.systemId);
              },
              headerType: headerType_vehicle,
              flowNavigationType: config.NAVIGATION_DISPLAY,
              viewProcessMode: config.PROCESS_IDLING,
              containerStyle: "container-execute",
            },
            props: true,
          },
          {
            path: "/specialFeatureIndividual",
            name: "specialFeatureIndividual",
            component: individualFunctions,
            children: [
              {
                path: "/:systemId/spDiagCode",
                name: "spDiagCode",
                component: spDiagCode,
                meta: {
                  requiresAuth: true,
                  breadCrumbLevel: 6,
                  headerType: headerType_vehicle,
                  flowNavigationType: config.NAVIGATION_DISPLAY,
                  viewProcessMode: config.PROCESS_IDLING,
                  containerStyle: "container-execute",
                },
              },
              {
                path: "/:systemId/spWorkSupport",
                name: "spWorkSupport",
                component: spWorkSupport,
                meta: {
                  requiresAuth: true,
                  breadCrumbLevel: 6,
                  headerType: headerType_vehicle,
                  flowNavigationType: config.NAVIGATION_DISPLAY,
                  viewProcessMode: config.PROCESS_IDLING,
                  containerStyle: "container-execute",
                },
              },
              {
                path: "/:systemId/spCancelCode",
                name: "spCancelCode",
                component: spCancelCode,
                meta: {
                  requiresAuth: true,
                  breadCrumbLevel: 6,
                  headerType: headerType_vehicle,
                  flowNavigationType: config.NAVIGATION_DISPLAY,
                  viewProcessMode: config.PROCESS_IDLING,
                  containerStyle: "container-execute",
                },
              },
              {
                path: "/:systemId/spDataMonitor",
                name: "spDataMonitor",
                component: spDataMonitor,
                meta: {
                  requiresAuth: true,
                  breadCrumbLevel: 6,
                  headerType: headerType_vehicle,
                  flowNavigationType: config.NAVIGATION_DISPLAY,
                  viewProcessMode: config.PROCESS_IDLING,
                  containerStyle: "container-execute",
                },
              },
            ],
          },
          {
            path: "/realWorldDataTable",
            name: "realWorldDataTable",
            component: realWorldDataTable,
            meta: {
              requiresAuth: true,
              breadCrumbLevel: 4,
              headerType: headerType_vehicle,
              flowNavigationType: config.NAVIGATION_DISPLAY,
              viewProcessMode: config.PROCESS_IDLING,
              containerStyle: "container-execute",
            },
          },
          // Phase4 Step7 追加
          // General 画面側の OBD システム内の各画面（トップを除く）
          obd.createRouteInfo(obd.PAGE_KEY_SYSTEM_LIST, obd.PAGE_FROM_GENERAL),
          obd.createRouteInfo(obd.PAGE_KEY_INDIVIDUAL_MENU, obd.PAGE_FROM_GENERAL),
          obd.createRouteInfo(obd.PAGE_KEY_ONBOARD_SYSTEM_TEST, obd.PAGE_FROM_GENERAL),
          obd.createRouteInfo(obd.PAGE_KEY_GET_VEHICLE_INFO, obd.PAGE_FROM_GENERAL),
          obd.createRouteInfo(obd.PAGE_KEY_ONBOARD_MONITOR_TEST, obd.PAGE_FROM_GENERAL),
          obd.createRouteInfo(obd.PAGE_KEY_INDIVIDUAL_DTC, obd.PAGE_FROM_GENERAL),
          obd.createRouteInfo(obd.PAGE_KEY_INDIVIDUAL_PERMANENT_DTC, obd.PAGE_FROM_GENERAL),
          obd.createRouteInfo(obd.PAGE_KEY_ALL_DTC, obd.PAGE_FROM_GENERAL),
          obd.createRouteInfo(obd.PAGE_KEY_INDIVIDUAL_DATA_MONITOR, obd.PAGE_FROM_GENERAL),
        ],
      },
      {
        path: "/attention",
        name: "attention",
        component: attention,
        meta: {
          requiresAuth: true,
          breadCrumbLevel: 6,
          headerType: headerType_vehicle,
          flowNavigationType: config.NAVIGATION_DISPLAY,
          viewProcessMode: config.PROCESS_REPRO_PROCESSING,
          containerStyle: "container-execute",
        },
      },
      {
        path: "/execution",
        name: "execution",
        component: execution,
        meta: {
          requiresAuth: true,
          breadCrumbLevel: 6,
          headerType: headerType_vehicle,
          flowNavigationType: config.NAVIGATION_DISPLAY,
          viewProcessMode: config.PROCESS_REPRO_PROCESSING,
          containerStyle: "container-execute",
        },
        props: true,
      },
      {
        path: "/result",
        name: "result",
        component: result,
        meta: {
          requiresAuth: true,
          breadCrumbLevel: 7,
          headerType: headerType_result,
          flowNavigationType: config.NAVIGATION_DISPLAY,
          viewProcessMode: config.PROCESS_REPRO_COMPLETED,
          containerStyle: "container-execute",
        },
        props: true,
      },
      {
        path: "/usb-verification-failed",
        name: "usbVerificationFailed",
        component: usbVerificationFailed,
        meta: {
          requiresAuth: true,
          breadCrumbLevel: 5,
          headerType: headerType_vehicle,
          flowNavigationType: config.NAVIGATION_DISPLAY,
          viewProcessMode: config.PROCESS_REPRO_SUB_FAILED,
          containerStyle: "container-execute",
        },
      },
    ],
  },
  // Phase4 Step7 追加
  // OBD システム（トップ）画面
  obd.createRouteInfo(obd.PAGE_KEY_TOP, obd.PAGE_FROM_START),
  obd.createRouteInfo(obd.PAGE_KEY_TOP, obd.PAGE_FROM_GENERAL),
  // Start 画面側の OBD システム内の各画面（トップを除く）
  {
    path: "/obd",
    name: "obdBase",
    component: componentBase,
    children: [
      obd.createRouteInfo(obd.PAGE_KEY_SYSTEM_LIST, obd.PAGE_FROM_START),
      obd.createRouteInfo(obd.PAGE_KEY_INDIVIDUAL_MENU, obd.PAGE_FROM_START),
      obd.createRouteInfo(obd.PAGE_KEY_ONBOARD_SYSTEM_TEST, obd.PAGE_FROM_START),
      obd.createRouteInfo(obd.PAGE_KEY_GET_VEHICLE_INFO, obd.PAGE_FROM_START),
      obd.createRouteInfo(obd.PAGE_KEY_ONBOARD_MONITOR_TEST, obd.PAGE_FROM_START),
      obd.createRouteInfo(obd.PAGE_KEY_INDIVIDUAL_DTC, obd.PAGE_FROM_START),
      obd.createRouteInfo(obd.PAGE_KEY_INDIVIDUAL_PERMANENT_DTC, obd.PAGE_FROM_START),
      obd.createRouteInfo(obd.PAGE_KEY_ALL_DTC, obd.PAGE_FROM_START),
      obd.createRouteInfo(obd.PAGE_KEY_INDIVIDUAL_DATA_MONITOR, obd.PAGE_FROM_START),
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    component: login,
    meta: {
      headerType: headerType_none,
      flowNavigationType: config.NAVIGATION_NONE,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "common-main-no-nav",
    },
  },
];
