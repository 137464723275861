import api from '../../common/api';
import apiService from '../../share/util/api/service';
import config from '../../share/config';

export default {
  namespaced: true,
  
  mutations: {

  },

  actions: {
    //機能使用回数をカウントする
    usageCountUp (context, payload) {
      if(!context.rootState.userProperty.isTechnicalHq &&
        !apiService.getPairingAppMode() // テストモードの時はカウントを実施しない
      ){
        const param = {
          //repro or diag or data_playback
          count_target: payload.type,
          date: new Date().getTime()
        };
        if (payload.systemName) {
          // システム名パラメータの追加
          param.system_name = payload.systemName;
        }
        if (payload.immutableId) {
          // 不変IDパラメータの追加
          param.immutable_id = payload.immutableId;
        }
        //APIを投げて機能使用回数をcountする
        api.postCall(config.ADMIN, '/usageCount', param);
      }
      return;
    },
  }
};