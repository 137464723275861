<div class="container-fluid datamonitor-select">
  <div class="row contents-div">
    <!-- left list -->
    <div class="left-div">
      <b-form class="filter-form mt-4" autocomplete="off" @contextmenu.once="() => {showDebugButton ? showDebugButton() : logWrapper.log('not implement');}">
        <b-form-input v-model="filterText" :placeholder="$t('individual.label_data_monitor_filter')" type="text"
          id="filter-text" class="" size="sm" autocomplete="off" @input="updateFilter" />
          <b-form-select class="custom-select custom-select-sm" v-model="selectedSignalGroup" @change="updateFilter" size="sm" :options="signalGroupList" style="margin-left: 10px;">
            <template #first>
              <b-form-select-option :value="null" disabled>{{ $t('individual.placeholder_select_signal_group') }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-form>
      <b-button v-if="showDebug" @click="downloadTSV" style="width: 70px !important; height: 20px !important; font-size: 9px !important;">Download</b-button><!-- for debug -->

      <cstm-table
        useStripeStyle
        rowSelect
        usePopup
        useSort
        hasTabs
        class="support-table mt-2"
        type="simple"
        ref="filtered_tbl"
        :currentSortkey="currentSortkey"
        :items="escapeFunctionList(filterFunctionList)"
        :columns="systemTableColumns"
        @rowClicked="clickRow"
      >
      </cstm-table>
    </div>

    <!-- middle btns -->
    <div class="middle-div px-0">
      <div class="button-container text-center short-buttons">
        <b-button
          @click="handleAllAppendButtonClick"
          size="sm"
          class="btn-short"
          variant="primary"
          >{{ $t('individual.button_data_monitor_all_add') }}</b-button
        ><br />
        <b-button
          @click="handleAppendButtonClick"
          size="sm"
          class="mt-2 btn-short"
          variant="primary"
          >{{ $t('individual.button_data_monitor_add') }}</b-button
        ><br />
        <b-button
          @click="handleRemoveButtonClick"
          size="sm"
          class="mt-2 btn-short"
          variant="primary"
          >{{ $t('individual.button_data_monitor_delete') }}</b-button
        ><br />
        <b-button
          @click="handleAllRemoveButtonClick"
          size="sm"
          class="mt-2 btn-short"
          variant="primary"
          >{{ $t('individual.button_data_monitor_all_delete') }}</b-button
        >
      </div>
    </div>
    <!-- right list -->
    <div class="right-div">
      <b-form class="filter-form mt-4" autocomplete="off">
        <b-form-input v-model="filterTextRemove" :placeholder="$t('individual.label_data_monitor_filter')" type="text"
          id="filter-text-remove" class="" size="sm" autocomplete="off" @input="updateFilterRemove" />
      </b-form>
      <cstm-table
        useStripeStyle
        rowSelect
        hasTabs
        usePopup
        initSortDir="asc"
        id="selected"
        class="support-table mt-2"
        type="simple"
        :items="escapeFunctionList(filterRemoveFunctionList)"
        :columns="cstmTableColumns"
        @rowClicked="clickRowRemove"
      >
      </cstm-table>
      <div class="text-right w-100">
        <b-button
          @click="startExecute"
          size="sm"
          class="mt-2"
          variant="primary"
        >
          {{ $t('individual.button_data_monitor_start') }}
        </b-button>
      </div>
    </div>
  </div>
  <processing-view :processing="processing"></processing-view>
</div>
