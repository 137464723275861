/**
 * 機能毎の設定値。
 */
export const FUNCTION_CONFIGS = {
  allDtc: {
    isDefault: true,
    labelKey: "message.label_measured_data_details_all_dtc",
  },
  diagCode: {
    useResourceSystemName: true,
    labelKey: "individual.individual_diagnosis_function_diag_code",
  },
  readiness: {
    useResourceSystemName: true,
    labelKey: "individual.label_readiness_code",
  },
  controlHistory: {
    useResourceSystemName: true,
    labelKey:
      "individual.individual_diagnosis_function_vehicle_control_history",
  },
  customize: {
    useResourceSystemName: true,
    labelKey: "individual.individual_diagnosis_function_customize",
  },
  cancelCode: {
    useResourceSystemName: true,
    labelKey: "individual.individual_diagnosis_function_cancel_code",
  },
  workSupport: {
    useResourceSystemName: true,
    labelKey: "individual.individual_diagnosis_function_work_support",
  },
  dataMonitor: {
    useResourceSystemName: true,
    labelKey: "individual.individual_diagnosis_function_data_monitor",
  },
  activeTest: {
    useResourceSystemName: true,
    labelKey: "individual.individual_diagnosis_function_active_test",
  },
  obdAllDtc: {
    isObd: true,
    labelKey: "fixed_resource.obd_menu_alldtc",
  },
  obdDiagCode: {
    isObd: true,
    useResourceSystemName: false, // OBD の場合は ECU に設定された名称をそのまま表示し、翻訳しない
    labelKey: "fixed_resource.obd_menu_dtc",
  },
  obdDataMonitor: {
    isObd: true,
    useResourceSystemName: false, // OBD の場合は ECU に設定された名称をそのまま表示し、翻訳しない
    labelKey: "fixed_resource.obd_menu_datamonitor",
  },
  obdOnBoardMonitorTest: {
    isObd: true,
    useResourceSystemName: false, // OBD の場合は ECU に設定された名称をそのまま表示し、翻訳しない
    labelKey: "fixed_resource.obd_menu_monitortest",
  },
  obdVehicleInfo: {
    isObd: true,
    useResourceSystemName: false, // OBD の場合は ECU に設定された名称をそのまま表示し、翻訳しない
    labelKey: "fixed_resource.obd_menu_infotype",
  },
  obdPermanentDiagCode: {
    isObd: true,
    useResourceSystemName: false, // OBD の場合は ECU に設定された名称をそのまま表示し、翻訳しない
    labelKey: "fixed_resource.obd_menu_pdtc",
  },
};

/**
 * activeLabel の初期値を生成する。
 * @returns {object} activeLabel の初期値
 */
export const createInitialActiveLabel = () => {
  const ret = {};
  for (const [key, value] of Object.entries(FUNCTION_CONFIGS)) {
    ret[key] = !!value.isDefault;
  }
  return ret;
};

/**
 * 指定された機能のタブ名を生成する。
 * @param {object} comment this を指定
 * @param {string} functionKey 機能
 */
export const createTabName = (component, functionKey) => {
  const settings = FUNCTION_CONFIGS[functionKey];
  if (!settings) {
    throw new Error(`functionKey[${functionKey}] is invalid.`);
  }

  if (settings.isObd) {
    // OBD の場合は、後ろに (OBD) を付ける
    return `${component.$t(settings.labelKey)}${component.$t(
      "fixed_resource.obd_menu"
    )}`;
  }

  return `${component.$t(settings.labelKey)}`;
};
