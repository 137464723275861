<div class="col-12">
  <div class="list-detail-top">
    <div class="text-right">
      <!-- メモボタン -->
      <b-button variant="primary" size="sm" class="ml-2" @click="parentOpenMemoModal">
        {{ $t('message.button_vehicle_dtc_note_modal') }}
      </b-button>
    </div>
  </div>

  <!-- 変換済項目を画面に表示 -->
  <div class="text-left align-middle">
    <div v-for="(item, index) in showData" :key="index" class="ml-2 mt-3">
      {{ item.vehicle_info }}
      <div v-for="(bc, inx) in item.body" :key="inx">
        {{ bc.item_name + " " }}
        {{ bc.value + " " }}
        {{ bc.unit === "-" ? "" : bc.unit }}
      </div>
    </div>
  </div>

  <processing-view :processing="processing"></processing-view>
</div>