import session from '../../../../share/session';
import individualWorkSupportUtil from '../../../../share/util/individualWorkSupportUtil';
import error from '../../../../common/nodejs/errors';
import { REQUEST_STATUS_COMPLETED, REQUEST_STATUS_FAILED } from '../../../../share/util/api/polling';
import { NORMAL_MODE_MOVE_TYPE } from './constants';
import { normalMode } from './normalMode';

/** 現在DTC確認（センサ補正後） */
export async function checkDtcAfter(self) {
  try {
    self.isLoading = true;
    //バックエンドAPI POST,GET
    const token = session.getToken();
    const response = await individualWorkSupportUtil.pollingWspApi(
      self,
      self.netAppId,
      self.systemId,
      self.workSupportId,
      {
        id_token: token.id_token,
        type: 'check_dtc_after',
      },
      // エラー発生のコールバックを実装
      (errorType) => { errorType; self.showUnexpectedError(); },
      // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
      // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
    );

    if (response) {
      // 正常コールバック
      switch (response.data.status) {
        case REQUEST_STATUS_COMPLETED:
          // 正常終了
          // 通常モード移行（センサ補正完了）APIを実行
          await normalMode(self, NORMAL_MODE_MOVE_TYPE.END);
          break;
        case REQUEST_STATUS_FAILED:
          // 異常終了
          switch (response.data.async_code) {
            case error.WSP_40002_DTC_OUT_OF_RANGE_ERROR:
              // 通常モード移行（DTC範囲外エラー）APIを実行
              await normalMode(self, NORMAL_MODE_MOVE_TYPE.DTC_OUT_RANGE);
              break;
            case error.WSP_40002_DTC_WITHIN_RANGE_ERROR:
              // 通常モード移行（DTC範囲内エラー）APIを実行
              await normalMode(self, NORMAL_MODE_MOVE_TYPE.DTC_WITHIN_RANGE);
              break;
            case error.WSP_40002_COMMUNICATION_ERROR:
            default:
              // 通常モード移行（異常時）APIを実行
              await normalMode(self, NORMAL_MODE_MOVE_TYPE.ERROR);
              break;
          }
          break;
        default:
          // 異常終了：通信異常エラー
          self.showUnexpectedError();
          return;
      }
    }
  } finally {
    self.isLoading = false;
  }
}