<div class="container">
  <div v-if="isTestMode" class="row text-center">
    <div class="col-12">
      <label class="sub-title-style alert-msg-color">{{ $t('message.label_title_training_mode') }}</label>
    </div>
  </div>
  <div class="row">
    <!-- USB照合失敗 -->
    <div class="col-12 text-center w-100">
      <div class="usb-verification-failed-container">
        <div class="col-12 w-100">
          <label class="mt-4 usb-verification-failed-style">
            {{ $t('message.header_usb_verification_failed') }}
          </label>
        </div>
        <div class="ml-3 text-left">
          {{ $t('message.message_usb_verification_failed') }}
        </div>
        <div class="col-12 mt-3 text-left">
          <b-table bordered striped :items="usbVerificationInfo" :fields="fieldsConfig"></b-table>
        </div>
        <!-- 診断画面に戻るボタン -->
        <div class="col-12 text-center mt-3">
          <b-button variant="primary" v-on:click="backToHome()" class="mb-3">
            {{ $t('message.button_repro_back_to_diagnosis') }}</b-button>
        </div>
      </div>
    </div>
  </div>

  <processing-view :processing="processing"></processing-view>
</div>