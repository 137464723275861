<div class="container-fluid mt-2 mb-2" id="detail_fd_modal">
  <b-overlay :show="modalLoading" opacity="0.8" spinner-variant="primary" rounded="lg">
    <div class="ml-2">
      <label style="font-weight: bold;">{{ statusLabel }} {{ statusValue }}</label>
    </div>
    <div class="mt-2 mb-2" style="display: flex;">
      <div>
        <b-button class="ml-2" variant="primary" size="sm" @click="csvExport()">
          {{ $t('message.file_export') }}
        </b-button>
      </div>
    </div>
    <cstm-table-modal class="detailed-fd mt-2 ml-2" useStripeStyle hasTabs :items="detailFdTableItems"
      :columns="detailFdTableColumns" useSort currentSortkey="did" initSortDir="asc" type="simple" usePopup>
    </cstm-table-modal>
  </b-overlay>
</div>