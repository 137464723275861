// 画面フェーズ
export const PHASE = {
  START: 'start', // 待機
  EXECUTE: 'execute', // 計測中表示
  CANCEL_CONFIRM: 'cancel_confirm', // キャンセル確認表示
  COMMUNICATION_JUDGE: 'communication_judge', // 変速比変化異常判定中
  COMPLETE: 'complete', // 変速比変化異常判定結果
  SAVE: 'save', // プロジェクトデータ保存
  ERROR: 'error', // エラー
  DEFAULT_SESSION_ERROR: 'defaultSessionError', // DefaultSessionエラー
};
// スピナー表示時の画面不透明度
export const OPACITY = {
  DEFAULT: '0.3',  // 通常
  ZERO: '0.0'      // 計測中画面用（透明）
};