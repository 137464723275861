<div class="data-monitor-virtual-list-dev" ref="virtual_list_dev">

  <div class="table-filter checkbox-size-lg" ref="table_filter" @contextmenu.once="() => {showDebugButton ? showDebugButton() : logWrapper.log('not implement');}">
    <div class="table-filter-text">
      <b-form class="filter-form" autocomplete="off">
        <b-form-input v-model="filterTextList" :placeholder="$t('individual.label_data_monitor_filter')" type="text"
          id="filter-text-list" class="" size="sm" autocomplete="off" @input="handleUpdateFilter" />
      </b-form>
    </div>
    <b-button v-if="showDebug" @click="downloadTSV" style="margin: 5px 3px 0px 3px; width: 70px !important; height: 24px !important; font-size: 9px !important;">Download</b-button><!-- for debug -->
    <b-form-checkbox-group v-if="showDebug"><!-- for debug -->
      <b-form-checkbox 
        v-model="filterCheckList[0]"
        size="sm"
        class="ml-2"
        @change="handleUpdateFilterCheck()">
        {{ $t("individual.label_data_monitor_split_graph_filter") }}
      </b-form-checkbox>
      <b-form-checkbox 
        v-model="filterCheckList[1]"
        size="sm" 
        class="ml-2"
        @change="handleUpdateFilterCheck()">
        {{ $t("individual.label_data_monitor_join_graph_filter") }}
      </b-form-checkbox>
    </b-form-checkbox-group>
  </div>

  <div class="virtual-list-title" ref="virtual_list_title">
    <div v-for="column in columns" 
      :key="column.name" 
      :class="['title-row', getTitleStyle(column.name)]">
      {{ column.label }}
    </div>
    <div v-show="isListScrolled" class="title-dummy">&nbsp;</div><!-- dummy -->
  </div>
  <virtual-list class="virtual-list"
    :id="`${componentName()}-virtual-list`"
    :data-key="'name'" 
    :data-sources="items" 
    :data-component="itemComponent"
    :keeps=30
    :extra-props="{highlightItems: highlightItems, splitGraphLines: splitGraphLines, unionGraphLines: unionGraphLines, splitGraphMaxLines: splitGraphMaxLines, unionGraphMaxLines: unionGraphMaxLines}"
  />
</div>
