import { mapState, mapActions } from 'vuex';
import util from '../../../share/util/utils';
import cstmTable from '../../../share/components/vue/PaginationTable';
import apiService from '../../../share/util/api/service';

const DIAG_SYSTEM = 'diagSytem.';

export default {
  name: 'detailFdModal',
  data() {
    return {
      statusLabel: '',
      statusValue: '',
      detailFdTableColumns: [],
      detailFdTableItems: [],
    };
  },

  props: {
    detailFdTableProp: { type: Array, default: [], required: false },
    selectedStatus: { type: String, default: "", required: false },
    modalLoading: { type: Boolean, required: true },
    measuredId: { type: String, default: "", required: false },
    ecuName: { type: String, default: "", required: false },
  },

  components: {
    'cstm-table-modal': cstmTable,
  },

  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('cache', ['cacheProjectDetails']),

    /**
     * 詳細FD 一覧の生成
     */
    updateDetailFdTable() {
      // リソース(作動履歴_DID)のi18nキー取得
      const i18nWsDidKey = `did`;
      this.statusLabel = this.$t(`message.header_vehicle_dtc_table_status`) + "：";
      this.statusValue = this.selectedStatus;

      // 項目・単位は固定で設定
      this.detailFdTableColumns = [
        { name: 'item', label: 'message.header_vehice_ffd_item' },
        { name: 'unit', label: 'message.header_vehice_ffd_unit' }
      ];
      const header_cnt = this.$t(`individual.header_vehicle_system_table_cnt`);
      const fdDetailList = [];
      for (const obj of this.detailFdTableProp) {
        const fdDetailObject = {};
        for (const key in obj) {
          if (key === 'did') {
            fdDetailObject.item = this.$t(`${i18nWsDidKey}.` + obj[key]);
            // 画面に表示しないが、初期ソート用にDID値をセット
            fdDetailObject.did = obj[key].split('_')[0];
          } else if (key === 'unit_id') {
            fdDetailObject.unit = this.$t(`didUnit.` + obj[key]);
          } else if (key === 'read_data') {
            // no でソート
            obj[key].sort((a, b) => a.no > b.no ? 1 : -1);
            const allHyphenFlg = obj[key].every((data) => data.value === 0);
            // read_data をloopし、文言ファイルに一致するキーが存在する場合は対象の文言を、data_typeがvalueは丸め処理を、それ以外は値をそのままセット
            for (const fdData of obj[key]) {
              const messageKey = `${i18nWsDidKey}.${obj.did}_${fdData.value}`;
              const keyName = "count_" + fdData.no;
              fdDetailObject[keyName] = this.$te(messageKey) ? this.$t(messageKey) :
                allHyphenFlg ? this.$t(`message.common_hyphen`) :
                  obj.data_type === 'Value' ? Number(fdData.value.toFixed(obj.digits_after_decimal)) : obj.data_type === 'HEX' ? `0x${fdData.value.toUpperCase()}` : fdData.value;
            }
          }
        }
        fdDetailList.push(fdDetailObject);
      }
      for (const element of this.detailFdTableProp[0].read_data) {
        this.detailFdTableColumns.push({
          "name": "count_" + element.no,
          "label": util.setColums(element.no, header_cnt, "{0}{1}"),
          "useHtmlBind": true,
        });
      }
      this.detailFdTableItems = fdDetailList;
    },

    async csvExport() {
      this.$emit('update-loading', true);
      const e = (str) => util.escapeForCsv(str);
      const targetMeasurement = this.cachedDetails.measures[0][apiService.WSP].find(measure => measure.registered_at.toString() === this.measuredId);
      const dataName = util.isNullOrDefault(targetMeasurement.name, '');
      const note = util.isNullOrDefault(targetMeasurement.note, '');
      const systemName = this.ecuName.replace(DIAG_SYSTEM, '');
      const immutableId = Number(targetMeasurement.item_name.split('.')[1]);
      const i18nWsKey = !util.isNullOrDefault(targetMeasurement.special_requirement_id) ?
        `ws${systemName}.${immutableId}` : `spws${systemName}.${immutableId}`;

      // テストdataを生成する
      let header = '';
      // 1行目～8行目までの出力内容は詳細FD表以外のデータ
      // 1行目(データ名) + 改行コード
      header += e(this.$t('message.label_measured_data_details_data_name')) + ',' + e(dataName) + '\n';
      // 2行目(メモ)
      header += e(this.$t('message.label_measured_data_details_notes')) + ',' + e(note) + '\n';
      // 3行目(VIN)
      header += e(this.$t('message.label_vehicle_system_vin').replace(':', '')) + ',' + e(this.cachedDetails.information.vin) + '\n';
      // 4行目(車台番号)空固定
      header += e(this.$t('message.label_chassis_number')) + '\n';
      // 5行目(登録番号)
      header += e(this.$t('message.label_registration_number')) + ',';
      let registrationNumber = '';
      if (
        util.isNullOrDefault(this.cachedDetails.information.area_name) !== null ||
        util.isNullOrDefault(this.cachedDetails.information.category) !== null ||
        util.isNullOrDefault(this.cachedDetails.information.hiragana) !== null ||
        util.isNullOrDefault(this.cachedDetails.information.individual_number) !== null
      ) {
        registrationNumber = ('{0} {1} {2} {3}').format(
          util.isNullOrDefault(this.cachedDetails.information.area_name, ''),
          util.isNullOrDefault(this.cachedDetails.information.category, ''),
          util.isNullOrDefault(this.cachedDetails.information.hiragana, ''),
          util.isNullOrDefault(this.cachedDetails.information.individual_number, '')
        );
      }
      header += e(registrationNumber) + '\n';
      // 6行目(車名)
      header += e(this.$t('message.label_measured_data_filter_vehicle_name')) + ',' + e(this.cachedDetails.information.vehicle_name) + '\n';
      // 7行目(車型)
      const vehicleTypeName = util.isNullOrDefault(this.cachedDetails.information.model_type, '');
      header += e(this.$t(i18nWsKey + '.label_Vehicle_type')) + ',' + e(vehicleTypeName) + '\n';
      // 8行目(詳細FDステータス)
      header += e(this.$t('individual.header_vehicle_system_table_details_fd')) + ':,' + e(this.selectedStatus) + '\n';
      // 9行目以降は詳細FD画面に表示されている内容
      // 項目名作成
      let body = e(this.$t('individual.label_counter')) + ',';
      body += this.detailFdTableItems.map(obj => {
        return e(obj.item);
      }).join(',') + '\n';
      // 単位作成
      body += e(this.$t('individual.header_vehicle_system_table_cnt')) + ',';
      body += this.detailFdTableItems.map(obj => {
        return e(obj.unit);
      }).join(',') + '\n';
      // 表示データ作成
      const itemList = [];
      // 回数作成
      const counterList = [];
      this.detailFdTableColumns.forEach(element => {
        if (element.name.startsWith('count_')) {
          const number = Number(element.name.substring(6));
          counterList.push(e(number.toString()));
        }
      });
      itemList.push(counterList);

      this.detailFdTableItems.forEach(element => {
        const displayFdInfoList = new Array();
        for (let i = 0; i < counterList.length; i++) {
          displayFdInfoList.push(e(element['count_' + (i + 1)].toString()));
        }
        itemList.push(displayFdInfoList);
      });

      // 表示データの行列入れ替え
      body = (function (body, arr) {
        let addBody = body;
        const result = [];
        for (let i = 0; i < arr[0].length; i++) {
          result[i] = [];
          for (let j = 0; j < arr.length; j++) {
            result[i][j] = arr[j][i];
          }
          result[i].push('\n');
          addBody = addBody + result[i];
        }
        return addBody;
      })(body, itemList);

      // ダミー処理（ファイルエクスポートが高速で終了するので、操作者に通信の発生を認識させる）
      await util.sleep(1000);
      this.$emit('update-loading', false);

      const data = header + body;
      const bomFormatData = new Uint8Array([0xEF, 0xBB, 0xBF]); // UTF-8 BOM
      const blob = new Blob([bomFormatData, data], { type: 'text/csv;charset=utf-8' });
      const link = window.document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = ('{0}.csv').format("FD"); // 出力するファイルの名前
      link.style.display = 'none';
      link.click();
      link.remove();
    },
  },

  computed: {
    ...mapState({
      cachedDetails: state => state.cache.projectDetails
    }),
  },

  /**
   * createdイベント
   */
  created() {
    // noop
  },

  /**
   * mountedイベント
   */
  mounted() {
    this.updateDetailFdTable();
  },
};