import { mapState, mapActions } from 'vuex';
import cstmTable from '../../../share/components/vue/PaginationTable';

export default {
  name: 'detailMonitorTestModal',
  data() {
    return {
      displayTableInfo: [],
      monitorTestResultColumns: [],
    };
  },

  components: {
    'cstm-table-modal': cstmTable,
  },

  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('cache', ['cacheProjectDetails']),

    /**
     * モニタテスト 一覧の生成
     */
    async updateDetailMonitorTestTable(monitorTestData) {

      this.monitorTestResultColumns = [
        { name: 'mid', label: `individual.label_table_mid` },
        { name: 'tid', label: `individual.label_table_tid` },
        { name: 'scalingId', label: `individual.label_table_scaling_id` },
        { name: 'testValue', label: `individual.label_table_value` },
        { name: 'unit', label: `individual.label_table_unit` },
        { name: 'minimumTestLimit', label: `individual.label_table_min` },
        { name: 'maximumTestLimit', label: `individual.label_table_max` },
        { name: 'result', label: `individual.label_table_result` }
      ];

      this.displayTableInfo = [];
        const convertedData = monitorTestData.map((item) => {
          return {
            mid: item.mid,
            tid: item.tid,
            scalingId: item.scaling_id,
            testValue: item.test_value,
            unit: item.unit ? this.$t(`obd_unit.${item.unit}`) : "-",
            minimumTestLimit: item.minimum_test_limit,
            maximumTestLimit: item.maximum_test_limit,
            result: item.result
          };
        });
        this.$nextTick(() => {
          this.displayTableInfo = this.displayTableInfo.concat(convertedData);
        });
    },
  },

  computed: {
    ...mapState({
      cachedDetails: state => state.cache.projectDetails
    }),
  },

  /** createdイベント */
  created() {
    // noop
  },

  /** mountedイベント */
  mounted() {
    // noop
  },
};