/** 実行画面の router:name と navigationの文言 MAP */
export const EXECUTE_NAVIGATION_RESOURCES = {
  home: 'message.header_home_menu',
  blank: '',
  systemTable: 'message.label_function_menu_reprogramming',
  systemInformation: 'message.header_vehicle_system_information',
  attention: 'message.header_vehicle_system_repro',
  execution: 'message.header_vehicle_system_repro',
  result: 'message.header_repro_result',
  suSystemTable: 'message.label_function_menu_reprogramming',
  usbVerificationFailed: 'message.header_vehicle_system_information',
  menu: 'message.header_function_menu',
  diagProjectList: 'message.header_measured_data',
  dtcTable: 'message.label_function_menu_all_dtc',
  individualDiagCode: 'individual.individual_diagnosis_function_diag_code',
  individualCancelCode: 'individual.individual_diagnosis_function_cancel_code',
  individualDataMonitor: 'individual.individual_diagnosis_function_data_monitor',
  individualVehicleControlHistory: 'individual.individual_diagnosis_function_vehicle_control_history',
  individualActiveTest: 'individual.individual_diagnosis_function_active_test',
  individualWorkSupport: 'individual.individual_diagnosis_function_work_support',
  individualCustomize: 'individual.individual_diagnosis_function_customize',
  diagProjectDetails: 'message.header_measured_data',
  dataRecollection: 'message.header_measures_recollection',
  macKey: 'message.label_function_menu_mac_key_registration',
  immobilizer: 'message.label_function_menu_imobi_registration',
  specialFeatureEcuTable: 'message.label_function_menu_special_feature',
  realWorldDataTable: 'message.label_function_menu_vehicle_information',
  spWorkSupport: 'individual.individual_diagnosis_function_work_support',
  spDiagCode: 'individual.individual_diagnosis_function_diag_code',
  spCancelCode: 'individual.individual_diagnosis_function_cancel_code',
  spDataMonitor: 'individual.individual_diagnosis_function_data_monitor',
  individualEcuTable: 'individual.label_menu_individual_diagnosis',
  obdSystemTop: 'fixed_resource.obd_menu_start',
  generalObdSystemTop: 'fixed_resource.obd_menu_start',
  obdSystemList: 'fixed_resource.obd_menu_individual',
  generalObdSystemList: 'fixed_resource.obd_menu_individual',
  obdOnboardSystemTest: 'fixed_resource.obd_menu_systemtest',
  generalObdOnboardSystemTest: 'fixed_resource.obd_menu_systemtest',
  obdGetVehicleInfo: 'fixed_resource.obd_menu_infotype',
  generalObdGetVehicleInfo: 'fixed_resource.obd_menu_infotype',
  obdOnboardMonitorTest: 'fixed_resource.obd_menu_monitortest',
  generalObdOnboardMonitorTest: 'fixed_resource.obd_menu_monitortest',
  obdDtc: 'individual.individual_diagnosis_function_diag_code',
  generalObdDtc: 'individual.individual_diagnosis_function_diag_code',
  obdPermanentDtc: 'fixed_resource.obd_menu_pdtc',
  generalObdPermanentDtc: 'fixed_resource.obd_menu_pdtc',
  obdAllDtc: 'fixed_resource.obd_menu_alldtc',
  generalObdAllDtc: 'fixed_resource.obd_menu_alldtc',
  obdDataMonitor: 'fixed_resource.obd_menu_datamonitor',
  generalObdDataMonitor: 'fixed_resource.obd_menu_datamonitor',
};
