<div class="col-12">
  <div class="col-12 data-monitor-page">
    <div class="one-liner-btns">
      <!-- メモボタン -->
      <div class="left-side-btn">
        <b-button class="ml-2" variant="primary" size="sm" @click="parentOpenMemoModal">
          {{ $t('message.button_vehicle_dtc_note_modal') }}
        </b-button>
        <!-- CSVエクスポートボタン -->
        <b-button class="ml-2" variant="primary" size="sm" :disabled="inPlaying" @click="csvExport">
          {{ $t('message.file_export') }}
        </b-button>
      </div>
      <!-- グラフ設定ボタン -->
      <div class="right-side-btn">
        <b-button
          class="ml-2"
          variant="primary"
          size="sm"
          @click="handleConfigureGraph"
          :disabled="btnDisabled"
        >
          {{ $t('individual.button_data_monitor_graph_config') }}
        </b-button>
      </div>
    </div>

    <div class="recollection-progress-bar">
      <div class="progress" role="progressbar" aria-label="progress-area" aria-valuenow="currentPercentage" aria-valuemin="0" aria-valuemax="100">
        <div class="progress-bar" :style="{'width': currentPercentage + '%'}">{{ currentPercentage + ' %' }}</div>
      </div>
    </div>

    <!-- 再生データのリストやグラフ -->
    <data-monitor
      class="{'dm-area': showSelect}"
      ref="data_monitor"
      parentName="data_recollection"
      :showSelect="false"
      :inPlaying="inPlaying"
      :inExecution="false"
      :highlightItems="highlightItems"
      :dataMonitorFunctionSettings="dataMonitorFunctionSettings"
      @toggle-playing="togglePlaying"
      @change-check-value = "changeCheckValue"
      @back-select="backSelect"
      @update-total-count="updateTotalCount"
    />
  </div>
  <processing-view :processing="processing"></processing-view>
</div>