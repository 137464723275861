<!-- 画像表示/切替 -->
<b-modal v-model="modal_requirement_054" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      {{ headerTitle }}
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <div class="w-100">
          <div class="box-margin-right work-support-message" style="white-space: break-spaces;">
            {{ workSupportMessage }}
          </div>
        </div>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 正常終了表示、エラー -->
      <template v-if="showButtonOk">
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="onOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 再実行確認表示 -->
      <template v-if="phase === 'reExecute'">
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="executeProcess" class="mx-2">
          {{ $t(`${i18nWsKey}.label_switching`) }}
        </b-button>
        <b-button size="sm" variant="outline-primary" :disabled="isLoading" @click="executeEndProcess" class="mx-2">
          {{ $t('individual.label_dtc_completion') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>