<div class="container-fluid mt-2 mb-2" id="detail_dtc_modal">
  <template v-if="phase === 'dtcDisplay'">
    <div class="row no-gutters">
      <div class="w-100">
        <div class="text-right align-middle">
          <b-button size="sm" variant="primary" @click="readinessCodeProcess" class="mx-2">
            {{ $t('individual.label_readiness_code') }}
          </b-button>
        </div>
        <div style="max-height: 265px; overflow-y: auto;">
          <cstm-table-modal id="detailedDtc" useStripeStyle rowSelect usePopup useSort fixedHeader hasTabs
            :items="detailDtcTableItems" :columns="detailDtcTableColumns" @rowButtonClicked="rowButtonClickEvent"
            initSortDir="asc" class="mt-2 ml-2" type="simple" :height="1000">
            <template v-slot:rowButton>{{ $t('message.header_vehicle_dtc_table_ffd') }}</template>
          </cstm-table-modal>
        </div>
      </div>
    </div>
  </template>
  <template v-if="phase === 'detailedFfd'">
    <ffd-modal :ffdTableProp="ffdSystemTable" :systemProp="selectedSystem" :ffdSystem="ffdSystem"
      :ffdDescription="ffdDescription" @updateLoading="isLoading = $event"></ffd-modal>
  </template>
  <template v-if="phase === 'readinessCodeDisplay'">
    <div class="row no-gutters">
      <div class="w-100">
        <div style="max-height: 265px; overflow-y: auto;">
          <cstm-table-modal id="detailedReadinessCode" useStripeStyle rowSelect usePopup useSort fixedHeader hasTabs
            :items="readinessCodeTableItems" :columns="readinessCodeTableColumns" initSortDir="asc" class="mt-2 ml-2"
            type="simple" :height="1000">
          </cstm-table-modal>
        </div>
      </div>
    </div>
  </template>

  <processing-view :processing="isLoading"></processing-view>
</div>