/** 各フェーズ定義 */
export const PHASE = {
  SELECTING: 'selecting',
  RECORDED_ERROR: 'recorded_error',
  RECORDED_CONFIRMATION_ERROR: 'recorded_confirmation_error',
  MAP_DISPLAY_SUCCESS: 'map_display_success',
  WAITING: 'waiting',
  CLEARING_CONFIRMATION: 'clearing_confirmation',
  CLEARING_SUCCESS: 'clearing_success',
  SPEED_ERROR: 'speed_error',
  ECU_ERROR: 'ecu_error',
  TIMEOUT_ERROR: 'timeout_error',
  COMMUNICATION_ERROR: 'communication_error',
  EXCLUDED_ERROR: 'excluded_error',
};