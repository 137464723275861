<b-modal v-model="modal_requirement_096" :size="modalSize" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 実行中 -->
      <template v-if="phase === 'start'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- 一覧表示 -->
      <template v-if="phase === 'listView' || phase === 'nodata'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 詳細FD表示 -->
      <template v-if="phase === 'detailedFd'">
        {{ $t('message.common_title_infomation') }}
      </template>
      <!-- プロジェクトデータ保存 -->
      <template v-if="phase === 'projectData'">
        {{ $t('message.label_vehicle_dtc_note_modal_note') }}
      </template>
      <!-- 異常終了(エラー) -->
      <template v-if="phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 実行中 -->
        <template v-if="phase === 'start'"></template>
        <!-- 一覧表示 -->
        <template v-if="phase === 'listView'">
          <custom-table useStripeStyle hasTabs :items="lksDisplayInfo" :columns="tableColumns" useSort
            currentSortkey="status" initSortDir="asc" class="mt-2 ml-2" type="simple" usePopup rowSelect
            @rowButtonClicked="rowButtonClickEvent">
            <template v-slot:rowButton>{{ $t(`individual.button_fd`) }}</template>
          </custom-table>
        </template>
        <!-- 履歴データ無し表示 -->
        <template v-else-if="phase === 'nodata'">
          <div v-html="$t(`individual.label_history`)"></div>
        </template>
        <!-- 詳細FD表示 -->
        <template v-if="phase === 'detailedFd'">
          <div class="row ml-1">
            <div class="ml-2">
              <label>{{ statusLabel }}</label>
            </div>
            <div class="ml-2">
              <label style="font-weight: bold;">{{ statusValue }}</label>
            </div>
          </div>
          <b-button size="sm" variant="primary" :disabled="isLoading" @click="csvExport" class="mx-2">
            {{ $t('message.file_export') }}
          </b-button>
          <custom-table class="detailedFd mt-2 ml-2" useStripeStyle hasTabs :items="displayFdInfo"
            :columns="tableFdColumns" useSort currentSortkey="did" initSortDir="asc" type="simple" usePopup>
          </custom-table>
        </template>

        <!-- プロジェクトデータ保存 -->
        <template v-if="phase === 'projectData'">
          <project-data ref="projectData" :vin="vin" :vehicleName="vehicleName" :displayProjectID="displayProjectID"
          :registrationNumber="registrationNumber" :functionName="functionName" :dataName="dataName"
          :memo="memo" :hideRegistrationNumber="hideRegistrationNumber"></project-data>
        </template>
        <!-- 異常終了(エラー) -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;"
              v-html="$t(`individual.error_communication_error`)"></div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 一覧表示 -->
      <template v-if="phase === 'listView'">
        <b-button size="sm" variant="outline-primary" :disabled="isLoading" @click="closeWizard" class="mx-2 mt-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="screenMovement" class="mx-2 mt-2">
          {{ $t('message.common_button_save') }}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="isLoading || !isModeChangeable" @click="modeChange" :class="['mx-2 mt-2', checkButtonClass]">
          {{ displayModeInfo }}
        </b-button>
      </template>
      <!-- 履歴データ無し表示 -->
      <template v-if="phase === 'nodata'">
        <b-button size="sm" variant="outline-primary" @click="closeWizard" class="mx-2 mt-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="Secondary" class="btn btn-secondary mx-2 mt-2" disabled>
          {{ $t('message.common_button_save') }}
        </b-button>
        <b-button size="sm" variant="primary" :class="['mx-2 mt-2', checkButtonClass]" :disabled="isLoading || !isModeChangeable">
          {{ displayModeInfo }}
        </b-button>
      </template>
      <!-- 詳細FD表示 -->
      <template v-if="phase === 'detailedFd'">
        <b-button size="sm" variant="outline-primary" :disabled="isLoading" @click="back" class="mx-2">
          {{ $t('message.common_button_back') }}
        </b-button>
      </template>
      <!-- プロジェクトデータ保存 -->
      <template v-if="phase === 'projectData'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="callListView" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="callSaveEvent" class="mx-2">
          {{ $t('message.common_button_save') }}
        </b-button>
      </template>
      <!-- 異常終了(エラー) -->
      <template v-if="phase === 'failed'">
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="exit" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>