import individualDiagnose from "../../../share/util/individualDiagnose";

const TSV_CONTENT_TYPE = 'text/tab-separated-values;charset=utf-8;';
export const HEX_PREFIX = '0x';

// 値の翻訳を実施するデータタイプ
export const VALUE_TRANSLATE_TYPE = ['FLG', 'HEX', 'decExStr'];
// 値の10進数変換を実施するデータタイプ
export const VALUE_CONVERT_DECIMAL_TYPE = [
  'Bin', 'min2hhmm', 'sec2hhmm', 'sec2hhmmss',
  'Lat_DEG', 'Lng_DEG', 'Lat_DMM', 'Lng_DMM', 'LatLng_DMM'
];
// Worker種別
export const WORKER = Object.freeze({
  REALTIME: 'realtime',
  RESTFUL: 'restful',
  MEASUREMENT_CSV: 'measurement_csv'
});
// 親スレッドからWorkerに通知するメッセージ種別
export const WORKER_TASK = Object.freeze({
  START: 'start',
  STOP: 'stop'
});
// Workerから親スレッドに返されるメッセージ種別
export const WORKER_EVENT = Object.freeze({
  SUCCESS_CONNECTION: 'success_connection',
  BASE_TIME: 'baseTime',
  CHUNK_DATA: 'chunkData',
  CSV_DATA: 'csv_data',
  MEASUREMENT_INFO: 'measurementInfo',
  SUCCESS_END: 'success_end',
  START_ERROR: 'start_error',
  RUNTIME_ERROR: 'runtime_error',
});
// 呼び出し元を識別する種別
export const TYPE_CALLER = Object.freeze({
  DEFAULT: 'original_dm', // Step3のData Monitor
  AT: 'at_dm',            // Step3のActive Test上のData Monitor
  WS22: 'ws22_dm',        // Work SupportのWS.22で埋め込まれるData Monitor
  OBD: 'obd_dm',          // OBD版のData Monitor
  PLAY: 'play'            // データ再生
});

/**
 * TSVエクスポート処理
 * @param {Array} data TSVに出力するオブジェクトの配列。先頭オブジェクトのキー名をTSVのヘッダー項目にする。
 * @param {String} fileName ファイル名
 */
export function tsvFileExport(data, fileName) {
  if (!data || data.length === 0) {
    return;
  }

  const tsvData = convertToTSV(data);
  const blob = new Blob([tsvData], { type: TSV_CONTENT_TYPE });
  const link = document.createElement('a');

  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

/**
 * TSVファイル変換処理
 */
function convertToTSV(data) {
  const tsvArray = [];
  const header = Object.keys(data[0]).map(key => `${key}`).join('\t');
  tsvArray.push(header);
  data.forEach(item => {
    const row = Object.values(item).map(value => `${value}`.replace('\n', '\\n').replace('\t', '\\t')).join('\t');
    tsvArray.push(row);
  });
  return tsvArray.join('\n');
}

/**
 * 物理変換された値から表示する値を取得する
 * @param {object} instance 画面コンポーネントのインスタンス（通常は this）を指定する
 * @param {string} did 選択項目のDID情報
 * @param {string} value 変換された計測値
 * @param {array} conversionInfo 変換情報リスト
 * @param {String|null} region リージョン文字列
 * @param {String} specifiedSystemId 任意のECUの翻訳キーを取得したい場合のみ指定
 * @returns 翻訳された計測値
 */
export function getTranslateValue(instance, did, value, conversionInfo, region, specifiedSystemId = null) {
  const cache = new Cache();
  const cachedValue = cache.get(did, value);
  // 0や空文字の場合はキャッシュを利用するため、nullやundefinedの時のみ翻訳情報を取得する
  if (cachedValue !== undefined && cachedValue !== null) {
    return cachedValue;
  }
  // "DID情報_変換結果"（翻訳用jsonを検索する文字列）
  const didValKey = did + "_" + value;

  // diagのjson参照用キー
  const findFFDi18nKey = individualDiagnose.getFFDi18nKeyWithRegion(instance, region, specifiedSystemId);
  const didConversionKey = `${findFFDi18nKey}.`;
  let didValDisp = "";

  // diagに存在する場合
  if (instance.$te(didConversionKey + didValKey)) {
    // diagから翻訳結果を取得
    didValDisp = instance.$t(didConversionKey + didValKey);
    // diagに存在しない場合
  } else {
    // decExStrの場合、翻訳結果が無ければ空を返す
    if (conversionInfo.data_type === "decExStr") {
      cache.set(did, value, "");
      return "";
    }
    const defaultKey = did + "_default";
    if (instance.$te(didConversionKey + defaultKey)) {
      didValDisp = instance.$t(didConversionKey + defaultKey, [value]);
    } else {
      if (conversionInfo.data_type === "HEX") {
        // HEXで翻訳結果がない場合、先頭に'0x'をつけて返す
        cache.set(did, value, `${HEX_PREFIX}${value}`);
        return `${HEX_PREFIX}${value}`;
      }
      // それ以外の場合、生値を返却
      cache.set(did, value, value);
      return value;
    }
  }

  // diagから翻訳結果を返却
  cache.set(did, value, didValDisp);
  return didValDisp;
}

/**
 * 翻訳対象の種別
 */
export const CACHE_CATEGORY = {
  UNIT: 'unit'
};

/**
 * 翻訳結果をキャッシュする
 * $tや$teを1秒に数千回と呼び出すと負荷が高いため、$tの結果を保持しておく
 */
export class Cache {
  translation = {};

  constructor() {
    if (Cache.instance) {
      return Cache.instance;
    }
    Cache.instance = this;
  }

  /**
   * 本クラスの後処理
   */
  destroy() {
    this.translation = {};
    Cache.instance = null;
  }

  /**
   * キャッシュから翻訳情報を取得する
   * @param {string | CACHE_CATEGORY} category DID(信号線項目のID)や翻訳対象の種別
   * @param {string} key 翻訳時のキー
   * @returns {string | undefined} キャッシュされた翻訳情報。キャッシュが無ければundefined
   */
  get(category, key) {
    if (!this.translation[category]) {
      this.translation[category] = {};
    }
    return this.translation[category][key];
  }

  /**
   * キャッシュに翻訳結果を設定する
   * @param {string | CACHE_CATEGORY} category DID(信号線項目のID)や翻訳対象の種別
   * @param {string} key 翻訳時のキー
   * @param {string} value 設定する翻訳結果
   */
  set(category, key, value) {
    this.translation[category][key] = value;
  }
}

/**
 * データモニタが提供する機能について、各機能をON/OFFするための設定フラグを管理するクラス。
 * 呼び出し元の機能が追加される場合、呼び出し元の機能側で本クラスを修正すること。
 */
export class DataMonitorFunctionSettings {
  // 信号線を経由せずに全信号線を自動実行するか否かのフラグ
  isAutoExecute = false;
  // 計測データを永続化するか否かのフラグ ※falseの場合はマーカ登録ボタンも表示しない
  isPersist = true;
  // シークバー／[<<][<][>][>>]ボタンを表示するか否かのフラグ
  isTimeLine = true;
  // 計測ボタンを表示するか否かのフラグ
  isStartButton = true;
  // 停止ボタンを表示するか否かのフラグ
  isStopButton = true;
  // 戻るボタンを表示するか否かのフラグ
  isBackButton = true;

  /**
   * コンストラクタ
   * @param {string} type_caller 呼び出し元
   */
  constructor(type_caller) {
    switch (type_caller) {
      // WS.22
      case TYPE_CALLER.WS22:
        this.isAutoExecute = true;    // 自動実行する
        this.isPersist = false;       // 永続化しない
        this.isTimeLine = false;      // シークバー／[<<][<][>][>>]ボタンを表示しない
        this.isStartButton = false;   // 計測ボタンを表示しない
        this.isStopButton = false;    // 停止ボタンを表示しない
        this.isBackButton = false;    // 戻るボタンを表示しない
        break;
      // OBD
      case TYPE_CALLER.OBD:
        this.isAutoExecute = false;   // 自動実行しない
        this.isPersist = true;        // 永続化する ※条件によってfalseの場合あり(呼び元で上書き)
        this.isTimeLine = true;       // シークバー／[<<][<][>][>>]ボタンを表示する
        this.isStartButton = true;    // 計測ボタンを表示する
        this.isStopButton = true;     // 停止ボタンを表示する
        this.isBackButton = true;     // 戻るボタンを表示する
        break;
      // データ再生
      case TYPE_CALLER.PLAY:
        this.isAutoExecute = false;   // 自動実行しない ※再生＝測定なし
        this.isPersist = true;        // 永続化する ※再生＝永続化あり
        this.isTimeLine = true;       // シークバー／[<<][<][>][>>]ボタンを表示する
        this.isStartButton = false;   // 計測ボタンを表示しない
        this.isStopButton = false;    // 停止ボタンを表示しない
        this.isBackButton = true;     // 戻るボタンを表示する
        break;
      // 通常のDM、その他
      case TYPE_CALLER.DEFAULT:
      case TYPE_CALLER.AT:
      default:
        this.isAutoExecute = false;   // 自動実行しない
        this.isPersist = true;        // 永続化する
        this.isTimeLine = true;       // シークバー／[<<][<][>][>>]ボタンを表示する
        this.isStartButton = true;    // 計測ボタンを表示する
        this.isStopButton = true;     // 停止ボタンを表示する
        this.isBackButton = true;     // 戻るボタンを表示する
        break;
    }
  }
}

export default {
  VALUE_TRANSLATE_TYPE,
  VALUE_CONVERT_DECIMAL_TYPE,
  CACHE_CATEGORY,
  HEX_PREFIX,
  TYPE_CALLER,
  tsvFileExport,
  getTranslateValue,
  Cache,
  DataMonitorFunctionSettings
};