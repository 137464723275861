<!-- 設定保存ダイアログ -->
<b-modal v-model="modal_requirement_40001" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      {{ headerTitle }}
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <template v-if="phase !== 'selecting'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  workSupportMessage }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 選択画面 -->
        <template v-if="phase === 'selecting'">
          <div class="row no-gutters">
            <div class="col-12">
              <b-form-select class="form-control" v-model="selectedMode" :options="modeList" @change="handleSelected"
                :select-size="6"></b-form-select>
            </div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer="{ close, ok }">
    <div class="w-100 text-center ">
      <template v-if="phase === 'selecting'">
        <!-- 戻るボタン表示 -->
        <b-button size="sm" variant="outline-primary" @click="onBackClicked" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_back') }}
        </b-button>
        <!-- OKボタン表示 -->
        <b-button size="sm" variant="primary" @click="onOkClicked" class="mx-2" :disabled="isNotSelected || isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'clearing_confirmation'">
        <!-- NOボタン表示 -->
        <b-button size="sm" variant="outline-primary" @click="onNoClicked" class="mx-2" :disabled="isLoading">
          {{ $t('individual.common_button_no') }}
        </b-button>
        <!-- YESボタン表示 -->
        <b-button size="sm" variant="primary" @click="onYesClicked" class="mx-2" :disabled="isLoading">
          {{ $t('individual.common_button_yes') }}
        </b-button>
      </template>
      <!-- OKボタン表示 -->
      <b-button v-if="showOKButton" size="sm" variant="primary" @click="onOkClicked" class="mx-2" :disabled="isLoading">
        {{ $t('message.common_button_ok') }}
      </b-button>
    </div>
  </template>
</b-modal>