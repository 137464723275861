import logWrapper from "../../../share/logWrapper";
import dmDidDataConversion from "../../../share/util/didDataConversion";
import dmUtil from "../dataMonitor/dataMonitorUtil";
import { fixNumberWithDecimal } from "../../../common/nodejs/mathFunctions";

export default class DmDataPointConversion {

  constructor() {
    this.funcValues = {};
    this.dataTotalCount = 0;
  }

  clear() {
    this.funcValues = {};
    this.dataTotalCount = 0;
  }

  /**
   * データ変換
   * @param {object} dataPointModel データポイント
   * @param {array} conversionKeys 変換情報のキー配列
   * @param {array} conversionValues 変換情報の値の配列
   * @param {object} didConversionMap 変換情報のDIDごとのインデックスを持つオブジェクト
   */
  dataConvert(dataPointModel, conversionKeys, conversionValues, didConversionMap) {
    if (!conversionKeys || !conversionKeys.length) return [];

    const dataId = this.getDataIdFourDigit(dataPointModel.data_id);
    const res = [];
    const indexes = didConversionMap[dataId];

    indexes.forEach(index => {
      const conversionInfo = conversionValues[index];
      const itemId = conversionKeys[index];

      const convertedVal = this.dataConversion(
        conversionInfo,
        dataPointModel,
        itemId
      );

      // 項目ごとの値を更新
      this.updateFuncValues(
        itemId,
        convertedVal,
        conversionInfo
      );

      // データ件数の通知
      this.dataTotalCount++;

      // For Debug: 総件数の出力
      if (this.dataTotalCount % 2000 === 0) {
        logWrapper.log(`data point total counts[${this.dataTotalCount}]`);
      }

      res.push({
        data_monitor_id: itemId, // 計測データID
        convertedData: convertedVal, // 変換済み計測値
        time: this.getTimeFromMeasureStart(dataPointModel.time), // 計測時間
      });
    });
    return res;
  }

  /**
   * 計測経過時間(ミリ秒)を取得
   * @param {String} YYYY-MM-DDTHH:mmss.sssssZ
   * @returns {number} ミリ秒
   */
  getTimeFromMeasureStart(rfcTime) {
    return new Date(rfcTime).getTime();
  }

  /**
   * data_id(DID/PID)を取得
   * @param {String} "0/PU_EGI/F401" or "0/EPS_PH4/01"
   * @returns {String} "F401" or "01"
   */
  getDataIdFourDigit(dataId) {
    const tmpList = dataId.split("/");
    return tmpList[tmpList.length - 1];
  }

  /**
   * 項目ごとの保管データ更新
   * @param {*} id data_monitor_id
   * @param {*} val 変換済の値
   * @param {*} conversionInfo 変換情報
   */
  // eslint-disable-next-line complexity
  updateFuncValues(id, val, conversionInfo) {
    const unit = `didUnit.` + conversionInfo.unit_id;
    const data_type = conversionInfo.data_type;
    const digit_after_decimal = conversionInfo.digit_after_decimal;
    let convertValue;

    // funcValuesに項目ごとにデータ格納
    // 項目の初回
    if (!this.funcValues[id]) {
      this.funcValues[id] = {
        cnt: 1,
        unit: unit,
        data_type: data_type,
        digit_after_decimal: digit_after_decimal
      };

      // 最大/最小はdecExStrとValueとACL2INCのみ
      // 平均はdecExStrとValueのみ
      switch (data_type) {
        case "Value":
          this.funcValues[id].max = Number(val); // 最大
          this.funcValues[id].maxDisp = val;
          this.funcValues[id].min = Number(val); // 最小
          this.funcValues[id].minDisp = val;
          this.funcValues[id].avg = Number(val); // 平均
          this.funcValues[id].avgDisp = fixNumberWithDecimal(Number(val), digit_after_decimal);
          break;
        case "decExStr":
          {
            // HEX値を数値に変換する
            convertValue = Number(dmDidDataConversion.convertValueDataForHex(val, conversionInfo));

            // メイン処理内で翻訳を行うため、表示値にHEX値のまま設定
            this.funcValues[id].max = convertValue; // 最大
            this.funcValues[id].maxDisp = val;
            this.funcValues[id].min = convertValue; // 最小
            this.funcValues[id].minDisp = val;
            this.funcValues[id].avg = convertValue; // 平均
            this.funcValues[id].avgDisp = fixNumberWithDecimal(convertValue, digit_after_decimal);
          }
          break;
        case "ACL2INC":
          this.funcValues[id].max = Number(val); // 最大
          this.funcValues[id].maxDisp = val;
          this.funcValues[id].min = Number(val); // 最小
          this.funcValues[id].minDisp = val;
          this.funcValues[id].avgDisp = "-"; // 平均
          break;
        case "HEX":
          convertValue = parseInt(val, 16);
          this.funcValues[id].max = convertValue; // 最大
          this.funcValues[id].maxDisp = "-";
          this.funcValues[id].min = convertValue; // 最小
          this.funcValues[id].minDisp = "-";
          this.funcValues[id].avgDisp = "-"; // 平均
          break;
        case "FLG":
          this.funcValues[id].max = 1; // 最大
          this.funcValues[id].maxDisp = "-";
          this.funcValues[id].min = 0; // 最小
          this.funcValues[id].minDisp = "-";
          this.funcValues[id].avgDisp = "-"; // 平均
          break;
        default:
          if (dmUtil.VALUE_CONVERT_DECIMAL_TYPE.includes(data_type)) {
            convertValue = dmDidDataConversion.convertBinToInt(val, conversionInfo);
            this.funcValues[id].max = convertValue; // 最大
            this.funcValues[id].min = convertValue; // 最小
          }
          this.funcValues[id].maxDisp = "-"; // 最大
          this.funcValues[id].minDisp = "-"; // 最小
          this.funcValues[id].avgDisp = "-"; // 平均
          break;
      }
    }
    // 項目の2回目以降
    else {
      this.funcValues[id].cnt++;

      // 最大/最小はdecExStrとValueとACL2INCのみ
      switch (data_type) {
        case "decExStr":
          {
            // HEX値を数値に変換する
            convertValue = Number(dmDidDataConversion.convertValueDataForHex(val, conversionInfo));

            // メイン処理内で翻訳を行うため、表示値にHEX値のまま設定
            if (this.funcValues[id].max < convertValue) {
              this.funcValues[id].max = convertValue; // 最大
              this.funcValues[id].maxDisp = val;
            }
            if (this.funcValues[id].min > convertValue) {
              this.funcValues[id].min = convertValue; // 最小
              this.funcValues[id].minDisp = val;
            }
          }
          break;
        case "ACL2INC":
        case "Value":
          convertValue = Number(val);

          if (this.funcValues[id].max < convertValue) {
            this.funcValues[id].max = convertValue; // 最大
            this.funcValues[id].maxDisp = fixNumberWithDecimal(convertValue, digit_after_decimal);
          }
          if (this.funcValues[id].min > convertValue) {
            this.funcValues[id].min = convertValue; // 最小
            this.funcValues[id].minDisp = fixNumberWithDecimal(convertValue, digit_after_decimal);
          }
          break;
        case "HEX":
          convertValue = parseInt(val, 16);
          if (this.funcValues[id].max < convertValue) {
            this.funcValues[id].max = convertValue; // 最大
          }
          if (this.funcValues[id].min > convertValue) {
            this.funcValues[id].min = convertValue; // 最小
          }
          break;
        case "FLG":
          // FLGは最大と最小が固定のため何もしない
          break;
        default:
          if (dmUtil.VALUE_CONVERT_DECIMAL_TYPE.includes(data_type)) {
            convertValue = dmDidDataConversion.convertBinToInt(val, conversionInfo);
            if (this.funcValues[id].max < convertValue) {
              this.funcValues[id].max = convertValue; // 最大
            }
            if (this.funcValues[id].min > convertValue) {
              this.funcValues[id].min = convertValue; // 最小
            }
          }
          break;
      }

      // 平均はdecExStrとValueのみ
      if (data_type === "decExStr" || data_type === "Value") {
        const newAvg = this.funcValues[id].avg + (convertValue - this.funcValues[id].avg) / this.funcValues[id].cnt;
        this.funcValues[id].avg = newAvg; // 平均
        this.funcValues[id].avgDisp = fixNumberWithDecimal(newAvg, digit_after_decimal);
      }
    }
  }

  /**
   * データ変換処理。
   * ただし値を10進数変換してグラフ描画を行うデータタイプの項目は、表示直前に変換を行うため、バイト配列で返す。
   * 
   * @param {object} conversionInfo 変換情報
   * @param {object} dataPointModel intdashから取得した計測値
   * @returns {string|array[byte]} convertedValue 変換後の値
   */
  dataConversion(conversionInfo, dataPointModel, conversionId) {
    const measuredData = this.findData(
      conversionInfo,
      dataPointModel,
      conversionId
    );

    const byteDataList = [];
    if (conversionInfo.byte_or_bit === "byte") {
      for (let i = 0; i < measuredData.length; i += 2) {
        byteDataList.push(parseInt(measuredData.substring(i, i + 2), 16));
      }
    } else {
      byteDataList.push(parseInt(measuredData));
    }

    let convertedValue = "";
    if (conversionInfo.data_type === "decExStr") {
      // グラフの値に使用するため、文字列に翻訳せずにHEXで返す
      convertedValue = dmDidDataConversion.convertHexData(byteDataList);
    } else if (dmUtil.VALUE_CONVERT_DECIMAL_TYPE.includes(conversionInfo.data_type)) {
      // グラフに10進数値と変換値を渡すため、表示直前に変換を行えるようにバイト配列のまま返す
      convertedValue = byteDataList;
    } else {
      // 変換値の取得
      convertedValue = dmDidDataConversion.convertDataToDisplayValue(
        byteDataList,
        conversionInfo
      );
    }

    return convertedValue;
  }

  /**
   * DID情報から該当するintdashの計測値を取得する
   * @param {object} conversionInfo 変換情報
   * @param {object} dataPointModel intdashから取得した計測値
   * @returns {string} subjectData 変換対象データ
   */
  findData(conversionInfo, dataPointModel, conversionId) {
    // 選択項目のDID/PID部分
    const did = conversionId.match(/^[^_]+/)[0];

    // DID情報を比較し、計測値から変換対象の計測値を検索する
    if (did === this.getDataIdFourDigit(dataPointModel.data_id)) {
      // 変換対象データ
      const intdashData = dataPointModel.data;

      // intdashから取得したデータ
      let targetData = intdashData;

      // リトルエンディアンの場合、データをリバースする
      if (conversionInfo.little_endian) {
        let reverseData = "";
        for (let i = targetData.length; i > 0; i -= 2) {
          reverseData += targetData.substring(i, i - 2);
        }
        targetData = reverseData;
      }

      // 切り出す単位が「byte」の場合
      if (conversionInfo.byte_or_bit === "byte") {
        return this.getByteData(
          targetData,
          conversionInfo.start_byte_position,
          conversionInfo.byte_size
        );
        // 切り出す単位が「bit」の場合
      } else {
        return this.getBinaryData(
          targetData,
          conversionInfo.start_bit_position,
          conversionInfo.start_byte_position,
          conversionInfo.bit_size
        );
      }
    }

    return "";
  }

  /**
   * 16進数データをbyte単位でトリムする。
   * @param {string} value 16進数文字列
   * @param {num} start_byte_position 1Based
   * @param {num} byte_size トリムするbyte数
   * @returns {string} トリム結果。16進数文字列
   */
  getByteData(value, start_byte_position, byte_size) {
    // トリムするbyteの最初の位置と最後の位置を計算する
    const startByte = (start_byte_position - 1) * 2;
    const endByte = startByte + byte_size * 2;

    // １６進数の値を最初の位置と最後の位置でトリムする
    return value.substring(startByte, endByte);
  }

  /**
   * 16進数データをbit単位でトリムする。
   * @param {string} value 16進数データ
   * @param {num} start_bit_position 0Based
   * @param {num} start_byte_position 1Based
   * @param {string} bit_size トリムするbit数
   * @returns {string} トリム結果。16進数文字列
   */
  getBinaryData(value, start_bit_position, start_byte_position, bit_size) {
    // １６進数を２進数に変換
    const binaryValue = this.hexToBinary(value);

    // トリムするbitの最初の位置と最後の位置を計算する
    const byteSize = Math.ceil((start_bit_position + bit_size) / 8);
    const LSByte = start_byte_position + byteSize - 1;
    const endBit = LSByte * 8 - start_bit_position;
    const startBit = endBit - bit_size;

    // ２進数の値を最初の位置と最後の位置でトリムして返却する
    return parseInt(binaryValue.substring(startBit, endBit), 2);
  }

  /**
   * 16進数を2進数に変換
   * @param {*} hex 
   * @returns 
   */
  hexToBinary(hex) {
    const lookup = {
      "0": "0000",
      "1": "0001",
      "2": "0010",
      "3": "0011",
      "4": "0100",
      "5": "0101",
      "6": "0110",
      "7": "0111",
      "8": "1000",
      "9": "1001",
      A: "1010",
      B: "1011",
      C: "1100",
      D: "1101",
      E: "1110",
      F: "1111",
    };
    const hexTemp = hex.toUpperCase();
    let ret = "";
    for (let index = 0; index < hexTemp.length; ++index) {
      ret += lookup[hexTemp[index]];
    }
    return ret;
  }
}
