import didDataConversion from "../../../../../share/util/didDataConversion";

const DEFAULT_UNIT_ID = "unitId_0";

/**
 * PID/DID data conversion from data to display value
 * @param dataByteArray
 * @param conversionInfo
 */
function convertDataToDisplayValue(dataByteArray = [], conversionInfo = {}, referenceValues = []) {
  if (!dataByteArray || dataByteArray.length === 0) {
    return "";
  }
  let displayValue = "";
  if (!conversionInfo || !conversionInfo || !conversionInfo.data_type) {
    return displayValue;
  }
  const conversionFunctions = {
    "FLG": convertFlgData,
    "HEX": convertHexData,
    "Value": convertValueData,
    "min2hhmm": didDataConversion.convertMin2hhmmData,
    "sec2hhmm": didDataConversion.convertSec2hhmmData,
  };
  const conversionFunction = conversionFunctions[conversionInfo.data_type];
  if (conversionFunction) {
    displayValue = conversionFunction(dataByteArray, conversionInfo, referenceValues);
  }
  return displayValue;
}

function convertFlgData(dataByte, conversionInfo, referenceValues) {
  const FLG_TRUE = 1;
  const FLG_FALSE = 0;
  let specBitStr = "";

  const referenceInfo = conversionInfo.setting_info?.reference_pid;
  const settingInfo = conversionInfo.setting_info?.setting_overwrite_condition;
  const intSettingValue = dataByte[0] === 0 ? FLG_FALSE : FLG_TRUE;

  if (referenceInfo && settingInfo) {
    if (intSettingValue === settingInfo.value) {
      const referenceValue = referenceValues.find(
        e => e.id.padStart(2, "0") === referenceInfo.pid || e.id.padStart(4, "0") === referenceInfo.did
      );

      if (referenceValue) {
        // HACK 現状取りうるサイズとして1Bit固定としている。本来であればPID項目のByte/Bitデータ＆サイズをBackendから取得してから処理すべき
        const intReferenceValue = parseInt(`0x${getBitData(referenceValue.value, referenceInfo.start_byte_position, referenceInfo.start_bit_position, 1)}`, 16);

        switch (intReferenceValue) {
          case FLG_TRUE:
            specBitStr = "_supp_TRUE";
            break;
          case FLG_FALSE:
            specBitStr = "_supp_FALSE";
            break;
          default:
            break;
        }
      }
    }
  }

  return dataByte[0] === 0 ? "FALSE" + specBitStr : "TRUE";
}

function convertHexData(dataBytes, conversionInfo) {
  let displayValue = "";
  if (conversionInfo.byte_or_bit === "byte") {
    for (let i = 0; i < dataBytes.length; i++) {
      const dataByte = dataBytes[i].toString(16).padStart(2, "0").toUpperCase();
      displayValue += dataByte;
    }
  } else {
    displayValue = dataBytes[0].toString(2).padStart(conversionInfo.bit_size, "0");
  }
  return displayValue;
}

function convertValueData(dataByte, conversionInfo) {
  // Physical conversion
  const intValue = didDataConversion.convertBinToInt(dataByte, conversionInfo);
  return convertValueDataForInt(intValue, conversionInfo);
}

function convertValueDataForInt(intValue, conversionInfo) {
  // SI単位系の設定値で、項目としてのオフセットを設定
  const physicalValue = (intValue * conversionInfo.conversion.si_scaling_bit) + conversionInfo.conversion.si_offset;
  // Unit conversion。非SI単位系の場合のみ、単位変換をおこなう
  const unitValue = conversionInfo.conversion.origin ?
    physicalValue :
    convertUnitValueData(physicalValue, conversionInfo);
  // Format number using fixed-point notation.
  return unitValue.toFixed(conversionInfo.conversion.digit_after_decimal);
}

// 上書き条件による演算値の適用
function convertOverWriteData(dataByte, conversionInfo, referenceValues) {
  const referenceInfo = conversionInfo.calculation_info.reference_pid;
  const calculationInfo = conversionInfo.calculation_info.calculation_formula.info;

  const intValue = didDataConversion.convertBinToInt(dataByte, conversionInfo); // 数値変換のみした数値
  const unitFixedValue = convertValueData(dataByte, conversionInfo); // 数値変換＋単位変換＋小数点以下丸めまでした文字列

  let referenceSupportValue = referenceValues.filter(e => e.id === '40' || e.id === 'F440');
  if (referenceSupportValue.length === 0) {
    return unitFixedValue;
  }

  referenceSupportValue = referenceSupportValue[0].value ? referenceSupportValue[0].value : '';
  if (referenceSupportValue === '') {
    return unitFixedValue;
  }

  const overWriteCondition = conversionInfo.calculation_info.calculation_overwrite_condition;
  // HACK 現状取りうるサイズとして1Bit固定としている。本来であればPID項目のByte/Bitデータ＆サイズをBackendから取得してから処理すべき
  const referenceSupportBit = getBitData(referenceSupportValue, overWriteCondition.target_byte, overWriteCondition.target_bit, 1);
  if (referenceSupportBit !== '1') {
    return unitFixedValue;
  }

  const referenceValue = referenceValues.filter(
    e => e.id.padStart(2, "0") === referenceInfo.pid || e.id.padStart(4, "0") === referenceInfo.did
  );
  if (referenceValue.length === 0) {
    return intValue.toFixed(conversionInfo.conversion.digit_after_decimal);
  }

  let intReferenceValue;
  if (conversionInfo.byte_or_bit === "byte") {
    intReferenceValue = parseInt(`0x${getByteData(referenceValue[0].value, referenceInfo.start_byte_position, referenceInfo.byte_size)}`, 16);
  } else {
    intReferenceValue = parseInt(`0x${getBitData(referenceValue[0].value, referenceInfo.start_byte_position, referenceInfo.start_bit_position, referenceInfo.bit_size)}`, 16);
  }
  if (intReferenceValue === 0) {
    // 参照PIDの値が0の時は、上書き条件を満たしていても、演算式を適用しない
    return unitFixedValue;
  }

  // 上書き値
  const overWriteValue = (intValue - calculationInfo.constant) * (intReferenceValue * calculationInfo.numerator) / calculationInfo.denominator;
  // 上書き条件の演算式に従って上書きする場合、SI標準の単位変換は考慮しない
  const unitValue = conversionInfo.conversion.origin ? overWriteValue : convertUnitValueData(overWriteValue, conversionInfo);
  return unitValue.toFixed(conversionInfo.conversion.digit_after_decimal);
}

// 単位変換
function convertUnitValueData(dataValue, conversionInfo) {
  const unit_conversion = conversionInfo.conversion;
  return ((dataValue * unit_conversion.scaling_bit) + unit_conversion.offset);
}

function getByteData(value, start_byte_position, byte_size) {
  // トリムするbyteの最初の位置と最後の位置を計算する
  const startByte = (start_byte_position - 1) * 2;
  const endByte = startByte + byte_size * 2;

  // １６進数の値を最初の位置と最後の位置でトリムする
  return value.substring(startByte, endByte);
}

function getBitData(value, byte_position, bit_position, bit_count) {
  const valueLength = (value.length / 2) * 8;
  const strValue = parseInt(value, 16).toString(2).padStart(valueLength, '0');
  const valueArray = strValue.match(/.{8}/g);
  const getByte = valueArray[byte_position - 1];
  return getByte.substring((8 - 1) - bit_position, (8 - 1) - bit_position + bit_count);
}

export default {
  convertDataToDisplayValue,
  convertFlgData,
  convertHexData,
  convertOverWriteData,
  DEFAULT_UNIT_ID,
};