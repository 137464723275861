const GRAPH = {
  SPLIT: 0,
  UNION: 1,
};

export default {
  name: 'obdDataMonitorMeasureListItem',
  data() {
    return {
      splitCheckValue: this.source.splitCheck,
      unionCheckValue: this.source.unionCheck,
    };
  },
  props: {
    index: {
      type: Number
    },

    source: {
      type: Object,
      default() {
        return {};
      }
    },

    // 強調表示項目 ※OBDでは不使用
    highlightItems: {
      type: Array
    },

    splitGraphLines: {
      type: Number
    },

    unionGraphLines: {
      type: Number
    },

    splitGraphMaxLines: {
      type: Number
    },

    unionGraphMaxLines: {
      type: Number
    }
  },

  watch: {
    'source.splitCheck': {
      handler(value) {
        this.splitCheckValue = value;
      }
    },

    'source.unionCheck': {
      handler(value) {
        this.unionCheckValue = value;
      }
    }
  },

  methods: {
    /**
     * 行CSS設定
     * @param {*} index 
     * @returns CSS設定
     */
    getRowStyle(index) {
      const tableStyles = ['data-monitor-virtual-row'];
      let idx = index;
      if (!idx) {
        idx = 0;
      }

      tableStyles.push((idx % 2 === 0) ? 'data-monitor-border-row' : 'data-monitor-non-border-row');
      return tableStyles;
    },

    /**
     * グラフ項目変更時処理
     * @param {*} col 分割G/結合G
     * @param {*} val チェック有無
     * @param {*} row 一覧のindex
     */
    handleChange(col, val, row) {
      let len = 0;
      let checkLen = 0;
      if (col === GRAPH.SPLIT) {
        len = this.splitGraphLines;
        checkLen = this.splitGraphMaxLines;
      } else {
        len = this.unionGraphLines;
        checkLen = this.unionGraphMaxLines;
      }
      if (val) {
        len = len + 1;
      } else {
        len = len - 1;
      }
      // グラフ項目数を確認
      if (len > checkLen) {
        // ONをもとに戻す
        if (col === GRAPH.SPLIT) {
          this.splitCheckValue = false;
        } else {
          this.unionCheckValue = false;
        }
        return;
      }
      this.dispatch(col, val, row);
    },

    /**
     * 親の処理呼び出し
     * @param {*} col 分割G/結合G
     * @param {*} val チェック有無
     * @param {*} row 一覧のindex
     */
    dispatch(col, val, row) {
      const componentName = "ObdDataMonitorMeasureList";
      let parent = this.$parent || this.$root;
      let name = parent.$options.name;

      while (parent && (!name || name !== componentName)) {
        parent = parent.$parent;
        if (parent) {
          name = parent.$options.name;
        }
      }

      if (parent) {
        parent.changeCheckValue(col, val, row);
      }
    }
  },
};
