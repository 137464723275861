<!-- 設定保存ダイアログ -->
<b-modal v-model="modal_requirement_40003" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      {{ headerTitle }}
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <div class="row no-gutters">
          <div class="col-12">
            <!-- 送信機ID入力 -->
            <template v-if="phase === 'input_id'">
              <div class="w-100 mb-4">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_40003_TXT_2`) }}</div>
                </div>
                <br>
                <table class="box-margin-right box-margin-top work-support-message" style="width:400px;">
                  <tbody>
                    <tr v-for="(item, i) in inputIds">
                      <td style="vertical-align: top">{{ $t(`${i18nWsKey}.REQ_WS_40003_TXT_6`, [i + 1]) }}</td>
                      <td>
                        <b-form-group class="input-40003-form">
                          <b-form-input v-model="item.id" :state="item.valid" type="text" size="sm" autocomplete="off">
                          </b-form-input>
                          <b-form-invalid-feedback v-if="item.valid !== null">{{ item.validMsg
                            }}</b-form-invalid-feedback>
                        </b-form-group>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br>
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_40003_TXT_3`) }}</div>
                </div>
              </div>
            </template>
            <!-- 待機/送信先選択/送信成功/共通エラー -->
            <template v-if="phase !== 'input_id'">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ workSupportMessage }}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer="{ close, ok }">
    <div class="w-100 text-center ">
      <template v-if="phase === 'confirm'">
        <!-- 「キャンセル」ボタン表示 -->
        <b-button size="sm" variant="outline-primary" @click="closeWizard" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_cancel') }}
        </b-button>
      </template>
      <template v-if="phase === 'select'">
        <!-- 「メイン」ボタン表示 -->
        <b-button size="sm" variant="primary" @click="write(true)" class="mx-2" :disabled="isLoading">
          {{ $t('individual.common_button_main') }}
        </b-button>
        <!-- 「サブ」ボタン表示 -->
        <b-button size="sm" variant="primary" @click="write(false)" class="mx-2" :disabled="isLoading">
          {{ $t('individual.common_button_sub') }}
        </b-button>
      </template>
      <!-- 「OK」ボタン表示 -->
      <b-button v-if="showOKButton" size="sm" variant="primary" @click="onOkClicked" class="mx-2" :disabled="isLoading">
        {{ $t('message.common_button_ok') }}
      </b-button>
    </div>
  </template>
</b-modal>