import session from '../../../../share/session';
import individualWorkSupportUtil from '../../../../share/util/individualWorkSupportUtil';
import { REQUEST_STATUS_COMPLETED, REQUEST_STATUS_FAILED } from '../../../../share/util/api/polling';
import { OPACITY } from './constants';

/** 周期通信開始・取得処理 */
export async function execute(self) {
  try {
    // スピナー表示時の画面不透明度を０に設定
    self.opacityValue = OPACITY.ZERO;
    self.isLoading = true;
    //バックエンドAPI POST,GET
    const token = session.getToken();
    const response = await individualWorkSupportUtil.pollingWspApi(
      self,
      self.netAppId,
      self.systemId,
      self.workSupportId,
      {
        id_token: token.id_token,
        type: 'execute',
      },
      // エラー発生のコールバックを実装
      (errorType) => { errorType; self.showUnexpectedError(); },
      // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
      // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
    );

    if (response) {
      // 正常コールバック
      switch (response.data.status) {
        case REQUEST_STATUS_COMPLETED:
          // 正常終了
          // 変速比変化量異常経験判定値（slip_hold）、プロジェクトデータ保存画面表示用データ名初期値を取得
          self.slipHold = response.data.user_data.slip_hold;
          self.dataName = response.data.user_data.data_name;
          // 周期通信停止（正常時）処理
          self.communicationStopSuccessProcess();
          break;
        case REQUEST_STATUS_FAILED:
          // 異常終了
          // 計測中表示画面の終了ボタン、キャンセル確認表示画面のキャンセル・OKボタンを非活性にする
          self.isDisabledExecute = true;
          // 周期通信停止（異常）処理
          self.communicationStopErrorProcess();
          break;
        default:
          // 異常終了：共通エラー
          self.showUnexpectedError();
          return;
      }
    }
  } finally {
    self.isLoading = false;
    // スピナー表示時の画面不透明度をデフォルト値に設定
    self.opacityValue = OPACITY.DEFAULT;
  }
}